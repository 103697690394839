import {
  Box, Button,
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@mui/material";
import React from "react";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { Invoice, LedgerReference } from "../../models";
import { Table as DataTable } from "../../components/shared/Table";
import { ColumnDef } from "@tanstack/react-table";
import {  convertObjectKeysToTitleCase, downloadCsv } from "../../utils/convertToCSV";
import { ReportEntity } from "../../models/reporting/reportEntity";
import Papa from "papaparse";
import { Download} from "react-feather";

type InvoiceTransactionsProps = {
  invoice: Invoice;
};

export const InvoiceTransactions = ({ invoice }: InvoiceTransactionsProps) => {
  const invoiceTransactions = invoice.includedTransactions ?? [];
  const transactions = invoiceTransactions
    .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

  const columns: ColumnDef<LedgerReference>[] = [
    {
      accessorKey: "employeeExternalId",
      header: "Employee Id",
      enableSorting: true,
      id: "employeeExternalId"
    },
    {
      accessorKey: "employeeName",
      header: "Employee",
      enableSorting: true,
      id: "employeeName"
    },
    {
      accessorKey: "sectorName",
      header: "Sector",
      enableSorting: true,
      id: "sectorName"
    },
    {
      accessorKey: "time",
      header: "Date",
      cell: ({ row }) => (
        <Box justifyContent="start">
          {formatDate(row.original.time)}
        </Box>
      ),
      enableSorting: true,
      id: "time"
    },
    {
      accessorKey: "amount",
      header: () => (
        <Box display={"flex"} justifyContent="flex-end" style={{ width: "100%", textAlign: "right" }}>
          Total
        </Box>
      ),
      cell: ({ row }) => (
        <Box display={"flex"} justifyContent="flex-end">
          {formatCurrency(row.original.amount, 2)}
        </Box>
      ),
      enableSorting: false
    }
  ];

  const total = transactions.reduce((acc, curr) => acc + curr.amount, 0);

  const handleDownloadCsv = () => {
    const csvData = transactions?.map((transaction) => {
      const {
        employeeExternalId,
        employeeName,
        sectorName,
        time,
        amount
      } = transaction;

      return convertObjectKeysToTitleCase({
        employeeExternalId,
        employeeName,
        sectorName,
        date: formatDate(time),
        amount: formatCurrency(amount)
      });
    });
    downloadCsv(Papa.unparse(csvData), `${ReportEntity.EMPLOYEES}${new Date().toLocaleString()}`);
  };

  return (
    <Grid item style={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflowY: "auto",
      height: "100%"
    }}>
      <Grid item pb={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">
              Transactions
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleDownloadCsv()}
              endIcon={<Download size={16} />}
              size="small"
            >
              Export to CSV
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item flex={1}>
        <Box minWidth="fit-content">
          <DataTable<LedgerReference>
            data={transactions}
            totalItems={transactions.length}
            columns={columns}
            displaySearch={false}
            displayFilters={false}
            rowSelection={false}
          />
        </Box>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="platform fees table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" style={{ width: "100%" }}>
                  <Typography variant="body1">
                    Transactions Total
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                  <Typography variant="body1">
                    {formatCurrency(total, 2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
