import { ChevronLeft } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";

export const BackButton: FC<{ onClick: () => void; outlined?: boolean }> = ({
  onClick,
  outlined
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        mt={1}
        sx={{
          cursor: "pointer",
          border: outlined ? "1px solid black" : "none",
          borderWidth: outlined ? 2 : 0,
          borderColor: "primary.main",
          borderRadius: 2,
          padding: 1
        }}
        onClick={onClick}
      >
        <ChevronLeft sx={{ color: theme.palette.primary.main }} />
        <Typography color="primary.main" mr={1}>
          Back
        </Typography>
      </Box>
    </>
  );
};
