import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { useMemo } from "react";
import { useData } from "../../utils/useData";
import ProviderSpendChart from "../charts/ProviderSpendChannelsChart";
import { LoadingSpinner } from "../shared/LoadingSpinner";
import MainCard from "../shared/MainCard";

function ProviderSpendChannels() {
  const { ledgers, serviceSectors } = useData();

  const data = useMemo(() => {
    if (!(ledgers.isFetched && serviceSectors.isFetched)) {
      return { isLoading: true };
    }

    const serviceSectorMap = new Map();

    ledgers.data?.items.forEach((ledger) => {
      const serviceSector = serviceSectors?.data?.items.find(
        (ss) => ss.id === ledger?.serviceSectorId
      );

      if (serviceSector) {
        if (!serviceSectorMap.has(serviceSector.id)) {
          serviceSectorMap.set(serviceSector.id, 0);
        }
        serviceSectorMap.set(
          serviceSector.id,
          serviceSectorMap.get(serviceSector.id) + ledger.billingAmount.amount
        );
      }
    });

    const series = Array.from(serviceSectorMap, ([id, value]) => ({
      name: serviceSectors?.data?.items.find(
        (serviceSector) => serviceSector.id === id
      )?.name,
      data: value
    }));

    // Find the top spend sector
    let topSpendSector = null;
    let maxSpend = 0;

    serviceSectorMap.forEach((value, id) => {
      if (value > maxSpend) {
        maxSpend = value;
        topSpendSector = serviceSectors?.data?.items.find(
          (serviceSector) => serviceSector.id === id
        );
      }
    });

    return {
      series,
      topSpendSector,
      isLoading: false
    };
  }, [
    ledgers.data,
    ledgers.isFetched,
    serviceSectors.data,
    serviceSectors.isFetched
  ]);

  return (
    <MainCard content={false} sx={{ mt: 1 }}>
      <Stack>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6">Provider Spend Breakdown</Typography>
              }
            />
          </ListItem>
        </List>
        {data.isLoading ? (
          <Box
            height="250px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingSpinner />
          </Box>
        ) : data.topSpendSector ? (
          <>
            <Box sx={{ pr: 2, pb: 2 }}>
              <ProviderSpendChart series={data.series} />
            </Box>
          </>
        ) : (
          <Box
            height="550px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>No data</Typography>
          </Box>
        )}
      </Stack>
    </MainCard>
  );
}

export default ProviderSpendChannels;
