import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";

const areaChartOptions = {
  chart: {
    height: 355,
    type: "area",
    toolbar: {
      show: false
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      type: "vertical",
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "straight",
    width: 1
  },
  grid: {
    show: true,
    borderColor: "#90A4AE",
    strokeDashArray: 0,
    position: "back",
    xaxis: {
      lines: {
        show: true
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    },
    yaxis: {
      lines: {
        show: true
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  }
};

const EmployeeSpendChart = ({ slot, quantity }) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories:
          slot === "month"
            ? [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
              ]
            : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: slot === "month" ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          },
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        y: {
          formatter(val) {
            return quantity === "By volume" ? val : formatCurrency(val);
          }
        }
      },
      theme: {
        mode: "light"
      }
    }));
  }, [primary, secondary, line, theme, slot, quantity]);

  const { ledgers } = useData();

  const seriesData = useMemo(() => {
    if (!ledgers.isFetched) return { isLoading: true };

    const today = new Date(); // Current date
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentWeekDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the start of the current week (Monday)
    const currentWeekStart = new Date(today);
    currentWeekStart.setDate(today.getDate() - (currentWeekDay - 1));

    // Initialize data arrays
    const monthData = Array(12).fill(0);
    const weekData = Array(7).fill(0);
    const monthVolume = Array(12).fill(0);
    const weekVolume = Array(7).fill(0);

    ledgers.data.items.forEach((transaction) => {
      const transactionDate = new Date(transaction.timestamp.split("T")[0]);
      const transactionYear = transactionDate.getFullYear();
      const transactionMonth = transactionDate.getMonth();
      const transactionDay = transactionDate.getDate();

      if (transactionYear === currentYear) {
        // Calculate volume (number of transactions) for the month
        monthVolume[transactionMonth]++;

        if (
          transactionMonth === currentMonth &&
          transactionDate >= currentWeekStart
        ) {
          // Calculate volume for the week
          const dayIndex = transactionDay - currentWeekStart.getDate();
          if (dayIndex >= 0 && dayIndex < 7) {
            // Check if the day is within the current week
            weekVolume[dayIndex]++;
            weekData[dayIndex] += transaction.billingAmount.amount;
          }
        }

        // Calculate amount (transaction total) for the month
        monthData[transactionMonth] += transaction.billingAmount.amount;
      }
    });

    return {
      amount: {
        monthData,
        weekData
      },
      volume: {
        monthData: monthVolume,
        weekData: weekVolume
      }
    };
  }, [ledgers.data, ledgers.isFetched]);

  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (seriesData.isLoading) return;
    switch (quantity) {
      case "By amount":
        setSeries([
          {
            name: "Amount",
            data:
              slot === "month"
                ? seriesData.amount.monthData
                : seriesData.amount.weekData
          }
        ]);
        break;
      case "By volume":
        setSeries([
          {
            name: "Volume",
            data:
              slot === "month"
                ? seriesData.volume.monthData
                : seriesData.volume.weekData
          }
        ]);
        break;
      default:
        break;
    }
  }, [slot, quantity, seriesData]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={355}
    />
  );
};

EmployeeSpendChart.propTypes = {
  slot: PropTypes.string,
  quantity: PropTypes.any
};

export default EmployeeSpendChart;
