import { Typography } from "@mui/material";
import { Table } from "../shared/Table";

interface CSVRow {
  [key: string]: string;
}

export const ReportDataTable = ({ csvData }: { csvData: string }) => {
  const rows = csvData.split("\n").map((row) => row.split(","));
  const columns = rows[0];
  const data: CSVRow[] = rows.slice(1).map((row) =>
    row.reduce((acc: CSVRow, value, index) => {
      acc[columns[index]] = value;
      return acc;
    }, {})
  );

  const tableColumns = columns.map((column) => ({
    header: column,
    accessorFn: (row: any) => row[column]
  }));

  if (!data.length) return <Typography m={1}>No Results</Typography>;

  return (
    <Table
      data={data}
      columns={tableColumns}
      totalItems={data.length}
      defaultSorting={[{ id: columns[0], desc: false }]}
      displaySearch={false}
      displayFilters={false}
      size="sm"
    />
  );
};
