import { useMemo } from "react";
import { Report } from "../models/reporting/report";
import { ReportEntity } from "../models/reporting/reportEntity";
import { ReportPeriod } from "../models/reporting/reportPeriod";

const useSeriesData = (
  documents: any[],
  report: Report,
  employerCreatedAt: Date
) => {
  const seriesData = useMemo(() => {
    const today = new Date(); // Current date
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentWeekDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the start of the current week (Monday)
    const currentWeekStart = new Date(today);
    currentWeekStart.setDate(today.getDate() - (currentWeekDay - 1));

    const resolveVolumeArray = () => {
      switch (report.data.period) {
        case ReportPeriod.WEEK:
          return 7; // 7 days in a week
        case ReportPeriod.MONTH:
          return 4; // Assume 4 weeks in a month for simplicity
        case ReportPeriod.YEAR:
          return 12; // 12 months in a year
        case ReportPeriod.ALL_TIME:
          if (documents.length === 0) return 0;
          const firstDate = new Date(employerCreatedAt);
          const lastDate = new Date();
          const months =
            (lastDate.getFullYear() - firstDate.getFullYear()) * 12 +
            lastDate.getMonth() -
            firstDate.getMonth() +
            1;
          return months;
        default:
          return 0;
      }
    };

    const volumeLength = resolveVolumeArray();
    const volume = Array(volumeLength).fill(0);

    documents.forEach((document) => {
      let documentDate =
        report.entity === ReportEntity.TRANSACTIONS && document?.timestamp
          ? new Date(document.timestamp.split("T")[0])
          : new Date(document.createdAt);

      const documentYear = documentDate.getFullYear();
      const documentMonth = documentDate.getMonth();
      const documentDay = documentDate.getDate();

      // Calculate the first day of the month
      const firstDayOfMonth = new Date(documentYear, documentMonth, 1);
      const documentWeekOfMonth = Math.min(
        4,
        Math.ceil((documentDay + firstDayOfMonth.getDay() - 1) / 7)
      );

      if (report.data.period === ReportPeriod.WEEK) {
        if (
          documentYear === currentYear &&
          documentMonth === currentMonth &&
          documentDate >= currentWeekStart
        ) {
          const dayIndex = documentDay - currentWeekStart.getDate();
          if (dayIndex >= 0 && dayIndex < 7) {
            volume[dayIndex]++;
          }
        }
      } else if (report.data.period === ReportPeriod.MONTH) {
        if (documentYear === currentYear && documentMonth === currentMonth) {
          if (documentWeekOfMonth >= 1 && documentWeekOfMonth <= 4) {
            volume[documentWeekOfMonth - 1]++;
          }
        }
      } else if (report.data.period === ReportPeriod.YEAR) {
        if (documentYear === currentYear) {
          volume[documentMonth]++;
        }
      } else if (report.data.period === ReportPeriod.ALL_TIME) {
        const firstDate = new Date(employerCreatedAt);
        const monthIndex =
          (documentYear - firstDate.getFullYear()) * 12 +
          documentMonth -
          firstDate.getMonth();
        if (monthIndex >= 0 && monthIndex < volume.length) {
          volume[monthIndex]++;
        }
      }
    });

    return {
      volume,
      volumeLength
    };
  }, [documents, report, employerCreatedAt]);

  return seriesData;
};

export default useSeriesData;
