import { Button, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step1Name: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  next: () => void;
}> = ({ formik, next }) => {
  return (
    <>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        What is your name?
      </Typography>
      <Grid container item xs={12} gap={4} my={3}>
        <TextField
          name="contactFirstName"
          value={formik.values.contactFirstName}
          onChange={formik.handleChange}
          error={
            formik.touched.contactFirstName &&
            Boolean(formik.errors.contactFirstName)
          }
          helperText={
            formik.touched.contactFirstName &&
            formik.errors.contactFirstName?.replace(
              "contactFirstName",
              "First Name"
            )
          }
          placeholder="First Name"
          fullWidth
          size="small"
          variant="standard"
          inputProps={{
            style: { fontSize: 24, marginBottom: 10 }
          }}
        />
        <TextField
          name="contactLastName"
          value={formik.values.contactLastName}
          onChange={formik.handleChange}
          error={
            formik.touched.contactLastName &&
            Boolean(formik.errors.contactLastName)
          }
          helperText={
            formik.touched.contactLastName &&
            formik.errors.contactLastName?.replace(
              "contactLastName",
              "Last Name"
            )
          }
          placeholder="Last Name"
          fullWidth
          size="small"
          variant="standard"
          sx={{ fontSize: 40 }}
          inputProps={{
            style: { fontSize: 24, marginBottom: 10 }
          }}
        />
        <Button
          disabled={
            !formik.values.contactFirstName ||
            !formik.values.contactLastName ||
            Boolean(formik.errors.contactFirstName) ||
            Boolean(formik.errors.contactLastName)
          }
          variant="contained"
          sx={{ width: "100%", py: 1, mt: 5 }}
          onClick={next}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};
