import {
  Checkbox,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  TextField
} from "@mui/material";
import { Column, Header } from "@tanstack/react-table";
import { useState } from "react";

type FilterProps = {
  header: Header<any, unknown>;
  column: Column<any, unknown>;
};

const MIN_FILTER_WIDTH = 120;

const extractCategories = (options: any): string[] => {
  const uniqueCategories = new Set<string>();

  options.forEach((option: any) => {
    const { category } = option;
    uniqueCategories.add(category);
  });

  return Array.from(uniqueCategories).filter((c) => c !== undefined);
};

export const Filter = ({ header, column }: FilterProps) => {
  const columnFilterValue = column.getFilterValue();

  // weird behavior with the checkbox's withing Select not rendering as checked correctly
  // this workaround seems to fix it
  const [localValueCopy, setLocalValueCopy] = useState(
    (columnFilterValue as string[]) ?? []
  );

  // select
  if (header.column.columnDef.meta?.filterSelectOptions) {
    const options = header.column.columnDef.meta?.filterSelectOptions;
    const categories = extractCategories(options);

    return (
      <Select
        onChange={(e) => {
          const value = e.target.value as string[];
          column.setFilterValue(value.length > 0 ? value : undefined);
          setLocalValueCopy(value ?? []);
        }}
        value={localValueCopy}
        input={<OutlinedInput size="small" />}
        renderValue={(selected: string[]) =>
          selected?.length
            ? selected
                .map(
                  (selected) =>
                    options?.find(
                      (filterOption) => filterOption.value === selected
                    )?.option
                )
                .join(", ")
            : "Select"
        }
        multiple
        labelId={header.column.columnDef.id + "-filter-label"}
        fullWidth
        displayEmpty={true}
        size="small"
      >
        {categories.length
          ? categories.flatMap((c) => [
              <ListSubheader key={c}>{c}</ListSubheader>,
              ...options
                .filter((o) => o.category === c)
                .map((filterOption) => (
                  <MenuItem key={filterOption.value} value={filterOption.value}>
                    <Checkbox
                      checked={localValueCopy.includes(filterOption.value)}
                      size="small"
                      sx={{ padding: "0px", paddingRight: "5px" }}
                    />
                    <ListItemText primary={filterOption.option} />
                  </MenuItem>
                ))
            ])
          : options.map((filterOption) => (
              <MenuItem key={filterOption.value} value={filterOption.value}>
                <Checkbox
                  checked={localValueCopy.includes(filterOption.value)}
                  size="small"
                  sx={{ padding: "0px", paddingRight: "5px" }}
                />
                <ListItemText primary={filterOption.option} />
              </MenuItem>
            ))}
      </Select>
    );
  }

  // slider
  if (column.columnDef.meta?.filterType === "number") {
    const { min, max } = column.columnDef.meta?.filterNumberRange ?? {
      min: 0,
      max: 100
    };
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ pl: 1, minWidth: 120 }}
      >
        <Slider
          value={(columnFilterValue as number) || max}
          min={min}
          max={max}
          step={1}
          onChange={(event, newValue) => {
            column.setFilterValue(newValue);
          }}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        {/* <Tooltip title="Reset">
          <IconButton
            size="small"
            color="error"
            onClick={() => column.setFilterValue(undefined)}
          >
            <CloseOutlined />
          </IconButton>
        </Tooltip> */}
      </Stack>
    );
  }

  // string
  if (column.getCanFilter())
    return (
      <TextField
        value={(columnFilterValue as string) ?? ""}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={`Search...`}
        size="small"
        sx={{
          minWidth: MIN_FILTER_WIDTH
        }}
      />
    );

  return null;
};
