import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ReactComponent as ExtraordinaryCardSplash } from "../../assets/extraordinary-card-splash.svg";
import { LoadingSpinner } from "../shared/LoadingSpinner";

export const Step5ReviewAndComplete: FC<{
  loading: boolean;
  onFinish: () => void;
}> = ({ loading, onFinish }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container item>
      <Grid item xs={4} mt={20}>
        {isLoading ? (
          <>
            <Typography
              variant="h4"
              fontSize={40}
              fontWeight={700}
              color="primary"
            >
              Hold on while we set things up for you...
            </Typography>
            <LoadingSpinner marginY="10vh" />
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              fontSize={40}
              fontWeight={700}
              color="primary"
            >
              Great!
            </Typography>
            <Typography variant="h4" fontSize={40} fontWeight={700}>
              You're all set!
            </Typography>
            <Typography variant="subtitle1" fontSize={18} my={3} width={400}>
              An invitation email has been sent to any users you’ve invited to
              join Extraordinary
            </Typography>

            <LoadingButton
              loading={loading}
              onClick={onFinish}
              variant="contained"
              sx={{ mt: 5 }}
            >
              Finish
            </LoadingButton>
          </>
        )}
      </Grid>
      <Grid item xs={6} mt={-45} minHeight="110vh">
        <ExtraordinaryCardSplash />
      </Grid>
    </Grid>
  );
};
