import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatCurrency } from "../../utils/formatters";
import { useGenerateColorGradient } from "../../utils/generateColorGradient";

const ProviderSpendChannelsChart = ({ series }) => {
  const theme = useTheme();

  const colors = useGenerateColorGradient(
    series?.length > 5 ? series.length : 5
  );

  // chart options
  const pieChartOptions = {
    chart: {
      type: "pie",
      height: 250,
      width: "100%",
      toolbar: {
        show: false
      }
    },
    labels: series.map((item) => item.name), // Add labels based on the series data
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (x) => formatCurrency(x)
      }
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
      offsetX: 10,
      markers: {
        width: 8,
        height: 8,
        radius: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false
    },
    stroke: {
      width: 1
    }
  };

  const [options, setOptions] = useState(pieChartOptions);

  const line = theme.palette.divider;
  const { primary, secondary } = theme.palette.text;

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors,
      theme: {
        mode: "light"
      },
      legend: {
        labels: {
          colors: "grey.500"
        }
      }
    }));
  }, [primary, secondary, line, theme, series?.length]);

  return (
    <ReactApexChart
      options={options}
      series={series.map((item) => item.data)}
      type="donut"
      height={450}
    />
  );
};

export default ProviderSpendChannelsChart;
