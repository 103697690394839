import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import React, { ReactNode, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AmountAndCurrency, PaymentIntentResponse } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { LoadingSpinner } from "../shared/LoadingSpinner";

type CreatePaymentIntentProps = {
  paymentMethodId: string;
  intendedAmount: number;
  invoiceId: string;
};

type CardPaymentProps = {
  countryId: string;
  invoiceId: string;
  amount: AmountAndCurrency;
  onSuccess: () => void;
  onCancel?: () => void;
  children?: ReactNode;
};

export const StripeCheckout = ({
  invoiceId,
  amount,
  onSuccess,
  onCancel,
  children
}: CardPaymentProps) => {
  const stripe = useStripe();
  const { config } = useConfig();
  const { employer, invoices } = useData();
  const elements = useElements();
  const { getToken } = useKindeAuth();
  const theme = useTheme();

  const [error, setError] = useState<string | undefined>(undefined);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  const calculateAmount = (surchargePercent: number) => {
    return amount.amount * (1 + surchargePercent);
  };

  const payForInvoice = useMutation({
    mutationFn: async ({
      paymentMethodId,
      intendedAmount,
      invoiceId
    }: CreatePaymentIntentProps) => {
      const response = await fetch(
        `${config?.API_URL}/stripe-payments/invoice-payments`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            intendedAmount: intendedAmount
          })
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(
          error.message || "There was a problem preparing payment"
        );
      }

      return await response.json();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  if (employer.isLoading || employer.data === undefined) {
    return <LoadingSpinner />;
  }

  const surchargePercent =
    employer.data.paymentOptions.cardPaymentSurchargePercent ?? 0.03;
  const intendedAmount = calculateAmount(surchargePercent);

  const handleSubmit = async (event: React.FormEvent) => {
    setProcessingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setProcessingPayment(false);
      return; // Stripe.js has not yet loaded.
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (
      cardNumberElement == null ||
      cardExpiryElement == null ||
      cardCvcElement == null
    ) {
      setProcessingPayment(false);
      return;
    }

    const paymentMethodRes = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement
    });

    if (paymentMethodRes.error) {
      setError(paymentMethodRes.error.message);
      console.error(paymentMethodRes.error);
      setProcessingPayment(false);
      return;
    }

    try {
      const paymentIntent: PaymentIntentResponse =
        await payForInvoice.mutateAsync({
          paymentMethodId: paymentMethodRes.paymentMethod.id,
          intendedAmount,
          invoiceId
        });

      if (paymentIntent.success) {
        toast.success("Payment processed!");
        await invoices.refetch();
        onSuccess();
      } else {
        toast.error(`Payment was not successful!`);
        await invoices.refetch();
        setProcessingPayment(false);
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      setError(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
      setProcessingPayment(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography>
        Make a payment of <b>{formatCurrency(intendedAmount, 2)}</b> including{" "}
        {surchargePercent * 100}% surcharge
      </Typography>
      <Grid container spacing={2} my={0}>
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: "10px",
              borderRadius: 1,
              backgroundColor: "grey.100",
              border: "1px solid black",
              borderColor: "grey.300",
              boxShadow: "none"
            }}
          >
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: "18px",
                    padding: "10px 14px",
                    "::placeholder": {
                      color: theme.palette.grey[500]
                    }
                  }
                }
              }}
            />
          </Paper>
        </Grid>

        <Grid container item xs={12} spacing={2} style={{ height: "50%" }}>
          <Grid item xs={6}>
            <Paper
              sx={{
                padding: "10px",
                borderRadius: 1,
                backgroundColor: "grey.100",
                border: "1px solid black",
                borderColor: "grey.300",
                boxShadow: "none"
              }}
            >
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "18px",
                      padding: "5px 7px",
                      "::placeholder": {
                        color: theme.palette.grey[500]
                      }
                    }
                  }
                }}
              />
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper
              sx={{
                padding: "10px",
                borderRadius: 1,
                backgroundColor: "grey.100",
                border: "1px solid black",
                borderColor: "grey.300",
                boxShadow: "none"
              }}
            >
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "18px",
                      padding: "5px 7px",
                      "::placeholder": {
                        color: theme.palette.grey[500]
                      }
                    }
                  }
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {error && <Typography color={"error"}>{error}</Typography>}
        </Grid>
      </Grid>

      <Grid item xs={12} pt={4}>
        <Box display="flex">
          {children && <Box>{children}</Box>}
          <Box mt={1} ml="auto">
            <LoadingButton
              loading={
                processingPayment ||
                employer.isLoading ||
                employer.data === undefined
              }
              color="primary"
              variant="contained"
              disabled={!stripe}
              type="submit"
            >
              Pay
            </LoadingButton>
          </Box>
          <Box>
            {onCancel && (
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
    </form>
  );
};

export const CardPayment = ({
  countryId,
  invoiceId,
  amount,
  onCancel,
  onSuccess,
  children
}: CardPaymentProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const { config, isLoading } = useConfig();

  const { employer } = useData();

  const resolveStripeAccountKey = (countryId: string) => {
    switch (countryId) {
      case "NZ": {
        return config?.STRIPE_PUBLIC_KEY_NZ;
      }
      case "AU": {
        return config?.STRIPE_PUBLIC_KEY_AU;
      }
      default: {
        return undefined;
      }
    }
  };

  useEffect(() => {
    const fetchConfig = async (stripeKey: string) => {
      try {
        setStripe(await loadStripe(stripeKey));
      } catch (error) {
        console.error("Error loading Stripe:", error);
        setStripe(null);
      }
    };

    if (config && !isLoading) {
      const key = resolveStripeAccountKey(countryId);
      if (!key) throw new Error("Error resolving stripe key by countryId");
      fetchConfig(key);
    }
  }, [config, isLoading]);

  if (isLoading || employer.isLoading) return <LoadingSpinner />;

  return (
    <Elements stripe={stripe}>
      <StripeCheckout
        countryId={employer.data?.country.id ?? ""}
        invoiceId={invoiceId}
        amount={amount}
        onCancel={onCancel}
        onSuccess={onSuccess}
        children={children}
      />
    </Elements>
  );
};
