import { LoadingButton } from "@mui/lab";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { Country } from "react-addressfinder";
import { AddressFinderForm } from "../../AddressFinderForm";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step4CompanyAddress: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  loading: boolean;
  updateCountry: (value: Country) => void;
}> = ({ formik, loading, updateCountry }) => {
  return (
    <>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Where is {formik.values.name} located?
      </Typography>
      <Grid container item xs={12} gap={2} my={3}>
        <Select
          sx={{ width: "100%" }}
          value={formik.values.contact.address.country}
          onChange={(e) =>
            updateCountry(e.target.value === "NZ" ? Country.NZ : Country.AU)
          }
          size="small"
        >
          <MenuItem value={Country.NZ}>New Zealand</MenuItem>
          <MenuItem value={Country.AU}>Australia</MenuItem>
        </Select>
        <AddressFinderForm formik={formik} />
        <LoadingButton
          loading={loading}
          disabled={
            Boolean(formik.errors.contact?.address?.address1) ||
            Boolean(formik.errors.contact?.address?.address2) ||
            Boolean(formik.errors.contact?.address?.city) ||
            Boolean(formik.errors.contact?.address?.stateOrProvince) ||
            Boolean(formik.errors.contact?.address?.postalCode)
          }
          variant="contained"
          sx={{ width: "100%", py: 1, mt: 5 }}
          type="submit"
        >
          Continue
        </LoadingButton>
      </Grid>
    </>
  );
};
