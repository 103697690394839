import { DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { FC } from "react";
import { Tier } from "../../models";

export const resolveRenewalPeriod = (periodInMonths: number) => {
  switch (periodInMonths) {
    case 1:
      return "month";
    case 6:
      return "6 months";
    case 12:
      return "year";
  }
};

export const calculateYearlyCost = (periodInMonths: number, amount: number) => {
  switch (periodInMonths) {
    case 1:
      return amount * 12;
    case 6:
      return amount * 2;
    case 12:
      return amount * 1;
  }
};

export const TierInfoBox: FC<{
  tier: Tier;
  isEdit: boolean;
  periodInMonths: number | null | undefined;
  error: string | undefined;
  deleteTier?: () => void;
  updateTierToCreate?: (value: Tier) => void;
  submitTierToCreate?: () => void;
}> = ({
  tier,
  isEdit,
  periodInMonths,
  error,
  deleteTier,
  updateTierToCreate,
  submitTierToCreate
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        width="100%"
        gap={10}
        sx={{
          p: 2,
          m: 0,
          border: "2px solid black",
          borderColor: theme.palette.grey[200],
          borderRadius: "10px",
          mb: 2,
          bgcolor: "transparent",
          cursor: "pointer",
          position: "relative"
        }}
      >
        <Grid container flexDirection="column" gap={0.5}>
          <Typography fontWeight={500} color={theme.palette.grey[600]}>
            Tier Name
          </Typography>
          {isEdit && updateTierToCreate ? (
            <TextField
              name="name"
              value={tier.name}
              onChange={(e) => {
                updateTierToCreate({ ...tier, name: e.target.value });
              }}
              // helperText={formik.touched.name && formik.errors.name}
              placeholder="Name"
              fullWidth
              size="small"
              variant="standard"
            />
          ) : (
            <Typography variant="h6">{tier.name}</Typography>
          )}
        </Grid>
        <Grid container flexDirection="column" gap={0.5}>
          <Typography fontWeight={500} color={theme.palette.grey[600]}>
            Tier Contribution
          </Typography>
          <Box display="flex" alignItems="end">
            {isEdit && updateTierToCreate ? (
              <>
                <TextField
                  name="contribution"
                  type="number"
                  value={tier.amount.amount}
                  onChange={(e) => {
                    updateTierToCreate({
                      ...tier,
                      amount: {
                        ...tier.amount,
                        amount: +e.target.value
                      }
                    });
                  }}
                  placeholder="Contribution"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  size="small"
                  variant="standard"
                />
                <Typography fontSize={14} whiteSpace="nowrap" color="grey">
                  /{resolveRenewalPeriod(periodInMonths ?? 0)}{" "}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  <span style={{ color: "grey", fontWeight: 400 }}>$</span>
                  {tier.amount.amount}{" "}
                </Typography>
                <Typography
                  fontSize={14}
                  whiteSpace="nowrap"
                  mb={0.5}
                  color="grey"
                >
                  /{resolveRenewalPeriod(periodInMonths ?? 0)}{" "}
                </Typography>
                {periodInMonths !== 12 && (
                  <Typography
                    fontSize={14}
                    whiteSpace="nowrap"
                    mb={0.5}
                    ml={2}
                    color="grey"
                  >
                    $
                    {calculateYearlyCost(
                      periodInMonths ?? 0,
                      tier.amount.amount
                    )}
                    /year
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>

        <Box
          display="flex"
          sx={{
            marginLeft: "auto",
            marginY: "auto",
            width: 150
          }}
        >
          {isEdit ? (
            <Button
              onClick={submitTierToCreate}
              disabled={!(tier.name.length > 0) || error !== undefined}
              variant="contained"
            >
              Save
            </Button>
          ) : (
            <Button>
              <DeleteOutline
                onClick={deleteTier}
                sx={{
                  fontSize: 30,
                  stroke: "#FFFFFF",
                  strokeWidth: 0.5,
                  color: "grey"
                }}
              />
            </Button>
          )}
        </Box>
      </Box>
      <Typography color="error">{error}</Typography>
    </>
  );
};
