import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Plan, PlanType, RenewalOptions, Tier } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { PlanDetails } from "./PlanDetails";

type EditPlanProps = {
  isPilot: boolean;
  open: boolean;
  handleClose: () => void;
  plan: Plan;
};

type UpdatePlanValues = {
  name: string;
  tiers: Tier[];
  serviceSectorIds?: string[];
  renewalOptions: RenewalOptions;
};

export const EditPlan = ({
                           isPilot,
                           open,
                           handleClose,
                           plan
                         }: EditPlanProps) => {
  const { config } = useConfig();
  const { employerId, plans, serviceSectors, employees, accounts } = useData();
  const { getToken } = useKindeAuth();

  const [error, setError] = useState("");

  const mutationUpdatePlan = useMutation(
    async (formValues: UpdatePlanValues) => {
      if (!formValues.serviceSectorIds?.length) {
        throw new Error("Plan must have at least 1 category selected");
      }

      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/plans/${plan.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: formValues.name,
            serviceSectorIds: formValues.serviceSectorIds,
            tiers: formValues.tiers.map((tier) => {
              return {
                name: tier.name,
                amount: tier.amount.amount,
                id: tier.id ? tier.id : null
              };
            }),
            renewalOptions: formValues.renewalOptions
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this plan");
    },
    {
      onSuccess: () => {
        toast.success("Plan updated successfully");
        plans.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const mutationDeletePlan = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/plans/${plan.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        if (response.status === 409) {
          throw new Error(
            "There was a problem deleting this Plan. To delete a plan, it must not be assigned to any employees."
          );
        }
        throw new Error("There was a problem deleting this Plan.");
      }
    },
    {
      onSuccess: () => {
        toast.success("Plan deleted successfully");
        handleClose();
        employees.refetch();
        accounts.refetch();
        plans.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  if (accounts.isLoading || serviceSectors.isLoading || plans.isLoading || employees.isLoading) {
    return <>Loading...</>;
  }

  const isPlanAssigned = () => {
    return !!accounts.data!.items.find((account) => account.planId === plan.id);
  };

  const handlePlanSave = async (
    name: string,
    serviceSectorIds: string[],
    tiers: Tier[],
    planType: PlanType,
    renewalOptions: RenewalOptions
  ) => {
    await mutationUpdatePlan.mutate({
      name,
      serviceSectorIds,
      tiers,
      renewalOptions
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle display="flex" width="100%">
        <Box>Manage plan</Box>
        <Box ml="auto">
          <Tooltip title="To delete a plan, it must not be assigned to any employees.">
            <Box>
              <Button
                disabled={isPlanAssigned() || mutationDeletePlan.isLoading}
                onClick={() => mutationDeletePlan.mutate()}
                color="error"
                variant="outlined"
                style={{ marginLeft: "auto" }}
              >
                Delete Plan
              </Button>{" "}
            </Box>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} p={5}>
          <PlanDetails
            isPilot={isPilot}
            employees={employees.data?.items ?? []}
            accounts={accounts.data?.items ?? []}
            serviceSectors={serviceSectors.data?.items ?? []}
            existingPlan={plan}
            isLoading={
              mutationDeletePlan.isLoading || mutationUpdatePlan.isLoading
            }
            onClose={handleClose}
            onSave={handlePlanSave}
          />
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
