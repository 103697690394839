import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ReportPeriod } from "../../models/reporting/reportPeriod";
import { useData } from "../../utils/useData";
import useSeriesData from "../../utils/useSeriesData";

const areaChartOptions = {
  chart: {
    height: 355,
    type: "area",
    toolbar: {
      show: false
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      type: "vertical",
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "straight",
    width: 1
  },
  grid: {
    show: true,
    borderColor: "#90A4AE",
    strokeDashArray: 0,
    position: "back",
    xaxis: {
      lines: {
        show: true
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    },
    yaxis: {
      lines: {
        show: true
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  }
};

export const ReportLineChart = ({ report, filteredDocuments }) => {
  const { employer } = useData();
  const theme = useTheme();
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(areaChartOptions);
  const { volume, volumeLength } = useSeriesData(
    filteredDocuments,
    report,
    employer.data.createdAt
  );

  const resolveCategories = () => {
    switch (report.data.period) {
      case ReportPeriod.WEEK:
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      case ReportPeriod.MONTH:
        return ["1st-7th", "8th-14th", "15th-21st", "22nd-End of Month"];
      case ReportPeriod.YEAR:
        return [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];
      case ReportPeriod.ALL_TIME: {
        const months = [];
        const currentDate = new Date();
        for (let i = 0; i < volumeLength; i++) {
          const monthDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - i,
            1
          );
          months.unshift(
            `${monthDate.toLocaleString("default", {
              month: "short"
            })} ${monthDate.getFullYear()}`
          );
        }
        return months;
      }
      default:
        return [];
    }
  };

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories: resolveCategories(),
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: resolveCategories().length
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          },
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      grid: {
        borderColor: line
      },
      theme: {
        mode: "light"
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary, secondary, line, theme]);

  useEffect(() => {
    setSeries([
      {
        name: "Count",
        data: volume
      }
    ]);
  }, [volume]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default ReportLineChart;
