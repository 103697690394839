export enum TransactionReason {
  APPROVE = "APPROVE",
  CARD_DELETED = "CARD_DELETED",
  CARD_NOT_FOUND = "CARD_NOT_FOUND",
  CARD_NOT_ACTIVE = "CARD_NOT_ACTIVE",
  CARD_EXPIRED = "CARD_EXPIRED",
  EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND",
  EMPLOYEE_NOT_ACTIVE = "EMPLOYEE_NOT_ACTIVE",
  EMPLOYEE_DELETED = "EMPLOYEE_DELETED",
  EMPLOYEE_HAS_NO_ACCOUNTS = "EMPLOYEE_HAS_NO_ACCOUNTS",
  EMPLOYEE_HAS_NO_ACTIVE_ACCOUNTS = "EMPLOYEE_HAS_NO_ACTIVE_ACCOUNTS",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  ACCOUNTS_FROM_ORIG_TRANSACTION_NOT_FOUND = "ACCOUNTS_FROM_ORIG_TRANSACTION_NOT_FOUND",
  MCC_CODE_NOT_ALLOWED = "MCC_CODE_NOT_ALLOWED",
  ORIGINAL_TRANSACTION_NOT_FOUND = "ORIGINAL_TRANSACTION_NOT_FOUND",
  NO_ORIGINAL_TRANSACTION_IN_REQUEST = "NO_ORIGINAL_TRANSACTION_IN_REQUEST",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  DECLINE = "DECLINE",
  WRONG_CSV2 = "WRONG_CSV2",
  INCORRECT_PIN = "INCORRECT_PIN",
  CARD_NOT_ACTIVATED = "CARD_NOT_ACTIVATED",
  PIN_TRIES_EXCEEDED = "PIN_TRIES_EXCEEDED",
  AUTHORISATION_EXPIRED = "AUTHORISATION_EXPIRED"
}
