import DOMPurify from "dompurify";
import { FC, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const RichTextEditor: FC<{
  value: string;
  updateValue: (value: string) => void;
}> = ({ updateValue }) => {
  const [rawValue, setRawValue] = useState<string>();

  useEffect(() => {
    if (!rawValue) return;

    const sanitizedHtml = DOMPurify.sanitize(rawValue); // Prevent injection / XSS
    updateValue(sanitizedHtml);
  }, [rawValue, updateValue]);

  return (
    <div id="container">
      <ReactQuill
        theme="snow"
        value={rawValue}
        onChange={setRawValue}
        style={{
          height: 200,
          paddingBottom: 30
        }}
        bounds="#container"
      />
    </div>
  );
};
