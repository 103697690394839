import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useState } from "react";
import { Country } from "react-addressfinder";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  EmployerForm,
  EmployerFormValuesType
} from "../../components/shared/EmployerForm";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { CardDelivery } from "../../models/employers/cardDelivery";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

export const Account = () => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer } = useData();
  const [error, setError] = useState("");

  const updateEmployerMutation = useMutation(
    async ({ formValues }: { formValues: EmployerFormValuesType }) => {
      const formattedPhoneNumber = parsePhoneNumberFromString(
        formValues.contact.phone,
        formValues.contact.address.country === "NZ" ? Country.NZ : Country.AU
      )?.number;

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...formValues,
            contactName:
              formValues.contactFirstName + " " + formValues.contactLastName,
            contact: {
              ...formValues.contact,
              contactFirstName: undefined,
              contactLastName: undefined,
              phone: formattedPhoneNumber,
              address: {
                ...formValues.contact.address,
                city: formValues.contact.address.city,
                // Only include state/province for outside of NZ
                stateOrProvince:
                  formValues.contact.address.country !== "NZ"
                    ? formValues.contact.address.stateOrProvince
                    : undefined
              }
            }
          })
        }
      );

      if (!response.ok) {
        let responseJson;
        try {
          responseJson = await response.json();
        } catch (err) {
          throw new Error("There was a problem updating your account.");
        }

        switch (response.status) {
          case 409:
            throw new Error("Email already in use. Please contact support");
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem updating your account.");
            }
          default:
            throw new Error("There was a problem updating your account.");
        }
      }
    },
    {
      onSuccess: () => {
        employer.refetch();
        toast.success("Account updated successfully");
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  if (employer.isIdle || employer.isLoading) return <LoadingSpinner />;

  return (
    <>
      <Grid container spacing={5} flexDirection="column">
        <Grid item>
          <Box minWidth="fit-content">
            <Typography variant="h4">Account</Typography>
          </Box>
        </Grid>
        <Grid item container spacing={5}>
          <Grid item xs={12} md={8}>
            <EmployerForm
              isCreateEmployer={false}
              isLoading={updateEmployerMutation.isLoading}
              employer={employer.data}
              onSubmit={(formValues: EmployerFormValuesType) => {
                updateEmployerMutation.mutate({ formValues });
              }}
            >
              {error && (
                <Grid item xs={12}>
                  <Typography color="error">{error}</Typography>
                </Grid>
              )}

              {employer.data?.createdAt ? (
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={<Checkbox checked={true} disabled={true} />}
                  label={
                    <span style={{ fontSize: 14 }}>
                      You agreed to our terms and conditions on{" "}
                      {new Date(employer.data?.createdAt).toLocaleDateString(
                        "en-NZ",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "2-digit"
                        }
                      )}
                    </span>
                  }
                />
              ) : null}
            </EmployerForm>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {employer.data?.generalOptions.expiryDate ? (
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ float: "left" }}>Expiry Date:</span>{" "}
                    <span style={{ float: "right" }}>
                      {new Date(
                        employer.data?.generalOptions.expiryDate
                      ).toLocaleDateString("en-NZ", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric"
                      })}
                    </span>
                  </Typography>
                </Grid>
              ) : null}
              {employer.data?.createdAt ? (
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ float: "left" }}>Account Activation:</span>{" "}
                    <span style={{ float: "right" }}>
                      {new Date(employer.data?.createdAt).toLocaleDateString(
                        "en-NZ",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "numeric"
                        }
                      )}
                    </span>
                  </Typography>
                </Grid>
              ) : null}
              {employer.data?.paymentOptions.minimumFloatPercent ? (
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ float: "left" }}>
                      Min. Float Requirement:
                    </span>{" "}
                    <span style={{ float: "right" }}>
                      {employer.data.paymentOptions.minimumFloatPercent * 100}%
                    </span>
                  </Typography>
                </Grid>
              ) : null}
              {employer.data?.paymentOptions.monthlyUserFee ? (
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ float: "left" }}>
                      Platform Fee (per user):
                    </span>{" "}
                    <span style={{ float: "right" }}>
                      {employer.data?.country.currencyName}
                      {formatCurrency(
                        employer.data.paymentOptions.monthlyUserFee,
                        2
                      )}
                    </span>
                  </Typography>
                </Grid>
              ) : null}
              {employer.data?.paymentOptions.issuedCardFee ? (
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ float: "left" }}>
                      Issuance Fee (per card):
                    </span>{" "}
                    <span style={{ float: "right" }}>
                      {employer.data?.country.currencyName}
                      {formatCurrency(
                        employer.data.paymentOptions.issuedCardFee,
                        2
                      )}
                    </span>
                  </Typography>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box display="flex" width="100%">
                  <Typography variant="subtitle1">Card Delivery</Typography>
                  <Select
                    sx={{ marginLeft: "auto" }}
                    disabled={true}
                    value={employer.data?.generalOptions.cardDelivery}
                    size="small"
                  >
                    <MenuItem value={CardDelivery.EMPLOYER_ADDRESS}>
                      Company
                    </MenuItem>
                    <MenuItem value={CardDelivery.EMPLOYEE_ADDRESS}>
                      Employee
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
