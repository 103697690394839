import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

export const LoadingSpinner: FC<{ marginY?: string }> = ({ marginY }) => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress sx={{ marginY: marginY ? marginY : "25vh" }} />
    </Box>
  );
};
