import { PhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { useMemo } from "react";
import * as yup from "yup";
import { Employee } from "../models";

// NOTE: Validation also exists in the my portal
// Any changes here should be reflected there too...

const nameRegex = /^[a-zA-Z\s-'.]*$/;
const whitespaceRegex = /^(?! ).*[^ ]$/;
const addressRegex = /^[a-zA-Z0-9/@'., -āēīōūĀĒĪŌŪ]+$/;

export const isMobileNumber = (
  phoneNumber: PhoneNumber,
  employeeCountryCode: string
) => {
  if (phoneNumber.country !== employeeCountryCode) return false; // If the derived phone numbers country code doesn't match the intended (employers)

  // Check the phone number matches the derived country format (eg. if its +64, next should be 2 for NZ)
  switch (phoneNumber.country) {
    case "NZ":
      return phoneNumber.nationalNumber.startsWith("2");
    case "AU":
      return (
        phoneNumber.nationalNumber.startsWith("4") ||
        phoneNumber.nationalNumber.startsWith("5")
      );
    default:
      return false;
  }
};

// Used only in Customer Portal, as we allow email address to change here.
// Not mirrored in the My Portal, as no address editing supported there.
export const isEmployeeEmailUnique = (
  allEmployees: Employee[],
  email: string
) => {
  const foundEmployeeWithEmail = allEmployees.find(
    (e) => e.contact.email === email
  );

  return !foundEmployeeWithEmail;
};

export const useDynamicEmployeeValidationSchema = (
  employeeCountryCode: any,
  allEmployees: Employee[] | undefined,
  currentEmployeeId?: string
) => {
  return useMemo(() => {
    const phoneNumberValidation = yup
      .string()
      .nullable() // Allows null values
      .test(
        "is-valid-mobile-phone",
        `Invalid ${employeeCountryCode} mobile phone number`,
        function (value) {
          if (!value) return true; // Allow empty values

          try {
            const phoneNumber: PhoneNumber | undefined =
              parsePhoneNumberFromString(value, employeeCountryCode);
            if (!phoneNumber) {
              return false;
            }

            //const numberType = getNumberType(phoneNumber.nationalNumber, countryCode); -> doesn't work for some reason, tried with custom metadata (check project.json scripts)
            return (
              phoneNumber.isValid() &&
              isMobileNumber(phoneNumber, employeeCountryCode)
            );
          } catch (error) {
            return this.createError({
              message: `Invalid phone number for country: ${employeeCountryCode}`
            });
          }
        }
      );

    const emailValidation = yup
      .string()
      .email("Invalid email")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format")
      .test(
        "not-invalid",
        "Email cannot contain special characters in invalid positions",
        (value) => !/^\S+\*|\S+@-/.test(value ?? "")
      )
      .test("unique", "Email already in use", function (value) {
        if (!value || !allEmployees) return true; // Allow empty values

        return isEmployeeEmailUnique(
          allEmployees.filter((e) => e.id !== currentEmployeeId), // Don't check uniqueness against current employee's email
          value
        );
      })
      .required("Email is required");

    return yup.object().shape({
      customerEmployeeId: yup.string().max(100),
      firstName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in first name")
        .matches(
          whitespaceRegex,
          "First name cannot start or end with a space"
        ),
      lastName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in last name")
        .matches(whitespaceRegex, "Last name cannot start or end with a space"),
      cardDisplayName: yup
        .string()
        .nullable()
        .notRequired()
        .max(28, "Card display name cannot be longer than 28 characters")
        .matches(nameRegex, "Invalid characters in card display name")
        .test(
          "at-least-one-space",
          "Card display name must contain at least one space and cannot start or end with a space",
          (value) => {
            if (value) {
              // Check if there is at least one space and the last character is not a space
              return (
                value.includes(" ") &&
                value[value.length - 1] !== " " &&
                value[0] !== " "
              );
            }
            return true; // If no value, then pass as value is not required.
          }
        ),
      contact: yup.object().shape({
        phone: phoneNumberValidation,
        email: emailValidation,
        address: yup.object().shape({
          // Allow address to be optional if employee address1 is not provided eg. in the editUser modal, to allow employers to not provide an employee address if employee hasn't added their address yet but employer still wants to edit.
          address1: yup
            .string()
            .max(100)
            .matches(addressRegex, "Invalid characters in address"),
          address2: yup
            .string()
            .nullable()
            .max(100)
            .matches(addressRegex, "Invalid characters in address"),
          city: yup.string().max(100),
          postalCode: yup
            .number()
            .when("address1", (address1, schema) =>
              address1.toString().length > 0
                ? schema.required("Postal Code is required.")
                : schema
            ),
          country: yup
            .string()
            .max(100)
            .when("address1", (address1, schema) =>
              address1.toString().length > 0
                ? schema.required("Country is required.")
                : schema
            )
        })
      })
    });
  }, [allEmployees, currentEmployeeId, employeeCountryCode]);
};

export const useDynamicImportEmployeeValidationSchema = (
  employeeCountryCode: any,
  allEmployees: Employee[] | undefined
) => {
  return useMemo(() => {
    const phoneNumberValidation = yup
      .string()
      .nullable() // Allows null values
      .test(
        "is-valid-mobile-phone",
        `Invalid ${employeeCountryCode} mobile phone number`,
        function (value) {
          if (!value) return true; // Allow empty values

          try {
            const phoneNumber: PhoneNumber | undefined =
              parsePhoneNumberFromString(value, employeeCountryCode);
            if (!phoneNumber) {
              return false;
            }

            return (
              phoneNumber.isValid() &&
              isMobileNumber(phoneNumber, employeeCountryCode)
            );
          } catch (error) {
            return this.createError({
              message: `Invalid phone number for country: ${employeeCountryCode}`
            });
          }
        }
      );

    const emailValidation = yup
      .string()
      .email("Invalid email")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format")
      .test(
        "not-invalid",
        "Email cannot contain special characters in invalid positions",
        (value) => !/^\S+\*|\S+@-/.test(value ?? "")
      )
      .test("unique", "Email already in use", function (value) {
        if (!value || !allEmployees) return true; // Allow empty values

        return isEmployeeEmailUnique(allEmployees, value);
      })
      .required("Email is required");

    return yup.object().shape({
      firstName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in first name")
        .matches(
          whitespaceRegex,
          "First name cannot start or end with a space"
        ),
      lastName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in last name")
        .matches(whitespaceRegex, "Last name cannot start or end with a space"),
      contact: yup
        .object()
        .required()
        .shape({
          phone: phoneNumberValidation,
          email: emailValidation,
          address: yup.object().shape({
            address1: yup
              .string()
              .max(100)
              .matches(addressRegex, "Invalid characters in address"),
            address2: yup
              .string()
              .nullable()
              .max(100)
              .matches(addressRegex, "Invalid characters in address"),
            city: yup.string().max(100),
            stateOrProvince: yup.string().max(3),
            postalCode: yup.number(),
            country: yup.string().max(100)
          })
        })
    });
  }, [allEmployees, employeeCountryCode]);
};

export const useDynamicEmployerValidationSchema = (
  employerCountryCode: any
) => {
  return useMemo(() => {
    const phoneNumberValidation = yup
      .string()
      .nullable() // Allows null values
      .test(
        "is-valid-mobile-phone",
        `Invalid ${employerCountryCode} mobile phone number`,
        function (value) {
          if (!value) return true; // Allow empty values
          try {
            const phoneNumber: PhoneNumber | undefined =
              parsePhoneNumberFromString(value, employerCountryCode);
            if (!phoneNumber) {
              return false;
            }

            //const numberType = getNumberType(phoneNumber.nationalNumber, countryCode); -> doesn't work for some reason, tried with custom metadata (check project.json scripts)
            return (
              phoneNumber.isValid() &&
              isMobileNumber(phoneNumber, employerCountryCode)
            );
          } catch (error) {
            return this.createError({
              message: `Invalid phone number for country: ${employerCountryCode}`
            });
          }
        }
      );

    return yup.object().shape({
      contactFirstName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in first name")
        .matches(
          whitespaceRegex,
          "First name cannot start or end with a space"
        ),
      contactLastName: yup
        .string()
        .max(100)
        .required()
        .matches(nameRegex, "Invalid characters in last name")
        .matches(whitespaceRegex, "Last name cannot start or end with a space"),
      name: yup.string().max(100).required(),
      contact: yup.object().shape({
        phone: phoneNumberValidation,
        email: yup
          .string()
          .email("Invalid email")
          .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email") // Checks email domain (eg. example@google.com VALID, example@google INVALID)
          .test(
            "not-invalid",
            "Invalid email",
            (value) => !/^\S+\*|\S+@-/.test(value ?? "")
          )
          .required(),
        address: yup.object().shape({
          address1: yup
            .string()
            .max(100)
            .required()
            .matches(addressRegex, "Invalid characters in address"),
          address2: yup
            .string()
            .nullable()
            .max(100)
            .matches(addressRegex, "Invalid characters in address"),
          city: yup.string().max(100),
          stateOrProvince: yup.string().max(3),
          postalCode: yup.number().required(),
          country: yup.string().max(100).required()
        })
      })
    });
  }, [employerCountryCode]);
};
