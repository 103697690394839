import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee, TagReference } from "../../models";
import { checkJobProgress } from "../../utils/checkJobProgress";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { TagSelection } from "./TagSelection";

type ApplyTagsProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const ApplyTags = ({
  open,
  handleComplete,
  handleClose,
  selectedEmployees
}: ApplyTagsProps) => {
  const { config } = useConfig();
  const { employerId, employees } = useData();
  const { getToken } = useKindeAuth();

  const [tags, setTags] = useState<TagReference[]>([]);
  const [error, setError] = useState("");

  const applySynchronously = useMutation({
    mutationFn: async () => {
      // Initialize a progress toast
      const totalEmployees = selectedEmployees.length;
      let completed = 0;
      let toastId = toast("Assigning Tags...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      await Promise.all(
        selectedEmployees.map(async (employee) => {
          const response = await fetch(
            `${config?.API_URL}/employers/${employerId}/employees/${employee.id}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${await getToken()}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                ...employee,
                tags
              })
            }
          );

          if (!response.ok) {
            throw new Error("There was a problem assign these Tags");
          }

          // Update the progress on the toast
          completed += 1;
          toast.update(toastId, {
            progress: completed / totalEmployees
          });
        })
      );

      // Finish the toast
      toast.update(toastId, {
        render: "Tags assigned successfully",
        type: "success",
        autoClose: 5000,
        progress: 1
      });
    },
    onSuccess: () => {
      toast.success("Tags assigned successfully");
      employees.refetch();
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const onJobComplete = async () => {
    await employees.refetch();
    handleClose();
  };

  const applyAsynchronously = useMutation({
    mutationFn: async () => {
      // Initialize a progress toast
      let toastId = toast("Assigning Tags...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const selectedEmployeesIds = selectedEmployees.map((x) => x.id);
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/tags`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employeeIds: selectedEmployeesIds,
            tags
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem assign these tags");
      }

      const responseBody = await response.json();

      checkJobProgress(
        config!.API_URL,
        responseBody.jobId,
        toastId,
        "Tags assignment completed!",
        getToken,
        onJobComplete
      );
    },
    onSuccess: () => {
      toast.success("Tags will be assigned in background!");
      employees.refetch();
      handleClose();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (selectedEmployees.length === 0) {
      return;
    } else if (selectedEmployees.length <= 10) {
      applySynchronously.mutate();
    } else {
      applyAsynchronously.mutate();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Apply tags to {selectedEmployees.length} user(s)
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={3}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography>General instructions on assigning tags</Typography>
              <Typography color="gray">
                The tags set here on the selected user(s) will overwrite any
                existing tags applied to these user(s)
              </Typography>
            </Grid>
            <Grid item container gap={2}>
              <Grid container item spacing={2} xs={10}>
                <Grid item xs={12}>
                  <Typography variant="h6">Tags</Typography>
                </Grid>
                <TagSelection tags={tags} setTags={setTags} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={
              applyAsynchronously.isLoading || applySynchronously.isLoading
            }
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
