import { Replay } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { ReactComponent as ExpiredCalendar } from "../../../assets/expired-calendar.svg";
import { PlanType, RenewalStrategy } from "../../../models";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";

export const Step5RenewalStrategy: FC<{
  formik: FormikProps<CreatePlanInput>;
  next: () => void;
  back: () => void;
}> = ({ formik, next, back }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        What happens to any residual balance at the end of the renewal period?
      </Typography>
      <Box display="flex" width="100%" gap={3} my={4}>
        {formik.values.planType === PlanType.ONE_OFF ? (
          <AccountRadioBox
            title={"Expires"}
            content={"Balance expires"}
            icon={<ExpiredCalendar />}
            isSelected={
              formik.values.renewalOptions.strategy === RenewalStrategy.EXPIRE
            }
            onClick={() =>
              formik.setFieldValue(
                "renewalOptions.strategy",
                RenewalStrategy.EXPIRE
              )
            }
          />
        ) : (
          <>
            <AccountRadioBox
              title={"Resets"}
              content={"Balance resets"}
              icon={<ExpiredCalendar />}
              isSelected={
                formik.values.renewalOptions.strategy === RenewalStrategy.RESET
              }
              onClick={() =>
                formik.setFieldValue(
                  "renewalOptions.strategy",
                  RenewalStrategy.RESET
                )
              }
            />
            <AccountRadioBox
              title={"Rolls over"}
              content={"Balance rolls over to the next period"}
              icon={
                <Replay style={{ transform: "scaleX(-1) rotate(-45deg)" }} />
              }
              isSelected={
                formik.values.renewalOptions.strategy ===
                RenewalStrategy.CARRY_OVER
              }
              onClick={() =>
                formik.setFieldValue(
                  "renewalOptions.strategy",
                  RenewalStrategy.CARRY_OVER
                )
              }
            />
          </>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} outlined />
        <Button variant="contained" onClick={next}>
          Continue
        </Button>
      </Box>
    </Grid>
  );
};
