import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import React, { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee, EmployeeAccount } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

type AssignToPlanProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployee: Employee;
};

export const TopUpAccount = ({
                               open,
                               handleComplete,
                               handleClose,
                               selectedEmployee
                             }: AssignToPlanProps) => {
  const { config } = useConfig();
  const { employerId, employees, accounts } = useData();
  const [selectedAccount, setSelectedAccount] = useState<EmployeeAccount | null>(null);
  const [amount, setAmount] = useState<string>("");
  const { getToken } = useKindeAuth();
  const [error, setError] = useState<string>("");

  // Filter accounts for the selected employee
  const employeeAccounts = accounts.data?.items.filter(
    (account) => account.employeeId === selectedEmployee.id
  ) ?? [];

  const topupAsynchronously = useMutation({
    mutationFn: async (params: { selectedAccount: EmployeeAccount; amount: number }) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/${selectedEmployee.id}/accounts/${params.selectedAccount.id}/top-ups`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            amount: params.amount
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem topping up this account.");
      }
    },
    onSuccess: () => {
      toast.success("Account topped up!");
      employees.refetch();
      accounts.refetch();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (selectedAccount && amount) {
      const amountValue = Number(amount);
      if (amountValue <= 0) {
        setError("Please enter an amount greater than 0.");
        return;
      }
      topupAsynchronously.mutate({ selectedAccount, amount: amountValue });
    } else {
      setError("Please select an account and enter an amount.");
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <DialogTitle>
          One-time top-up for {selectedEmployee.firstName} {selectedEmployee.lastName}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography my={1}>Account</Typography>
              {employeeAccounts.length === 0 ? (
                <Typography color="error">
                  This employee does not have any associated accounts.
                </Typography>
              ) : (
                <Autocomplete
                  size="small"
                  options={employeeAccounts}
                  getOptionLabel={(option) =>
                    `${option.name} - Balance: ${option.availableBalance.amount}`
                  }
                  value={selectedAccount}
                  onChange={(_, newValue) => setSelectedAccount(newValue)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select an account" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography my={1}>Amount</Typography>
              <TextField
                fullWidth
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  if (error && Number(e.target.value) > 0) {
                    setError("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="gray">
                This amount will be added to the employee’s account. It will expire or roll over when the account renews.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={topupAsynchronously.isLoading}
            disabled={!selectedAccount || !amount}
          >
            Top Up
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
