import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { InvitedEmployee, Plan, PlanReference } from "../../models";
import { calculateMinimumFloatAmount } from "../../pages/onboarding/Onboarding";
import { formatCurrency } from "../../utils/formatters";
import { extraordinaryColors } from "../../utils/theme";

export const FloatSummary: FC<{
  invitedEmployees: InvitedEmployee[];
  adminEmployeePlanReference: PlanReference | undefined;
  plans: Plan[];
}> = ({ invitedEmployees, adminEmployeePlanReference, plans }) => {
  return (
    <Box
      gap={1}
      sx={{
        padding: 2,
        borderWidth: 2,
        bgcolor: extraordinaryColors.yellow.light,
        borderColor: extraordinaryColors.yellow.light,
        borderStyle: "solid",
        borderRadius: 3
      }}
    >
      <Typography fontSize={16} fontWeight={800}>
        Summary
      </Typography>
      <Divider sx={{ borderColor: "primary.main", my: 1 }} />
      {plans.map((p) => {
        return (
          <>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography fontSize={16}>{p.name}</Typography>
              <Typography fontSize={16}>
                {formatCurrency(
                  calculateMinimumFloatAmount(
                    invitedEmployees,
                    adminEmployeePlanReference,
                    [p]
                  )
                )}
              </Typography>
            </Box>

            <Box mt={0.5}>
              {p.tiers.map((t) => {
                let tierEmployeeCount = invitedEmployees.filter((ie) =>
                  ie.plans.find((p) => p.tierId === t.id)
                ).length;

                if (t.id === adminEmployeePlanReference?.tierId) {
                  tierEmployeeCount++;
                }
                return (
                  <Typography fontSize={14} color="primary">
                    {tierEmployeeCount}x {t.name}
                  </Typography>
                );
              })}
            </Box>
          </>
        );
      })}
      <Divider sx={{ borderColor: "primary.main", mt: 2 }} />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography fontSize={16}>Total</Typography>
        <Typography fontSize={16}>
          {formatCurrency(
            calculateMinimumFloatAmount(
              invitedEmployees,
              adminEmployeePlanReference,
              plans
            )
          )}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Typography fontSize={16} fontWeight={700}>
          Due Today
        </Typography>
        <Typography fontSize={16} fontWeight={700}>
          {formatCurrency(
            calculateMinimumFloatAmount(
              invitedEmployees,
              adminEmployeePlanReference,
              plans
            ) * 0.1
          )}
        </Typography>
      </Box>
    </Box>
  );
};
