import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { PlanReference, TagReference } from "../../models";
import { PlanSelection } from "./PlanSelection";

const DEFAULT_VALUE = "-1";

type CompleteImportDialogProps = {
  isLoading: boolean;
  open: boolean;
  handleClose: () => void;
  employeesCount: number;
  onComplete: (plans: PlanReference[], tags: TagReference[], sendInvitationEmail: boolean) => void
};

export const CompleteImportDialog = ({
                                       isLoading,
                                       open,
                                       handleClose,
                                       employeesCount,
                                       onComplete
                                     }: CompleteImportDialogProps) => {
  const [sendInvitationEmail, setSendInvitationEmail] = useState<boolean>(false);
  const [plans, setPlans] = useState<PlanReference[]>([]);
  const [tags, setTags] = useState<TagReference[]>([]);

  useEffect(() => {
    setSendInvitationEmail(false);
    setPlans([]);
    setTags([]);
  }, []);

  const completeImport = ()=> {
    onComplete(plans, tags, sendInvitationEmail);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Import {employeesCount} Employees
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid container spacing={2} p={3}>
              <PlanSelection
                selectedPlans={plans}
                setSelectedPlans={setPlans}
                DEFAULT_VALUE={DEFAULT_VALUE}
              />
            </Grid>
            {/*<Grid item container gap={2}>*/}
            {/*  <TagSelection tags={tags} setTags={setTags} />*/}
            {/*</Grid>*/}
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendInvitationEmail}
                onChange={(e) => setSendInvitationEmail(e.target.checked)}
              />
            }
            label={<span>Send Invitation Email</span>}
          />
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={completeImport}
            disabled={isLoading}
          >
            Import
          </LoadingButton>
        </DialogActions>
    </Dialog>
  );
};
