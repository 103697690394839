import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { Invoice, InvoiceType } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { LoadingSpinner } from "../shared/LoadingSpinner";
import { PaymentDialog } from "./PaymentDialog";

export const FloatPaymentBox = () => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { float, employer, invoices } = useData();

  const [amount, setAmount] = useState<number>(0);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);

  const createFloatInvoice = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data!.id}/invoices`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            type: InvoiceType.FLOAT,
            amount: amount
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem processing the payment");
      }

      const invoice = await response.json();
      setInvoice(invoice);
    },
    onSuccess: () => {
      setPaymentDialogOpen(true);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const handleAddPayment = async () => {
    setError(undefined);
    if (isNaN(amount) || amount <= 0) {
      setError("Amount needs to be a positive number");
      return;
    }
    setProcessingPayment(true);
    await createFloatInvoice.mutateAsync();
  };

  const onSuccessfulPayment = async () => {
    if (invoice == null) {
      return;
    }

    try {
      await invoices.refetch();
      await float.refetch();
      setPaymentDialogOpen(false);
      setProcessingPayment(false);
    } catch (e) {
      setError(
        "There was an error while processing your payment. Please contact support!"
      );
    }
  };

  if (float.isIdle || float.isLoading || employer.isLoading)
    return <LoadingSpinner />;

  if (!float.data) return <Typography>Error Loading</Typography>;

  return (
    <>
      {invoice != null && (
        <PaymentDialog
          amount={invoice.amount}
          reference={employer.data!.name}
          title={`Float top up`}
          open={paymentDialogOpen}
          invoiceId={invoice.id}
          countryId={employer.data?.country.id ?? ""}
          onCancel={() => {
            setPaymentDialogOpen(false);
            setProcessingPayment(false);
          }}
          onSuccess={onSuccessfulPayment}
        />
      )}
      <Grid item xs={12} md={6} mt={2} display="flex" justifyContent="center">
        <Box minWidth="fit-content">
          <LinearProgress
            variant="determinate"
            value={
              ((float.data.minimumAmount.amount -
                float.data.availableAmount.amount) /
                float.data.minimumAmount.amount) *
              100
            }
            sx={{ height: 36 }}
          />
          <Typography py={2}>
            You have used{" "}
            {(float.data.minimumAmount.amount -
              float.data.availableAmount.amount) /
              float.data.minimumAmount.amount <
            0
              ? 0
              : (
                  ((float.data.minimumAmount.amount -
                    float.data.availableAmount.amount) /
                    float.data.minimumAmount.amount) *
                  100
                ).toFixed(0)}
            % of your Available Float. Paying any outstanding{" "}
            <Link to="/billing">invoices</Link> will reset this amount or you
            can top up your Available Float.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ m: 1, px: 3 }}
            gap={2}
          >
            <TextField
              value={amount}
              onChange={(e) =>
                setAmount(parseFloat(e.target.value.replace(/[^0-9.-]+/g, "")))
              }
              label="Amount"
              required
              type="number"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputMode: "numeric"
              }}
              sx={{ maxWidth: 150 }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ m: 0 }}
            >
              <LoadingButton
                onClick={handleAddPayment}
                variant="contained"
                color="primary"
                loading={
                  processingPayment ||
                  createFloatInvoice.isLoading ||
                  employer.isLoading ||
                  employer.data === undefined
                }
                autoFocus
              >
                Top Up
              </LoadingButton>
            </Box>
          </Box>
          {error && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ m: 0 }}
            >
              <Typography color={"error"}>{error}</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};
