import { Employee } from "../../models";
import { Report } from "../../models/reporting/report";
import { ReportEntity } from "../../models/reporting/reportEntity";
import {
  ReportFilter,
  ReportFilterOperator
} from "../../models/reporting/reportFilter";
import { ReportPeriod } from "../../models/reporting/reportPeriod";

const applyReportFilters = (
  documents: any[],
  report: Report,
  employees: Employee[]
) => {
  const entityFilters = report.filters?.filter(
    (f) => f.entity === report.entity
  );
  const crossEntityFilters = report.filters?.filter(
    (f) => f.entity !== report.entity
  );

  const applyDateFilters = (docs: any[]) => {
    const currentDate = new Date();

    if (report.data.period === ReportPeriod.ALL_TIME) {
      return docs;
    } else if (report.data.period === ReportPeriod.WEEK) {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

      const endOfWeek = new Date(currentDate);
      endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

      return docs.filter((doc) => {
        let documentDate =
          report.entity === ReportEntity.TRANSACTIONS && doc?.timestamp
            ? new Date(doc.timestamp.split("T")[0])
            : new Date(doc.createdAt);

        return documentDate >= startOfWeek && documentDate <= endOfWeek;
      });
    } else if (report.data.period === ReportPeriod.MONTH) {
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      return docs.filter((doc) => {
        let documentDate =
          report.entity === ReportEntity.TRANSACTIONS && doc?.timestamp
            ? new Date(doc.timestamp.split("T")[0])
            : new Date(doc.createdAt);

        return documentDate >= startOfMonth && documentDate <= endOfMonth;
      });
    } else if (report.data.period === ReportPeriod.YEAR) {
      const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
      const endOfYear = new Date(currentDate.getFullYear(), 11, 31);

      return docs.filter((doc) => {
        let documentDate =
          report.entity === ReportEntity.TRANSACTIONS && doc?.timestamp
            ? new Date(doc.timestamp.split("T")[0])
            : new Date(doc.createdAt);

        return documentDate >= startOfYear && documentDate <= endOfYear;
      });
    }

    return docs;
  };

  const applyEntityFilters = (docs: any[]) => {
    return docs.filter((doc) =>
      entityFilters?.every((filter: ReportFilter) => {
        if (filter.operator === ReportFilterOperator.EQUALS) {
          return doc[filter.path] === filter.value;
        } else if (filter.operator === ReportFilterOperator.CONTAINS) {
          return doc[filter.path].find((dfp: any) => dfp.id === filter.value);
        }
        // Add support for other filter operators if needed
        return true;
      })
    );
  };

  const applyCrossEntityFilters = (docs: any[]) => {
    if (crossEntityFilters?.length === 0) return docs;
    return docs.filter((doc) => {
      const employeeId = doc.employeeId;
      const employee: any = employees.find((emp) => emp.id === employeeId);
      if (!employee) return false;
      return crossEntityFilters?.every((filter: ReportFilter) => {
        if (filter.operator === ReportFilterOperator.EQUALS) {
          return employee[filter.path] === filter.value;
        }
        // Add support for other filter operators if needed
        return true;
      });
    });
  };

  let filteredDocuments = applyDateFilters(documents);
  filteredDocuments = applyEntityFilters(filteredDocuments);
  filteredDocuments = applyCrossEntityFilters(filteredDocuments);

  return filteredDocuments;
};

export default applyReportFilters;
