import { Id, toast } from "react-toastify";

export const checkJobProgress = async (
  apiUrl: string,
  jobId: string,
  toastId: Id,
  completeMessage: string,
  getToken:  () => Promise<string | undefined>,
  onFinish:  () => Promise<void>) => {
  // ToDo: add employerId to the query
  const statusResponse = await fetch(`${apiUrl}/jobs/${jobId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });

  if (!statusResponse.ok) {
    throw new Error("Failed to fetch job status");
  }

  const jobDetails = await statusResponse.json();

  if (jobDetails.progress.final > 0) {
    const progress = jobDetails.progress.successCount / jobDetails.progress.final;
    toast.update(toastId, {
      progress
    });

    if (jobDetails.progress.successCount < jobDetails.progress.final) {
      setTimeout(() => checkJobProgress(apiUrl, jobId, toastId, completeMessage, getToken, onFinish), 2000);  // Check again after 2 seconds
    } else {
      toast.update(toastId, {
        render: completeMessage,
        type: "success",
        autoClose: 5000,
        progress: 1
      });
      await onFinish();
    }
  }
};
