import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { Fragment } from "react";
import { Check, X } from "react-feather";
import { useQuery } from "react-query";
import { Transaction, TransactionStatus } from "../../models";
import { Pagination } from "../../models/pagination";
import { sortDate } from "../../utils/date";
import { fetchData } from "../../utils/fetchData";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { LoadingSpinner } from "../shared/LoadingSpinner";

type AllTransactionsProps = {
  open: boolean;
  handleClose: () => void;
  employeeId: string;
};

export const AllTransactions = ({
  open,
  handleClose,
  employeeId
}: AllTransactionsProps) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const transactions = useQuery<Pagination<Transaction>>(
    `${config?.API_URL}/transactions?employeeId=${employeeId}`,
    () =>
      fetchData(
        `${config?.API_URL}/transactions`,
        getToken,
        `employeeId=${employeeId}`
      )
  );

  const allTransactions = transactions.data?.items.sort((a, b) =>
    sortDate({ dateA: a.timestamp, dateB: b.timestamp, ascending: false })
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>All transactions</DialogTitle>
        <IconButton onClick={handleClose} sx={{ mr: 2 }}>
          <X />
        </IconButton>
      </Box>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {transactions.isLoading ? (
              <LoadingSpinner />
            ) : allTransactions?.length ? (
              <List>
                {allTransactions.map((transaction, index) => (
                  <Fragment key={index}>
                    <ListItem>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {/* First Row */}
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              {transaction.status ===
                              TransactionStatus.DECLINED.toString() ? (
                                <X color="red" />
                              ) : (
                                <Check color="green" />
                              )}
                            </Grid>
                            <Grid item xs>
                              <ListItemText
                                primary={transaction.cardAcceptor ?? ""}
                                secondary={formatDate(transaction.timestamp)}
                              />
                            </Grid>
                            <Grid item>
                              <Typography>
                                {formatCurrency(
                                  transaction.billingAmount.amount
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index < allTransactions.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </List>
            ) : (
              <Typography>No transactions</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
