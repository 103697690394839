import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { TagReference } from "../../models/tagReference";
import { useData } from "../../utils/useData";

type TagSelectionProps = {
  tags: TagReference[];
  setTags: React.Dispatch<React.SetStateAction<TagReference[]>>;
};

export const TagSelection = ({ tags, setTags }: TagSelectionProps) => {
  const { tagCategories } = useData();

  if (!tagCategories.data?.items.length) return <div />;

  return (
    <>
      {tagCategories.data?.items.map((tagCategory, i) => (
        <Grid key={i} item xs={12}>
          <InputLabel id={tagCategory.id}>{tagCategory.name}</InputLabel>
          <Select
            key={tagCategory.id}
            labelId={tagCategory.id}
            value={
              tags.find((tag) => tag.tagCategoryId === tagCategory.id)?.tagId ??
              "none"
            }
            onChange={(e: SelectChangeEvent) => {
              const value =
                e.target.value === "none"
                  ? undefined
                  : {
                      tagCategoryId: tagCategory.id,
                      tagId: e.target.value
                    };

              if (value) {
                setTags((tags) => [
                  ...tags.filter((tag) => tag.tagCategoryId !== tagCategory.id),
                  value
                ]);
              } else {
                setTags((tags) => [
                  ...tags.filter((tag) => tag.tagCategoryId !== tagCategory.id)
                ]);
              }
            }}
            fullWidth
            required
            size="small"
          >
            {tagCategory.tags.map((tag) => (
              <MenuItem value={tag.id} key={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
            <MenuItem value="none">None</MenuItem>
          </Select>
        </Grid>
      ))}
    </>
  );
};
