import {
  Box, Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Plus, Trash } from "react-feather";
import * as yup from "yup";
import { Employee, EditMode, Tier, EmployeeAccount } from "../../models";
import { useData } from "../../utils/useData";
import { useFormik } from "formik";

type PlanTiersProps = {
  isPilot: boolean;
  initialTiers: Tier[];
  employees: Employee[];
  accounts: EmployeeAccount[];
  editMode: EditMode;
  onBack: () => void;
  saveTiers: (tiers: Tier[]) => void;
  onNext: () => void;
};

const AmountAndCurrencySchema = yup.object().shape({
  amount: yup
    .number()
    .positive()
    .moreThan(0, "Tier Amount must be greater than 0")
    .required()
    .typeError("Tier Amount must be a number")
});

const TierSchema = yup.object().shape({
  id: yup.string().notRequired(), // `undefined` is allowed so `notRequired` is used
  name: yup
    .string()
    .required("Tier Name is required")
    .notOneOf([""], "Tier Name cannot be empty"),
  amount: AmountAndCurrencySchema // Use the AmountAndCurrency schema here
});

const PlanTiersSchema = yup.object().shape({
  tiers: yup
    .array()
    .of(TierSchema)
    .min(1, "At least one Tier is required")
    .required()
});

export const PlanTiersForm = ({
                                isPilot,
                                initialTiers,
                                employees,
                                accounts,
                                editMode,
                                onBack,
                                saveTiers,
                                onNext
                              }: PlanTiersProps) => {
  const { membership } = useData();

  const [tierErrors, setTierErrors] = useState<
    {
      name: string;
      amount: { amount: string };
    }[]
  >([]);

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      tiers: initialTiers
    },
    validationSchema: PlanTiersSchema,
    onSubmit: async (values) => {
      saveTiers(values.tiers);
      onNext();
    },
    enableReinitialize: true
  });

  const handleAddTier = () => {
    const newTiers = [...formik.values.tiers];
    newTiers.push({
      id: undefined,
      name: "",
      amount: {
        amount: 0
      }
    });
    formik.setFieldValue("tiers", newTiers);
  };

  const handleRemoveTier = (indexToRemove: number) => {
    // Create a new array excluding the tier at the specified index
    const newTiers = formik.values.tiers.filter(
      (_: Tier, index: number) => index !== indexToRemove
    );

    // Update the form state with the new tiers array
    formik.setFieldValue("tiers", newTiers);
  };

  const handleTierNameChange = (index: number, newName: string) => {
    const newTiers = [...formik.values.tiers];
    newTiers[index].name = newName;
    formik.setFieldValue("tiers", newTiers);
  };

  const handleTierAmountChange = (index: number, newAmount: number) => {
    const newTiers = [...formik.values.tiers];
    newTiers[index].amount.amount = newAmount;
    formik.setFieldValue("tiers", newTiers);
  };

  const renderTier = (tier: Tier, index: number) => {
    const tierNameError = tierErrors?.[index]?.name;
    const tierAmountError = tierErrors?.[index]?.amount?.amount;

    const canDelete = tier.id === undefined || !accounts.some((a) => a.tierId === tier.id);
    const tooltipTitle = !canDelete
      ? "Unable to remove the tier as it currently has employees assigned to it."
      : "Remove Tier";

    return (
      <ListItem
        key={index}
        secondaryAction={
          <Tooltip title={tooltipTitle}>
            <div>
              <IconButton
                disabled={!canDelete || isPilot}
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveTier(index)}
              >
                <Trash />
              </IconButton>
            </div>
          </Tooltip>
        }
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <TextField
              disabled={isPilot}
              variant="standard"
              fullWidth={true}
              value={tier.name}
              onChange={(e) => handleTierNameChange(index, e.target.value)}
              error={!!tierNameError}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={isPilot}
              type="number"
              variant="standard"
              fullWidth={true}
              value={tier.amount.amount}
              onChange={(e) =>
                handleTierAmountChange(index, parseFloat(e.target.value))
              }
              error={!!tierAmountError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="error">
              {tierNameError?.replace(`tiers[${index}].name`, "Tier Name")}
            </Typography>
            <Typography color="error">
              {tierAmountError?.replace(
                `tiers[${index}].amount.amount`,
                "Tier Amount"
              )}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  // Handle membership tier limit restrictions
  useEffect(() => {
    const newTierErrors = formik.values.tiers.map((tier: any, i: number) => {
      if (formik.errors.tiers?.[i]) {
        return formik.errors.tiers[i];
      }

      if (
        membership.data?.features?.maxTierAmount &&
        formik.values.tiers[i].amount.amount >
        membership.data?.features.maxTierAmount
      ) {
        formik.setFieldError(
          `tiers[${i}].amount.amount`,
          "Tier amount exceeds the maximum amount"
        );
        formik.setFieldTouched(`tiers[${i}].amount.amount`, true);

        return {
          amount: {
            amount: `Tier amount exceeds max amount ${membership.data.features.maxTierAmount}`
          }
        };
      } else {
        formik.setFieldError(`tiers[${i}].amount.amount`, undefined);
        return null;
      }
    });

    // @ts-ignore
    setTierErrors(newTierErrors);
  }, [
    formik.values.tiers,
    formik.errors.tiers,
    membership.data?.features?.maxTierAmount
  ]);

  const handleBack = () => {
    saveTiers(formik.values.tiers);
    onBack();
  };

  return (
    <Grid container spacing={2}>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Grid item xs={12} md={12} mt={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }}>
                Contribution tiers
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton disabled={isPilot} onClick={handleAddTier} sx={{ display: "flex", alignItems: "center" }}>
                <Plus />
              </IconButton>
            </Box>
          </Box>


          <List>{formik.values.tiers.map(renderTier)}</List>
          {/* Display an error message if there are no tiers */}
          {formik.touched.tiers &&
            formik.errors.tiers &&
            formik.values.tiers.length === 0 && (
              <Typography color="error" sx={{ mt: 2 }}>
                At least one Tier is required
              </Typography>
            )}
          {editMode === EditMode.EDIT &&
            <Typography sx={{ mt: 2, mb: 4 }} variant="body2">
              Changes to tiers will be effective on the next renewal date
            </Typography>
          }
        </Grid>
        <Grid item xs={12} mt={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button onClick={handleBack} color="secondary">
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
              Next
            </Button>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
};
