import { Button, Card, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { Plus } from "react-feather";
import { CreateReport } from "../../components/reporting/CreateReport";
import { RenderReport } from "../../components/reporting/RenderReport";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Report } from "../../models/reporting/report";
import { ReportEntity } from "../../models/reporting/reportEntity";
import { useData } from "../../utils/useData";

export const Reporting = () => {
  const { employees, transactions, cards, reports, accounts } = useData();
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const resolveReportDocuments = (reportEntity: ReportEntity) => {
    switch (reportEntity) {
      case ReportEntity.EMPLOYEES:
        return (
          employees.data?.items.map((employee) => ({
            ...employee,
            accounts: accounts.data?.items.filter((account) => account.employeeId === employee.id) ?? []
          })) ?? []
        );
      case ReportEntity.TRANSACTIONS:
        return transactions.data?.items ?? [];
      case ReportEntity.CARDS:
        return cards.data?.items ?? [];
      default:
        return [];
    }
  };

  if (
    employees.isIdle ||
    employees.isLoading ||
    accounts.isIdle ||
    accounts.isLoading ||
    transactions.isIdle ||
    transactions.isLoading ||
    cards.isIdle ||
    cards.isLoading ||
    reports.isIdle ||
    reports.isLoading
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border: "1px solid",
          borderRadius: 1,
          borderColor: "divider",
          boxShadow: "inherit",
          marginBottom: "10px"
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{
            padding: 2,
            overflowX: "auto"
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsCreatingReport(true)}
            endIcon={<Plus size={16} />}
            size="medium"
          >
            Create Report
          </Button>
        </Stack>
      </Card>
      <Grid container spacing={2} my={1}>
        {reports.data?.items.map((report: Report, i) => (
          <RenderReport
            key={i}
            report={report}
            documents={resolveReportDocuments(report.entity)}
          />
        ))}
      </Grid>
      {isCreatingReport && (
        <CreateReport
          open={true}
          handleClose={() => setIsCreatingReport(false)}
        />
      )}
    </>
  );
};
