import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, {
  FormEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState
} from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  CardStatus,
  Employee, EmployeeAccount,
  EmployeeStatus,
  PlanReference,
  TagReference
} from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { useDynamicEmployeeValidationSchema } from "../../utils/useDynamicValidationSchema";
import { AddressFinderForm } from "../AddressFinderForm";
import { Card } from "../shared/Card";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { UserPlans } from "./UserPlans";
import { TagSelection } from "./TagSelection";

type EditUserProps = {
  open: boolean;
  handleClose: () => void;
  employee: Employee;
};

export type ReplaceEmployeeFormValuesType = {
  customerEmployeeId: string;
  firstName: string;
  lastName: string;
  cardDisplayName: string;
  contact: {
    email: string;
    phone: string;
    address: {
      address1: string;
      address2: string;
      // address3: string;
      // address4: string;
      // address5: string;
      city: string;
      stateOrProvince: string;
      postalCode: string;
      country: string;
    };
  };
};

const DEFAULT_VALUE = "-1";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export const EditUser = ({ open, handleClose, employee }: EditUserProps) => {
  const { config } = useConfig();
  const {
    userInfoData,
    employerId,
    allEmployees,
    employees,
    employer,
    accounts
  } = useData();
  const { getToken } = useKindeAuth();

  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [canUpdateStatus, setCanUpdateStatus] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [employeeAccounts, setEmployeeAccounts] = useState<EmployeeAccount[]>([]);
  const [plansToRemove, setPlansToRemove] = useState<EmployeeAccount[]>([]);
  const [plansToAdd, setPlansToAdd] = useState<PlanReference[]>([]);
  const [tags, setTags] = useState<TagReference[]>(
    employee.tags.map((tag) => {
      return { tagId: tag.id, tagCategoryId: tag.tagCategoryId };
    })
  );
  const [hasRunEffect, setHasRunEffect] = useState(false);

  useEffect(() => {
    if (!hasRunEffect && !accounts.isLoading) {
      setEmployeeAccounts(accounts.data?.items.filter((x) => x.employeeId === employee.id) || []);
      setHasRunEffect(true); // Mark the effect as run
    }
  }, [accounts.data, employee, hasRunEffect]);

  const [status] = useState<EmployeeStatus>(employee.status);
  const [error, setError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const mutationUpdateUser = useMutation(
    async (formValues: ReplaceEmployeeFormValuesType) => {
      const countryCode: any = employer?.data?.country?.id;

      let formattedPhoneNumber = null;
      if (formValues.contact.phone?.length) {
        formattedPhoneNumber = parsePhoneNumberFromString(
          formValues.contact.phone,
          countryCode
        )?.number;
      }

      const updateEmployeeResponse = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/${employee.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            customerEmployeeId: formValues.customerEmployeeId,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            cardDisplayName: formValues.cardDisplayName,
            contact: {
              email: formValues.contact.email,
              phone: formattedPhoneNumber,
              address: {
                ...formValues.contact.address,
                city: formik.values.contact.address.city,
                // Only include state/province for outside of NZ
                stateOrProvince:
                  employer.data?.country.id !== "NZ"
                    ? formik.values.contact.address.stateOrProvince
                    : undefined,
                country: employer.data?.country.id ?? ""
              }
            },
            tags
          })
        }
      );

      if (!updateEmployeeResponse.ok) {
        const responseJson = await updateEmployeeResponse.json();

        switch (updateEmployeeResponse.status) {
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem updating this user");
            }
          default:
            throw new Error("There was a problem updating this user");
        }
      }

      if (plansToAdd.length > 0) {
        const addPlansResponse = await fetch(
          `${config?.API_URL}/employers/${employerId}/employees/${employee.id}/accounts`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(plansToAdd)
          }
        );

        if (!addPlansResponse.ok) {
          throw new Error("There was a problem updating this user");
        }
      }

      if (plansToRemove.length > 0) {
        const removePlansResponse = await fetch(
          `${config?.API_URL}/employers/${employerId}/employees/${employee.id}/accounts`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(plansToRemove.map(x => x.id))
          }
        );

        if (!removePlansResponse.ok) {
          throw new Error("There was a problem updating this user");
        }
      }
    },
    {
      onSuccess: () => {
        toast.success("User updated successfully");
        employees.refetch();
        accounts.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const mutationChangeUserStatus = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/${employee.id}/status`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            status:
              status === EmployeeStatus.Active
                ? EmployeeStatus.Inactive
                : EmployeeStatus.Active
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating status for this user");
    },
    onSuccess: () => {
      toast.success("User Status updated successfully");
      employees.refetch();
      accounts.refetch();
      handleClose();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const mutationDeleteUser = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/${employee.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok)
        throw new Error("There was a problem deleting this user");
    },
    onSuccess: () => {
      toast.success("User was deleted successfully");
      employees.refetch();
      accounts.refetch();
      handleClose();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const validationSchema = useDynamicEmployeeValidationSchema(
    employer.data?.country.id ?? "",
    allEmployees.data?.items,
    employee.id
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      customerEmployeeId: employee?.customerEmployeeId ?? "",
      firstName: employee.firstName,
      lastName: employee.lastName,
      cardDisplayName: employee?.cardDisplayName ?? "",
      contact: {
        email: employee.contact.email,
        phone: employee.contact.phone,
        address: {
          address1: employee.contact.address?.address1 ?? "",
          address2: employee.contact.address?.address2 ?? "",
          // address3: employee.data?.contact.address.address3 ?? "",
          // address4: employee.data?.contact.address.address4 ?? "",
          // address5: employee.data?.contact.address.address5 ?? "",
          city: employee.contact.address?.city ?? "",
          stateOrProvince: employee.contact.address?.stateOrProvince ?? "",
          postalCode: employee.contact.address?.postalCode ?? "",
          country: employer.data?.country.id ?? ""
        }
      }
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutationUpdateUser.mutate(values);
    },
    enableReinitialize: true
  });

  // Reset form when the modal is opened
  useEffect(() => {
    if (open) {
      resetForm();
      setError("");
    }
  }, [resetForm, setError, open]);

  function updateUserStatus(event: FormEvent) {
    event.preventDefault();
    mutationChangeUserStatus.mutate();
  }

  function deleteUser(event: FormEvent) {
    event.preventDefault();
    mutationDeleteUser.mutate();
    setIsDeleteDialogOpen(false);
  }

  useEffect(() => {
    setCanUpdateStatus(
      employee.status === EmployeeStatus.Active ||
      employee.status === EmployeeStatus.Inactive
    );
  }, [employee]);

  if (employees.isLoading || accounts.isLoading) {
    return <>Loading...</>;
  }

  function updateEmployeeAccounts(plansToRemove: EmployeeAccount[], newPlans: PlanReference[]) {
    setPlansToRemove(plansToRemove);
    setPlansToAdd(newPlans);
  }

  return (
    <>
      {/* Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => setIsConfirmCloseModalOpen(true)}
        maxWidth="md"
        fullWidth={true}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle>Edit user</DialogTitle>
          </Box>
          <DialogContent>
            <Grid container item spacing={2} xs={12}>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs value={tabIndex} onChange={handleChange}>
                  <Tab label="Employee Details" {...a11yProps(0)} />
                  <Tab label="Plans" {...a11yProps(1)} />
                  <Tab label="Tags" {...a11yProps(2)} />
                </Tabs>
              </Grid>
              <TabPanel value={tabIndex} index={0}>
                <Grid container item spacing={2} xs={12}>
                  <Grid container item spacing={2} xs={7}>
                    <PersonalDetailsForm
                      formik={formik}
                      allowEmailChange={true}
                    />
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={formik.values.cardDisplayName}
                        onChange={formik.handleChange}
                        label="Name to display on card (Max. 28 Characters)"
                        name="cardDisplayName"
                        fullWidth
                        size="small"
                        error={
                          formik.touched.cardDisplayName &&
                          Boolean(formik.errors.cardDisplayName)
                        }
                        helperText={
                          formik.touched.cardDisplayName &&
                          formik.errors.cardDisplayName
                        }
                      />
                    </Grid>
                    <Box
                      width="100%"
                      ml={2}
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <AddressFinderForm
                        formik={formik}
                        promptEmployerAddress={true}
                        required={
                          formik.values.contact.address.address1.length > 0
                        } // Don't mark address fields required if user is not filling in address. This is as an employer might want to edit an employee, before the employee has entered their address (and the employer might not want to fill it in). The validation of this is handled in the useDynamicEmployeeValidationSchema, this is just to mark the fields visually.
                      />
                    </Box>
                  </Grid>
                  <Grid container item spacing={2} xs={5}>
                    <Card // Prioritise finding a card by status not being removed, if all are removed, then select the latest
                      cardId={
                        employee.cards.find(
                          (c) => c.status !== CardStatus.Removed
                        )?.id ??
                        employee.cards[
                          employee.cards.length > 0
                            ? employee.cards.length - 1
                            : 0
                          ]?.id
                      }
                      employee={employee}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <Grid container item spacing={2} xs={10}>
                  <UserPlans
                    employeeAccounts={employeeAccounts}
                    onEmployeeAccountsChange={updateEmployeeAccounts}
                    DEFAULT_VALUE={DEFAULT_VALUE}
                    disabledPlans={[]}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <Grid container item spacing={2} xs={10}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Tags</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={formik.values.customerEmployeeId}
                      onChange={formik.handleChange}
                      label="Employee Id"
                      name="customerEmployeeId"
                      fullWidth
                      size="small"
                      error={
                        formik.touched.customerEmployeeId &&
                        Boolean(formik.initialValues.customerEmployeeId)
                      }
                      helperText={
                        formik.touched.customerEmployeeId &&
                        formik.errors.customerEmployeeId
                      }
                    />
                  </Grid>
                  <TagSelection tags={tags} setTags={setTags} />
                </Grid>
              </TabPanel>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container item spacing={2} xs={12} mx={1} mb={1}>
              <Box display="flex" flexDirection="column" gap={2}>
                {canUpdateStatus && (
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    direction="row"
                    justifyContent="space-between"
                  >
                    {/* <Grid item xs={6}> */}
                    <Button
                      fullWidth
                      variant="outlined"
                      color={
                        status === EmployeeStatus.Active ? "error" : "primary"
                      }
                      onClick={updateUserStatus}
                    >
                      {status === EmployeeStatus.Active ? "Disable" : "Enable"}{" "}
                      Access
                    </Button>
                    {/* </Grid> */}
                    {/* <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        This will{" "}
                        {status === EmployeeStatus.Active ? "remove" : "enable"}{" "}
                        access to the card and HealthNow for this user
                      </Typography>
                    </Grid> */}
                  </Grid>
                )}
                {userInfoData?.employeeId !== employee.id && ( // A user cannot remove himself
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    direction="row"
                    justifyContent="space-between"
                  >
                    {/* <Grid item xs={6}> */}
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    >
                      Delete User
                    </Button>
                    {/* </Grid> */}
                    {/* <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        This will permanently remove the card and user from your
                        organisation
                      </Typography>
                    </Grid> */}
                  </Grid>
                )}
              </Box>
            </Grid>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={mutationUpdateUser.isLoading || employer.isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      {isConfirmCloseModalOpen && (
        <Dialog open={isConfirmCloseModalOpen} maxWidth="xs" fullWidth={true}>
          <DialogContent>
            <Typography>Are you sure you want to exit?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsConfirmCloseModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsConfirmCloseModalOpen(false);
                handleClose();
              }}
              color="primary"
            >
              Exit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
