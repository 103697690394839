import { Autorenew, HistoryOutlined } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { ReactComponent as AllCategories } from "../../assets/all-categories.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { ReactComponent as RestrictedCategories } from "../../assets/restricted-categories.svg";
import { PlanType, RenewalStrategy, ServiceSector } from "../../models";
import { extraordinaryColors } from "../../utils/theme";
import { CreatePlanInput } from "../plans/AddPlan";
import { getOpenServiceSector } from "./ContributionAccountSteps/3_serviceSectors";
import { resolveRenewalPeriod } from "./TierInfoBox";

export const AccountSummary: FC<{
  variant: "full" | "preview";
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
}> = ({ variant, formik, serviceSectors }) => {
  const theme = useTheme();
  const openServiceSector = getOpenServiceSector(serviceSectors ?? []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        padding: 2,
        borderWidth: 2,
        bgcolor:
          variant === "full" ? "transparent" : extraordinaryColors.orange.main,
        borderColor:
          variant === "full"
            ? theme.palette.grey[200]
            : extraordinaryColors.orange.main,
        borderStyle: "solid",
        borderRadius: 3
      }}
    >
      {/* NAME */}
      <Typography fontSize={20} fontWeight={600}>
        {formik.values.name}
      </Typography>
      {variant === "full" && <Divider sx={{ borderWidth: 1 }} />}

      <Box display="flex" gap={2}>
        {/* RENEWAL */}
        {formik.values.planType &&
          formik.values.renewalOptions.periodInMonths && (
            <Box display="flex" gap={0.5} alignItems="center" mt={1}>
              {formik.values.planType === PlanType.ONGOING ? (
                <Autorenew sx={{ color: theme.palette.primary.main }} />
              ) : (
                <Calendar color={theme.palette.primary.main} />
              )}
              {formik.values.planType === PlanType.ONGOING ? (
                <Typography fontSize={14} color="primary">
                  Renews{" "}
                  {formik.values.renewalOptions.periodInMonths === 1 &&
                    "Monthly"}
                  {formik.values.renewalOptions.periodInMonths === 6 &&
                    "Bi-Annually"}
                  {formik.values.renewalOptions.periodInMonths === 12 &&
                    "Yearly"}
                </Typography>
              ) : (
                <>
                  <Typography fontSize={14} color={theme.palette.grey[600]}>
                    One-Off
                  </Typography>
                  <HistoryOutlined
                    sx={{ ml: 2, color: theme.palette.primary.main }}
                  />
                  <Typography fontSize={14} color={theme.palette.grey[600]}>
                    {formik.values.renewalOptions.periodInMonths === 1 &&
                      "Expires in 1 Month"}
                    {formik.values.renewalOptions.periodInMonths === 6 &&
                      "Expires in 6 Months"}
                    {formik.values.renewalOptions.periodInMonths === 12 &&
                      "Expires in 1 Year"}
                  </Typography>
                </>
              )}
            </Box>
          )}

        {formik.values.serviceSectorIds.length > 0 && (
          <Box display="flex" gap={0.5} alignItems="center" mt={1}>
            {formik.values.serviceSectorIds.find(
              (ssid) => ssid === openServiceSector?.id
            ) ? (
              <AllCategories />
            ) : (
              <RestrictedCategories />
            )}

            <Typography fontSize={14} color="primary">
              {formik.values.serviceSectorIds.find(
                (ssid) => ssid === openServiceSector?.id
              )
                ? "All Categories"
                : "Restricted"}
            </Typography>
          </Box>
        )}
      </Box>

      {/* TIERS */}
      {formik.values.tiers.length > 0 && (
        <>
          <Typography fontSize={12} fontWeight={600} mt={1} color={variant === "full" ? "primary": "white"}>
            TIERS
          </Typography>
          {formik.values.tiers.map((t, i) => {
            return (
              <Box
                key={i}
                display="flex"
                alignItems="flex-end"
                paddingX={1.5}
                paddingY={1}
                sx={{
                  borderWidth: variant === "full" ? 2 : 1,
                  borderColor:
                    variant === "full" ? theme.palette.grey[200] : "white",
                  borderStyle: "solid",
                  borderRadius: 1
                }}
              >
                <Typography fontWeight={600}>{t.name}</Typography>
                <Typography ml="auto" color="primary">
                  ${t.amount.amount}/
                  {resolveRenewalPeriod(
                    formik.values.renewalOptions.periodInMonths ?? 0
                  )}
                </Typography>
              </Box>
            );
          })}
        </>
      )}

      <Box
        display="flex"
        justifyContent={variant === "full" ? "normal" : "space-between"}
        gap={variant === "full" ? 10 : 0}
        mt={1}
      >
        <Box>
          <Typography fontSize={12} fontWeight={600} color="primary">
            ACCOUNT ACTIVATES
          </Typography>
          <Typography fontSize={14}>
            {formik.values.renewalOptions.startDate
              ? new Date(
                  formik.values.renewalOptions.startDate
                ).toLocaleDateString()
              : "Date Employee Added"}
          </Typography>
        </Box>
        {formik.values.planType === PlanType.ONGOING && (
          <Box>
            <Typography fontSize={12} fontWeight={600} color="primary">
              RESIDUAL BALANCE
            </Typography>
            <Typography fontSize={14}>
              {formik.values.renewalOptions.strategy === RenewalStrategy.RESET
                ? "Resets on Renewal"
                : "Rolls over"}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
