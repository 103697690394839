import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { useState } from "react";
import EmployeeSpendChart from "../charts/EmployeeSpendChart";
import MainCard from "../shared/MainCard";

export const EmployeeSpend = () => {
  const [slot, setSlot] = useState("week");
  const [quantity, setQuantity] = useState("By amount");

  /* @ts-ignore-next-line */
  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  };

  /* @ts-ignore-next-line */
  const handleChange = (event, newAlignment) => {
    if (newAlignment) setSlot(newAlignment);
  };

  return (
    // @ts-ignore-next-line
    <MainCard content={false}>
      <Grid item>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>
              Employee Spend
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-end" }}
              sx={{ mt: 3, mr: 2 }}
            >
              <ToggleButtonGroup
                exclusive
                onChange={handleChange}
                size="small"
                value={slot}
              >
                <ToggleButton
                  disabled={slot === "week"}
                  value="week"
                  sx={{ px: 2, py: 0.5 }}
                >
                  Week
                </ToggleButton>
                <ToggleButton
                  disabled={slot === "month"}
                  value="month"
                  sx={{ px: 2, py: 0.5 }}
                >
                  Month
                </ToggleButton>
              </ToggleButtonGroup>
              <Select value={quantity} onChange={handleQuantity} size="small">
                <MenuItem value="By amount">By Amount</MenuItem>
                <MenuItem value="By volume">By Volume</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ pt: 1 }}>
        <EmployeeSpendChart slot={slot} quantity={quantity} />
      </Box>
    </MainCard>
  );
};
