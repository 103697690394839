import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import { Invoice, InvoiceItemType } from "../../models";
import { formatCurrency } from "../../utils/formatters";

type InvoicePlatformFeesProps = {
  invoice: Invoice;
};

export const InvoicePlatformFees = ({ invoice }: InvoicePlatformFeesProps) => {
  const fullwidthColumnStyles = { width: "100%" };
  const columnStyles = { minWidth: 100 };

  const monthlyUsers = invoice.items.find(x => x.type === InvoiceItemType.MONTHLY_USER_FEE);
  const issuedCards = invoice.items.find(x => x.type === InvoiceItemType.ISSUED_CARD_FEE);
  const renewedCards = invoice.items.find(x => x.type === InvoiceItemType.RENEW_CARD_FEE);

  const displayRow = (item: string, total: number) => {
    return (
      <TableRow>
        <TableCell component="th" scope="row" style={fullwidthColumnStyles}>
          <Typography variant="body1">
            {item}
          </Typography>
        </TableCell>
        <TableCell align="right" style={columnStyles}>
          <Typography variant="body1">
            {formatCurrency(total, 2)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid item style={{ paddingTop: "20px" }}>
      <Grid container flexDirection="column">
        <Typography variant="h6" pb={2}>
          Platform Fees
        </Typography>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="platform fees table">
            <TableBody>
              {monthlyUsers &&
                displayRow(`${monthlyUsers.quantity} Active User(s) x ${formatCurrency(monthlyUsers.price, 2)}`, monthlyUsers.total)}
              {issuedCards &&
                displayRow(`${issuedCards.quantity} Issued Card(s) x ${formatCurrency(issuedCards.price, 2)}`, issuedCards.total)}
              {renewedCards &&
                displayRow(`${renewedCards.quantity} Renewed Card(s) x ${formatCurrency(renewedCards.price, 2)}`, renewedCards.total)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
