import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee } from "../../models";
import { CommunicationPlatform } from "../../models/communications/communicationPlatform";
import { CommunicationSubject } from "../../models/communications/communicationSubject";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { RichTextEditor } from "../messages/RichTextEditor";

type SendMessageProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const SendMessage = ({
  open,
  handleComplete,
  handleClose,
  selectedEmployees
}: SendMessageProps) => {
  const { config } = useConfig();
  const { employees, emailTemplates, employer } = useData();
  const { getToken } = useKindeAuth();

  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState(
    emailTemplates.data?.find((x) => x.type === "INVITATION")?.id ?? ""
  );
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const selectedEmailTemplate = emailTemplates.data?.find(
    (emailTemplate) => emailTemplate.id === selectedEmailTemplateId
  );

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${config?.API_URL}/communications`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          employerId: employer.data?.id,
          employeeIds: selectedEmployees.map((e) => e.id),
          subject: CommunicationSubject.GENERAL,
          platform: CommunicationPlatform.EMPLOYEE_EMAIL,
          emailDetails: {
            templateId: selectedEmailTemplate?.id,
            parameters: {
              employer: employer.data?.name,
              message
            }
          }
        })
      });

      if (!response.ok) {
        throw new Error("There was a problem sending the message");
      }
    },
    onSuccess: () => {
      employees.refetch();
      toast.success("Message sent successfully");
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    mutation.mutate();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Send message to {selectedEmployees.length} user
            {selectedEmployees.length > 1 ? "s" : ""}
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {selectedEmailTemplate?.description && (
              <Grid item xs={12}>
                <Typography>{selectedEmailTemplate?.description}</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel id="messageType">Message type</InputLabel>
              <Select
                value={selectedEmailTemplateId}
                onChange={(e) => setSelectedEmailTemplateId(e.target.value)}
                labelId="messageType"
                size="small"
                fullWidth
              >
                {emailTemplates.data?.map((emailTemplate) => (
                  <MenuItem key={emailTemplate.id} value={emailTemplate.id}>
                    {emailTemplate.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {selectedEmailTemplate?.parameters.includes("message") && (
              <Grid item xs={12}>
                <RichTextEditor value={message} updateValue={setMessage} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={mutation.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
