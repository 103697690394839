import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { TagReference } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { useDynamicEmployeeValidationSchema } from "../../utils/useDynamicValidationSchema";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { PlanSelection } from "./PlanSelection";
import { TagSelection } from "./TagSelection";

type AddUserProps = {
  open: boolean;
  handleClose: () => void;
};

export type CreateEmployeeFormValuesType = {
  customerEmployeeId: string;
  firstName: string;
  lastName: string;
  contact: {
    email: string;
    phone: string;
  };
};

const DEFAULT_VALUE = "-1";

export const AddUser = ({ open, handleClose }: AddUserProps) => {
  const { config } = useConfig();
  const { employerId, allEmployees, employees, accounts, employer, membership } =
    useData();
  const { getToken } = useKindeAuth();

  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [sendInvitationEmail, setSendInvitationEmail] = useState(true);
  const [tags, setTags] = useState<TagReference[]>([]);
  const [selectedPlans, setSelectedPlans] = useState<
    { planId: string; tierId: string }[]
  >([]);

  const [error, setError] = useState("");
  const initialValues: CreateEmployeeFormValuesType = {
    customerEmployeeId: "",
    firstName: "",
    lastName: "",
    contact: {
      email: "",
      phone: ""
    }
  };

  const addEmployeeMutation = useMutation(
    async (formValues: CreateEmployeeFormValuesType) => {
      if (
        employees?.data?.totalItems &&
        membership.data?.features?.maxEmployeeCount &&
        employees.data.totalItems >= membership.data.features.maxEmployeeCount
      ) {
        throw new Error("User limit reached.");
      }

      if (!selectedPlans.length)
        throw new Error("Must select at least 1 plan for user.");

      const countryCode: any = employer?.data?.country?.id;
      const formattedPhoneNumber = parsePhoneNumberFromString(
        formValues.contact.phone,
        countryCode
      )?.number;

      const token = await getToken();
      const createEmployeeResponse = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            customerEmployeeId: formValues.customerEmployeeId,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            sendInvitationEmail,
            contact: {
              email: formValues.contact.email,
              phone: formattedPhoneNumber,
              address: {
                country: employer.data?.country.id ?? ""
              }
            },
            plans: selectedPlans.filter(
              (plan) => plan.tierId !== DEFAULT_VALUE
            ),
            tags
          })
        }
      );

      const responseJson = await createEmployeeResponse.json();
      if (!createEmployeeResponse.ok) {

        switch (createEmployeeResponse.status) {
          case 409:
            throw new Error(
              "This email already exists or the account has been deleted. Please contact support"
            );
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem adding this user");
            }
          default:
            throw new Error("There was a problem adding this user");
        }
      }
    },
    {
      onSuccess: () => {
        toast.success("User added successfully");
        employees.refetch();
        accounts.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const validationSchema = useDynamicEmployeeValidationSchema(
    employer.data?.country.id ?? "",
    allEmployees.data?.items
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      addEmployeeMutation.mutate(values);
    },
    enableReinitialize: true
  });

  // Reset form when the modal is opened
  useEffect(() => {
    if (open) {
      resetForm();
      setSelectedPlans([]);
      setTags([]);
      setError("");
    }
  }, [open, resetForm]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setIsConfirmCloseModalOpen(true)}
        maxWidth="md"
        fullWidth={true}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add new user</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <Typography color="error">{error}</Typography>
                </Grid>
              )}
              <Grid container spacing={2} item xs={12}>
                <PersonalDetailsForm formik={formik} allowEmailChange={true} />
              </Grid>
              <Grid container item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      value={sendInvitationEmail}
                      onChange={(e) => setSendInvitationEmail(e.target.checked)}
                    />
                  }
                  label="Send invitation email"
                />
              </Grid>
              <Grid container spacing={1} item xs={12}>
                <PlanSelection
                  selectedPlans={selectedPlans}
                  setSelectedPlans={setSelectedPlans}
                  DEFAULT_VALUE={DEFAULT_VALUE}
                />
              </Grid>
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">Tags</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={formik.values.customerEmployeeId}
                    onChange={formik.handleChange}
                    label="Employee Id"
                    name="customerEmployeeId"
                    fullWidth
                    size="small"
                    error={
                      formik.touched.customerEmployeeId &&
                      Boolean(formik.initialValues.customerEmployeeId)
                    }
                    helperText={
                      formik.touched.customerEmployeeId &&
                      formik.errors.customerEmployeeId
                    }
                  />
                </Grid>
                <TagSelection tags={tags} setTags={setTags} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={addEmployeeMutation.isLoading || employer.isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      {isConfirmCloseModalOpen && (
        <Dialog open={isConfirmCloseModalOpen} maxWidth="xs" fullWidth={true}>
          <DialogContent>
            <Typography>Are you sure you want to exit?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsConfirmCloseModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsConfirmCloseModalOpen(false);
                handleClose();
              }}
              color="primary"
            >
              Exit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
