import { CheckCircle } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

export const AccountRadioBox: FC<{
  title: string;
  content: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: ReactNode;
  children?: ReactNode;
}> = ({ title, content, isSelected, onClick, icon, children }) => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      sx={{
        p: 1,
        py: 1.5,
        m: 0,
        border: "4px solid black",
        borderColor: isSelected
          ? theme.palette.primary.light
          : theme.palette.grey[200],
        borderRadius: "10px",
        mb: 2,
        color: "black",
        bgcolor: "transparent",
        cursor: "pointer",
        position: "relative"
      }}
    >
      <Box onClick={onClick}>
        {isSelected && (
          <Box position="absolute" top={10} right={10}>
            <CheckCircle
              sx={{
                fontSize: 30,
                color: theme.palette.primary.light
              }}
            />
          </Box>
        )}

        {icon && (
          <Box
            sx={{
              bgcolor: "grey.300",
              width: 32,
              height: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginX: 1,
              marginY: 0.5,
              marginBottom: 0.5,
              borderRadius: 1
            }}
          >
            {icon}
          </Box>
        )}

        <Typography fontSize={18} fontWeight={600} ml={1}>
          {title}
        </Typography>
        <Typography fontSize={14} ml={1} my={0.5}>
          {content}
        </Typography>
      </Box>

      <Box ml={1} my={1}>
        {children}
      </Box>
    </Box>
  );
};
