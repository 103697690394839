import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  RadioGroup,
  Typography,
  useTheme
} from "@mui/material";
import { FormikProps } from "formik";
import { FC, ReactNode } from "react";
import { ReactComponent as LargeBusiness } from "../../../assets/large-business-icon.svg";
import { ReactComponent as MediumBusiness } from "../../../assets/medium-business-icon.svg";
import { ReactComponent as SmallBusiness } from "../../../assets/small-business-icon.svg";
import { EmployerCompanySize } from "../../../models";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step3CompanySize: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  next: () => void;
}> = ({ formik, next }) => {
  const theme = useTheme();
  const companySizeOptions: {
    label: string;
    value: string;
    icon: ReactNode;
  }[] = [
    {
      label: "1-350 Employees",
      value: EmployerCompanySize.SMALL,
      icon: <SmallBusiness />
    },
    {
      label: "351-1000 Employees",
      value: EmployerCompanySize.MEDIUM,
      icon: <MediumBusiness />
    },
    {
      label: "1000+ Employees",
      value: EmployerCompanySize.LARGE,
      icon: <LargeBusiness />
    }
  ];

  return (
    <>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        How many people work at {formik.values.name}?
      </Typography>
      <Typography variant="subtitle1" my={1}>
        This helps us create a personalised experience
      </Typography>
      <Grid container item xs={12} gap={2} my={3}>
        <RadioGroup
          sx={{ width: "100%" }}
          name="companySize"
          value={formik.values.companySize}
        >
          {companySizeOptions.map((cso, i) => {
            const isSelected = cso.value === formik.values.companySize;
            return (
              <Grid
                key={i}
                container
                alignItems="center"
                direction="row"
                onClick={() => formik.setFieldValue("companySize", cso.value)}
                sx={{
                  p: 1,
                  m: 0,
                  border: "3px solid black",
                  borderColor: isSelected
                    ? theme.palette.primary.light
                    : theme.palette.grey[200],
                  borderRadius: "10px",
                  mb: 2,
                  width: "100%",
                  color: "black",
                  bgcolor: "transparent",
                  cursor: "pointer",
                  position: "relative"
                }}
              >
                <Box
                  sx={{
                    bgcolor: "grey.300",
                    width: 45,
                    height: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginX: 1,
                    marginY: 0.5,
                    marginBottom: 0.5,
                    borderRadius: 1
                  }}
                >
                  {cso.icon}
                </Box>
                <Typography fontSize={18} ml={1}>
                  {cso.label}
                </Typography>
                {isSelected && (
                  <Box position="absolute" top={10} right={10}>
                    <CheckCircle
                      sx={{
                        fontSize: 30,
                        color: theme.palette.primary.light
                      }}
                    />
                  </Box>
                )}
              </Grid>
            );
          })}
        </RadioGroup>
        <Button
          disabled={Boolean(formik.errors.companySize)}
          variant="contained"
          sx={{ width: "100%", py: 1, mt: 5 }}
          onClick={next}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};
