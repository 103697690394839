import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { ArrowLeft } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { InvoicePlatformFees } from "../../components/billing/InvoicePlatformFees";
import { InvoiceTransactions } from "../../components/billing/InvoiceTransactions";
import { PaymentDialog } from "../../components/billing/PaymentDialog";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { InvoiceStatus } from "../../models";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { useData } from "../../utils/useData";

export const InvoiceDetails = () => {
  const { invoiceId } = useParams();
  const { invoices, employer } = useData();
  const navigate = useNavigate();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);

  if (invoices.isLoading || invoices.isIdle || employer.isLoading) {
    return <LoadingSpinner />;
  }

  if (invoices.error || !invoices.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const invoice = invoices.data.items.find((x) => x.id === invoiceId);
  if (!invoice) {
    return <p>Something went wrong, please try again later</p>;
  }

  const isReadyToPay = invoice.status === InvoiceStatus.READY;

  const isPaid =
    invoice.status === InvoiceStatus.PAID ||
    invoice.status === InvoiceStatus.PAYMENT_PENDING;

  return (
    <>
      <PaymentDialog
        amount={invoice.amount}
        reference={invoice.number}
        title={`Invoice ${invoice.number} for ${invoice.period.title}`}
        open={paymentDialogOpen}
        invoiceId={invoice.id}
        countryId={employer.data?.country.id ?? ""}
        onCancel={() => setPaymentDialogOpen(false)}
        onSuccess={async () => {
          await invoices.refetch();
          setPaymentDialogOpen(false);
        }}
      />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        spacing={4}
        style={{ height: "98vh" }}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => navigate(`/billing`)}>
                <ArrowLeft />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                Invoice '{invoice.number}' for {invoice.period.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          pb={2}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto"
          }}
        >
          <InvoiceTransactions invoice={invoice} />
          <InvoicePlatformFees invoice={invoice} />
        </Grid>

        <Grid item>
          <Paper elevation={0} style={{ width: "100%", padding: "20px 0" }}>
            {" "}
            {/* Apply Paper component for consistent background */}
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Grid item>
                {isReadyToPay && !isPaid && (
                  <Button
                    variant="contained"
                    onClick={() => setPaymentDialogOpen(true)}
                    size="medium"
                  >
                    Pay Now
                  </Button>
                )}
                {isPaid && (
                  <Typography variant="h6" style={{ textAlign: "right" }}>
                    Paid on <b>{formatDate(invoice.paidOn)}</b>
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ textAlign: "right" }}>
                  <b>{formatCurrency(invoice.amount.amount, 2)}</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
