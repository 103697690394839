import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import { Fragment, useMemo } from "react";
import { Activity } from "react-feather";
import { EmployeeStatus } from "../../models/employees/employeeStatus";
import { useData } from "../../utils/useData";
import LinearWithLabelChart from "../charts/LinearWithLabelChart";
import MainCard from "../shared/MainCard";

export const EmployeeProgress = () => {
  const { employees, ledgers } = useData();

  const data = useMemo(() => {
    const numberOfEmployees = employees.data?.totalItems ?? 1;

    const activeEmployees = employees.data?.items.reduce(
      (sum, employee) =>
        sum + (employee.status === EmployeeStatus.Active ? 1 : 0),
      0
    );
    const issuedCard = employees.data?.items.reduce(
      (sum, employee) => sum + (employee.cards.length > 0 ? 1 : 0),
      0
    );
    const activatedCard = employees.data?.items.reduce(
      (sum, employee) =>
        sum + (employee.cards.find((card) => card.status === "ACTIVE") ? 1 : 0),
      0
    );
    const madeTransaction = new Set(
      ledgers.data?.items.map((ledger) => ledger.employeeId)
    ).size;

    return [
      {
        label: "Active employees",
        value: activeEmployees
          ? Math.round((100 * activeEmployees) / numberOfEmployees)
          : 0,
        color: "primary"
      },
      {
        label: "Issued card",
        value: issuedCard
          ? Math.round((100 * issuedCard) / numberOfEmployees)
          : 0,
        color: "primary"
      },
      {
        label: "Activated card",
        value: activatedCard
          ? Math.round((100 * activatedCard) / numberOfEmployees)
          : 0,
        color: "primary"
      },
      {
        label: "Made transaction",
        value: madeTransaction
          ? Math.round((100 * madeTransaction) / numberOfEmployees)
          : 0,
        color: "primary"
      }
    ];
  }, [employees.data, ledgers.data]);

  return (
    // @ts-ignore-next-line
    <MainCard sx={{ width: "100%", mt: 1 }}>
      <Typography variant="h6" mb={2}>
        Employee Progress
      </Typography>
      <Grid container spacing={1.25}>
        {data.map((stat) => (
          <Fragment key={stat.label}>
            <Grid item xs={6}>
              <Typography>{stat.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <LinearWithLabelChart value={stat.value} color={stat.color} />
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <List sx={{ pb: 0 }}>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar>
                <Avatar sx={{ background: "none" }}>
                  <Activity color="black" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Tips for improving engagement with your users"
                secondary="Send messages to users who have not signed up, learn more here"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </MainCard>
  );
};
