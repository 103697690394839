import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField,
  Typography, useTheme
} from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { Employee, Plan, PlanReference, Tier } from "../../models";
import { useData } from "../../utils/useData";
import { formatCurrency } from "../../utils/formatters";

type AssignToPlanProps = {
  open: boolean;
  handleClose: () => void;
  selectedEmployees: Employee[];
  onSelectPlan: (selectedPlan: PlanReference) => void;
  isLoading: boolean;
  apiError: string;
};

export const SelectPlan = ({
                             open,
                             handleClose,
                             selectedEmployees,
                             onSelectPlan,
                             isLoading,
                             apiError
                           }: AssignToPlanProps) => {
  const theme = useTheme();
  const { plans } = useData();
  const [error, setError] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [selectedTier, setSelectedTier] = useState<Tier | undefined>(undefined);
  const [employeesWithSelectedPlan, setEmployeesWithSelectedPlan] = useState<Employee[]>([]);
  const [acknowledgeDuplicatePlan, setAcknowledgeDuplicatePlan] = useState<boolean>(false);
  const DEFAULT_VALUE = "-1";

  async function handleSubmit(event: FormEvent) {
    if (!selectedPlan || !selectedTier) {
      setError("Need to select Plan and Tier");
      return;
    }
    if (employeesWithSelectedPlan.length > 0 && !acknowledgeDuplicatePlan) {
      setError("You must acknowledge the possibility of assigning the same plan again.");
      return;
    }
    setError("");
    event.preventDefault();
    onSelectPlan({
      planId: selectedPlan!.id,
      tierId: selectedTier!.id!
    });
  }

  useEffect(() => {
    if (selectedPlan) {
      const employeesWithSelectedPlan = selectedEmployees
        .filter(e => e.accounts.find(a => a.planId === selectedPlan.id));
      setEmployeesWithSelectedPlan(employeesWithSelectedPlan);
    } else {
      setEmployeesWithSelectedPlan([]);
    }
  }, [selectedEmployees, selectedPlan]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Assign {selectedEmployees.length} user{selectedEmployees.length > 1 ? "s" : ""} to plan(s)
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {apiError && (
              <Grid item xs={12}>
                <Typography color="error">{apiError}</Typography>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography>General instructions on assigning plans</Typography>
              <Typography color="gray">
                Only users without a plan can have their plans set here. If you
                want to add a new plan to a user with an active plan please edit
                that user directly.
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography my={1}>Plan to Assign</Typography>
                <Autocomplete
                  size="small"
                  options={plans.data?.items ?? []}
                  getOptionLabel={(option) => option.name}
                  value={selectedPlan}
                  onChange={(_, newValue) => {
                    setSelectedPlan(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Plan to Assign" />
                  )}
                />
              </Grid>
            </Grid>
            {selectedPlan &&
              <Grid item xs={12}>
                <InputLabel>Tier</InputLabel>
                <Select
                  key={selectedPlan.id}
                  labelId={selectedPlan.id}
                  value={selectedTier?.id ?? DEFAULT_VALUE}
                  onChange={(e: SelectChangeEvent) =>
                    setSelectedTier(selectedPlan!.tiers.find(x => x.id === e.target.value))
                  }
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                >
                  {[
                    ...selectedPlan.tiers.map((tier, index) => (
                      <MenuItem value={tier.id} key={index}>
                        {tier.name} {formatCurrency(tier.amount.amount)}
                      </MenuItem>
                    )),
                    <MenuItem value={DEFAULT_VALUE} defaultChecked key={9999}>
                      Not selected ($0)
                    </MenuItem>
                  ]}
                </Select>
              </Grid>
            }

            {employeesWithSelectedPlan.length > 0 &&
              <Grid item xs={12}>
                <Typography color={theme.palette.error.main} sx={{ mb: 2 }}>
                  {employeesWithSelectedPlan.length} user{employeesWithSelectedPlan.length > 1 ? "s" : ""} out of {selectedEmployees.length} already have this plan assigned.
                </Typography>
                <Typography color={theme.palette.error.main} sx={{ mb: 2 }}>
                  Assigning this plan again will add another instance of the plan for these users.
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acknowledgeDuplicatePlan}
                        onChange={(e) => setAcknowledgeDuplicatePlan(e.target.checked)}
                      />
                    }
                    label="I acknowledge that this plan will be assigned again, and users who already have it will receive an additional instance."
                  />
                </Box>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={!selectedPlan || !selectedTier || (employeesWithSelectedPlan.length > 0 && !acknowledgeDuplicatePlan)}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
