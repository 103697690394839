import { Typography } from "@mui/material";
import { FC } from "react";
import { Report } from "../../models/reporting/report";
import { useData } from "../../utils/useData";
import useSeriesData from "../../utils/useSeriesData";

export const ReportStatValue: FC<{
  report: Report;
  filteredDocuments: any;
}> = ({ report, filteredDocuments }) => {
  const { employer } = useData();
  const { volume } = useSeriesData(
    filteredDocuments,
    report,
    employer.data?.createdAt ?? new Date()
  );
  const totalVolume = volume.reduce((acc, val) => acc + val, 0);

  return (
    <Typography variant="h6" textAlign="center" width="100%" mt={2}>
      {totalVolume}
    </Typography>
  );
};
