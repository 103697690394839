import { useTheme } from "@mui/material";
import { createExtraordinaryTheme, extraordinaryColors } from "./theme";

export const useGenerateColorGradient = (steps: number): string[] => {
  const theme = useTheme();
  const extraordinaryTheme = createExtraordinaryTheme();

  // No programmatically generated color gradient for extraordinary brand - easiest way to compare theme
  if (theme.palette.primary.main === extraordinaryTheme.palette.primary.main) {
    return [
      extraordinaryColors.green.main,
      extraordinaryColors.purple.main,
      extraordinaryColors.red.main,
      extraordinaryColors.blue.main,
      extraordinaryColors.orange.main,
      extraordinaryColors.yellow.main,
      extraordinaryColors.green.light
    ];
  }

  const startColor = "#0A5857";
  const middleColor = "#15ACA4";
  const endColor = "#E5F3F1";

  const startRGB = hexToRgb(startColor);
  const middleRGB = hexToRgb(middleColor);
  const endRGB = hexToRgb(endColor);

  const colorGradient: string[] = [];

  for (let i = 0; i < steps; i++) {
    let ratio: number;
    if (i < steps / 2) {
      ratio = i / (steps / 2 - 1);
    } else {
      ratio = (i - steps / 2) / (steps / 2);
    }

    const newColor =
      i < steps / 2
        ? interpolateColor(startRGB, middleRGB, ratio)
        : interpolateColor(middleRGB, endRGB, ratio);

    colorGradient.push(rgbToHex(newColor));
  }

  return colorGradient;
};

const hexToRgb = (hex: string): number[] => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

const interpolateColor = (
  color1: number[],
  color2: number[],
  ratio: number
): number[] => {
  return color1.map((channel, index) =>
    Math.round(channel + ratio * (color2[index] - channel))
  );
};

const rgbToHex = (rgb: number[]): string => {
  return (
    "#" +
    rgb
      .map((channel) => {
        const hex = channel.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};
