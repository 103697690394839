import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Plus, Settings } from "react-feather";
import { HnChip } from "../../components/HnChip";
import { CreateTagCategory } from "../../components/modals/CreateTagCategory";
import { EditTagCategory } from "../../components/modals/EditTagCategory";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { TagCategory } from "../../models/tagCategory";
import { useData } from "../../utils/useData";

export const EmployeeTags = () => {
  const { tagCategories, employees } = useData();

  const [isCreatingTagCategory, setIsCreatingTagCategory] = useState(false);
  const [editingTagCategory, setEditingTagCategory] = useState<
    TagCategory | undefined
  >(undefined);

  useEffect(() => {
    if (tagCategories.isLoading) return setEditingTagCategory(undefined);
  }, [tagCategories.isLoading]);

  if (
    tagCategories.isLoading ||
    tagCategories.isIdle ||
    employees.isLoading ||
    employees.isIdle
  ) {
    return <LoadingSpinner />;
  }

  if (
    tagCategories.error ||
    !tagCategories.data ||
    employees.error ||
    !employees.data
  ) {
    return <p>Something went wrong, please try again later</p>;
  }

  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border: "1px solid",
          borderRadius: 1,
          borderColor: "divider",
          boxShadow: "inherit",
          marginBottom: "10px"
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="end"
          sx={{
            padding: 2,
            overflowX: "auto"
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsCreatingTagCategory(true)}
            endIcon={<Plus size={16} />}
            size="medium"
          >
            Create New Tag Category
          </Button>
        </Stack>
      </Card>
      <Grid container spacing={2}>
        {tagCategories.data.items.map((tagCategory) => {
          return (
            <Grid item xs={12} md={6} key={tagCategory.id} gap={2}>
              <Card>
                <CardContent>
                  <Box display="flex">
                    <Box>
                      <Typography variant="h5">{tagCategory.name}</Typography>
                    </Box>
                    <Box ml="auto">
                      <IconButton
                        onClick={() => setEditingTagCategory(tagCategory)}
                      >
                        <Settings />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="flex" flexWrap="wrap" gap={2} mt={3}>
                    {tagCategory.tags.map((tag, i) => {
                      return (
                        <HnChip key={i} variant="outlined">
                          {tag.name}
                        </HnChip>
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {editingTagCategory && (
          <EditTagCategory
            open={true}
            handleClose={() => setEditingTagCategory(undefined)}
            tagCategory={editingTagCategory}
          />
        )}
        {isCreatingTagCategory && (
          <CreateTagCategory
            open={true}
            handleClose={() => setIsCreatingTagCategory(false)}
          />
        )}
      </Grid>
    </Box>
  );
};
