import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Step0Welcome } from "../../components/onboarding/0_welcome";
import { Step3InviteEmployees } from "../../components/onboarding/3_inviteEmployees";
import { Step4SetupFloat } from "../../components/onboarding/4_setupFloat";
import { Step5ReviewAndComplete } from "../../components/onboarding/5_reviewAndComplete";
import { ContributionAccountSteps } from "../../components/onboarding/ContributionAccountSteps";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { OnboardingLayout } from "../../layouts/OnboardingLayout";
import { InvitedEmployee, Plan, PlanReference } from "../../models";
import { OnboardingProgress } from "../../models/onboardingProgress";
import { fetchData } from "../../utils/fetchData";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

export const calculateMinimumFloatAmount = (
  employees: InvitedEmployee[],
  adminEmployeePlanReference: PlanReference | undefined,
  plans: Plan[]
) => {
  let totalAmount = 0;
  employees.forEach((employee) => {
    employee.plans.forEach((planRef) => {
      const plan = plans.find((p) => p.id === planRef.planId);
      const tier = plan?.tiers.find((t) => t.id === planRef.tierId);
      if (tier && plan) {
        totalAmount += tier.amount.amount;
      }
    });
  });

  if (adminEmployeePlanReference) {
    const adminEmployeePlan = plans.find(
      (p) => p.id === adminEmployeePlanReference.planId
    );
    const adminEmployeeTier = adminEmployeePlan?.tiers.find(
      (t) => t.id === adminEmployeePlanReference.tierId
    );
    if (adminEmployeePlan && adminEmployeeTier) {
      totalAmount += adminEmployeeTier.amount.amount;
    }
  }
  return totalAmount;
};

export const Onboarding = () => {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer, employees, userInfoData, plans, invoices } = useData();
  const employee = employees.data?.items.find(
    (e) => e.id === userInfoData?.employeeId
  );

  // We have steps (Welcome, Create Contribution Accounts, Add Team, Payment), but we then have sub-steps within these
  const [step, setStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [invitedEmployees, setInvitedEmployees] = useState<InvitedEmployee[]>(
    []
  );

  // Used to allow the employer to assign themselves a plan (instead of inviting employees) if they choose
  const [adminEmployeePlanReference, setAdminEmployeePlanReference] = useState<
    PlanReference | undefined
  >();

  const [existingPlan, setExistingPlan] = useState<Plan | null>(
    plans.data?.items[0] ?? null
  );

  const getOnboardingProgress = useQuery<OnboardingProgress>(
    `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        getToken
      ),
    {
      enabled: !!employer.data?.id
    }
  );

  const updateOnboardingProgress = useMutation(
    async ({
      step,
      subStep,
      isCompleted,
      invitedEmployees,
      adminEmployeePlanReference
    }: any) => {
      await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            step,
            subStep,
            isCompleted,
            invitedEmployees,
            adminEmployeePlanReference
          })
        }
      );
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const assignPlan = useMutation(
    async ({ employee, adminEmployeePlanReference }: any) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/employees/${employee.id}/accounts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify([adminEmployeePlanReference])
        }
      );

      if (!response.ok) {
        throw new Error(
          "There was a problem assigning the admin employee plan"
        );
      }
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  useEffect(() => {
    setExistingPlan(plans.data?.items[0] ?? null);
  }, [plans]);

  useEffect(() => {
    if (
      !getOnboardingProgress.isLoading &&
      getOnboardingProgress.data &&
      step === 0 &&
      subStep === 0
    ) {
      setStep(getOnboardingProgress.data.step);
      setSubStep(getOnboardingProgress.data.subStep);
      setInvitedEmployees(getOnboardingProgress.data?.invitedEmployees ?? []);
      setAdminEmployeePlanReference(
        getOnboardingProgress.data?.adminEmployeePlanReference
      );
      // @ts-ignore
      if (getOnboardingProgress.data.isCompleted && step !== 4) {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnboardingProgress.data]);

  // Update the onboarding progress on step update
  useEffect(() => {
    if (step !== 0 && subStep !== 0)
      // Stop the step being reset to 0
      updateOnboardingProgress.mutate({
        step,
        subStep,
        invitedEmployees,
        adminEmployeePlanReference
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, subStep]);

  if (
    employer.isLoading ||
    employees.isLoading ||
    plans.isLoading ||
    getOnboardingProgress.isLoading ||
    invoices.isLoading
  ) {
    return <LoadingSpinner />;
  }

  const resolveStep = () => {
    switch (step) {
      case 0: {
        return (
          <Step0Welcome employer={employer.data} next={() => setStep(1)} />
        );
      }
      case 1: {
        return (
          <ContributionAccountSteps
            step={subStep}
            setStep={setSubStep}
            existingPlan={existingPlan}
            refetchPlan={plans.refetch}
            setMasterStep={setStep}
          />
        );
      }
      case 2: {
        return (
          <Step3InviteEmployees
            invitedEmployees={invitedEmployees}
            onEmployeesChange={(employees) => {
              setInvitedEmployees(employees ?? []);
            }}
            adminEmployeePlanReference={adminEmployeePlanReference}
            updateAdminEmployeePlanReference={setAdminEmployeePlanReference}
            plans={plans.data!.items}
            next={() => setStep(3)}
            back={() => {
              setStep(1);
              setSubStep(6);
            }}
          />
        );
      }
      case 3: {
        return (
          <Step4SetupFloat
            employer={employer.data}
            next={async () => {
              // Assign admin employee to plan
              if (adminEmployeePlanReference) {
                let employee = employees.data?.items.find(
                  (e) => e.id === userInfoData?.employeeId
                );
                await assignPlan.mutate({
                  employee,
                  adminEmployeePlanReference
                });
                employees.refetch();
              }

              updateOnboardingProgress.mutate({
                step: 4,
                subStep,
                invitedEmployees,
                adminEmployeePlanReference,
                isCompleted: true
              });
              setStep(4);
            }}
            back={() => setStep(2)}
            invoices={invoices.data!.items}
            minimumFloat={
              calculateMinimumFloatAmount(
                invitedEmployees,
                adminEmployeePlanReference,
                plans.data!.items
              ) * 0.1
            }
            adminEmployeePlanReference={adminEmployeePlanReference}
            invitedEmployees={invitedEmployees}
            plans={plans.data!.items}
          />
        );
      }
      case 4: {
        return (
          <Step5ReviewAndComplete
            loading={updateOnboardingProgress.isLoading}
            onFinish={async () => {
              await getOnboardingProgress.refetch();
              navigate("/");
            }}
          />
        );
      }
    }
  };

  if (employer.isLoading || getOnboardingProgress.isLoading)
    return <LoadingSpinner />;

  if (!employer || !employee) {
    navigate("/");
    return null;
  }

  return (
    <OnboardingLayout
      employer={employer.data}
      employee={employee}
      step={step}
      subStep={subStep}
      totalSteps={4}
    >
      {resolveStep()}
    </OnboardingLayout>
  );
};
