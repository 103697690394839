import { Box, Container } from "@mui/material";
import { FC, ReactNode } from "react";
import { DRAWER_WIDTH, Sidebar } from "../components/shared/Sidebar";

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Sidebar />
      <Box
        component={"main"}
        sx={{
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          overflowX: "auto",
          marginLeft: `${DRAWER_WIDTH - 24}px`
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column"
          }}
          maxWidth="lg"
        >
          {children}
        </Container>
      </Box>{" "}
    </>
  );
};
