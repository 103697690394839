import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Alert,
  Link,
  Paper
} from "@mui/material";
import { useState } from "react";
import CSVReader from "react-csv-reader";

type UploadCsvProps = {
  open: boolean;
  handleClose: () => void;
  onUploaded: (employees: any[]) => void;
};

export const UploadCsv = ({ open, handleClose, onUploaded }: UploadCsvProps) => {
  const [csvRows, setCsvRows] = useState<any[]>([]);
  const [csvErrors, setCsvErrors] = useState<string[]>([]);

  const loadedColumns: any[] = [];
  const parseOptions = {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    transformHeader: (header: string) => {
      const key = header.toLowerCase(); //.replace(/\W/g, "_");
      loadedColumns.push({ width: 300, label: header, dataKey: header });
      return key;
    }
  };

  const ValidateCsvFormat = (rows: any[]) => {
    const errors: string[] = [];
    const checkFieldExists = (field: string, headerKeys: string[], errors: string[]) => {
      if (!headerKeys.includes(field)) {
        errors.push(`File is missing '${field}' column.`);
      }
    };
    if (rows.length < 1) {
      errors.push("File is empty");
    } else {
      let headerKeys = loadedColumns.map((x) => x.dataKey.toLowerCase());

      checkFieldExists("id", headerKeys, errors);
      checkFieldExists("firstname", headerKeys, errors);
      checkFieldExists("middlename", headerKeys, errors);
      checkFieldExists("lastname", headerKeys, errors);
      checkFieldExists("phone", headerKeys, errors);
      checkFieldExists("email", headerKeys, errors);
    }

    setCsvErrors(errors);
  };

  const isValid = () => {
    return csvErrors.length === 0 && csvRows.length > 0;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (isValid()) {
      onUploaded(csvRows);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Import employees from CSV file</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ borderStyle: 'dashed', p: 4, borderWidth: 'thin', textAlign: 'center' }}>
                <Typography variant="h5" sx={{ mt: 2 }}>Prepare your CSV file</Typography>
                <Typography sx={{ mt: 2 }}>Use the guide below to prepare your file for import.</Typography>
                <Link href="https://help.healthnow.co.nz/en/articles/9402520-healthnow-user-import-csv-guide" target="_blank" variant="body2" sx={{ mt: 2, display: 'block' }}>View your guide &gt;</Link>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ borderStyle: 'dashed', p: 4, borderWidth: 'thin', textAlign: 'center' }}>
                <Typography variant="h5" sx={{ mt: 2 }}>Download a test CSV file</Typography>
                <Typography sx={{ mt: 2 }}>Familiarize yourself with the import process, using our sample file.</Typography>
                <Link href="/assets/employees_sample.csv" variant="body2" sx={{ mt: 2, display: 'block' }}>Download sample file &gt;</Link>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography>Ready to go?</Typography>
              <CSVReader
                onFileLoaded={(data: any[]) => {
                  ValidateCsvFormat(data);
                  setCsvRows(data);
                }}
                onError={(error) => setCsvErrors([error.message])}
                parserOptions={parseOptions}
                inputStyle={{
                  dropArea: {
                    borderColor: 'lightgray',
                    borderStyle: 'dashed',
                    borderRadius: 4,
                    padding: 16,
                    textAlign: 'center'
                  },
                  dropAreaActive: {
                    borderColor: 'blue'
                  },
                  dropFile: {
                    width: 100,
                    height: 100,
                    background: '#ccc'
                  },
                  fileSizeInfo: {
                    color: '#333',
                    backgroundColor: '#ccc',
                    borderRadius: 3,
                    lineHeight: 1,
                    marginBottom: '0.5em',
                    padding: '0 0.4em'
                  },
                  fileNameInfo: {
                    color: '#659cef',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    fontSize: 14,
                    lineHeight: 1,
                    padding: '0 0.4em'
                  },
                  removeButton: {
                    color: 'white',
                    borderRadius: 3,
                    padding: '0.2em 0.4em',
                    fontSize: 14,
                    backgroundColor: '#659cef'
                  },
                  progressBar: {
                    backgroundColor: '#659cef'
                  }
                }}
              />
              {csvErrors.map((e, i) => (
                <Alert severity="error" key={`csvAlert${i}`}>
                  {e}
                </Alert>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={!isValid()}>
            Upload
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
