import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Typography } from "@mui/material";

export const NotAuthorized = () => {
  const { logout } = useKindeAuth();

  return (
    <Box
      display="flex"
      justifyContent="center"
      p={5}
      flexDirection="column"
      textAlign="center"
      gap={2}
    >
      <Typography>
        You don't have the necessary permissions to access this portal. Please
        contact your administrator or HealthNow.
      </Typography>
      <Box>
        <Button onClick={logout} variant="contained">
          Sign out
        </Button>
      </Box>
    </Box>
  );
};
