import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Typography
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { Employee } from "../../models";
import { EmployeeStatus } from "../../models/employees/employeeStatus";

type IssueCardsProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const IssueCards = ({
                             open,
                             handleComplete,
                             handleClose,
                             selectedEmployees
                           }: IssueCardsProps) => {
  const { config } = useConfig();
  const { employees, employer } = useData();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");
  const [employeesWithoutCard, setEmployeesWithoutCard] = useState<Employee[]>([]);

  useEffect(() => {
    const filteredEmployees = selectedEmployees
      .filter(x => (x.cards == null || x.cards.length === 0) && x.status === EmployeeStatus.Active);
    setEmployeesWithoutCard(filteredEmployees);
    if (filteredEmployees.length === 0) {
      setError("Cards can only be issued to active users who do not already have one.");
    }
  }, [open, selectedEmployees]);

  const mutation = useMutation({
    mutationFn: async (employeeId: string) => {
      const response = await fetch(`${config?.API_URL}/employers/${employer?.data?.id}/cards?employeeId=${employeeId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        throw new Error("There was a problem issuing the cards");
      }
    },
    onSuccess: () => {
      employees.refetch();
      toast.success("Cards has been issued successfully");
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const batchSize = 10;

    for (let i = 0; i < employeesWithoutCard.length; i += batchSize) {
      // Get the current batch of employees
      const currentBatch = employeesWithoutCard.slice(i, i + batchSize);
      // Create an array of promises for the mutation of each employee in the current batch
      const promises = currentBatch.map(employee => mutation.mutateAsync(employee.id));
      // Wait for all mutations in the current batch to complete before proceeding to the next batch
      await Promise.all(promises).catch(e => {
        setError('There was a problem issuing the cards');
      });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Issue card for {employeesWithoutCard.length} user
            {employeesWithoutCard.length > 1 ? "s" : ""}
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {!error &&
              <Grid item xs={12}>
                <InputLabel id="messageType">A card will be issued
                  for {employeesWithoutCard.length} user {employeesWithoutCard.length > 1 ? "s" : ""}. Are you sure you
                  want to proceed?</InputLabel>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={mutation.isLoading}
            disabled={employeesWithoutCard.length === 0}
          >
            Issue Cards for {employeesWithoutCard.length} Employee(s)
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
