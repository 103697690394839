import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { ServiceSector } from "../../../models";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { AccountSummary } from "../AccountSummary";

export const Step6Review: FC<{
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
  loading: boolean;
  back: () => void;
}> = ({ formik, serviceSectors, loading, back }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Great Account!
      </Typography>
      <Typography variant="subtitle1" my={1} mb={3}>
        Confirm everything below looks as planned
      </Typography>
      <AccountSummary
        variant="full"
        formik={formik}
        serviceSectors={serviceSectors}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <BackButton onClick={back} outlined />
        <LoadingButton loading={loading} variant="contained" type="submit">
          Continue
        </LoadingButton>
      </Box>
    </Grid>
  );
};
