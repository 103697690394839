import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";
import { Country } from "react-addressfinder";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useMutation, useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import ExtraordinaryConfetti from "../../assets/extraordinary-confetti.svg";
import ExtraordinaryHandCard from "../../assets/extraordinary-hand-card.png";
import ExtraordinaryRainbow from "../../assets/extraordinary-rainbow.svg";
import ExtraordinaryShapes from "../../assets/extraordinary-shapes.svg";
import { BackButton } from "../../components/shared/BackButton";
import { EmployerFormValuesType } from "../../components/shared/EmployerForm";
import { Step0Email } from "../../components/signup/steps/0_email";
import { Step1Name } from "../../components/signup/steps/1_name";
import { Step2CompanyName } from "../../components/signup/steps/2_companyName";
import { Step3CompanySize } from "../../components/signup/steps/3_companySize";
import { Step4CompanyAddress } from "../../components/signup/steps/4_companyAddress";
import { SignupLayout } from "../../layouts/SignupLayout";
import { EmployerCompanySize, EmployerSource } from "../../models";
import { EmployerBrand } from "../../models/employers/employerBrand";
import { useConfig } from "../../utils/useConfig";
import { useDynamicEmployerValidationSchema } from "../../utils/useDynamicValidationSchema";

type SignupSession = {
  id: string;
  step: number;
  formValues: any;
  isCompleted: boolean;
};

export const Signup = () => {
  const { login } = useKindeAuth();
  const { config } = useConfig();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [step, setStep] = useState(0);
  const [country, setCountry] = useState<string>("NZ");
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [error, setError] = useState<string | null>();

  const [signupImage, setSignupImage] = useState(ExtraordinaryHandCard);
  const validationSchema = useDynamicEmployerValidationSchema(country);

  // Tracks whether we have created a signup session. This is due to the async nature of creating the session + saving it to the cookies
  // its possible for the useEffect to create two sign up sessions for the user. This way we keep track locally if we have already requested to create the signup session,
  // so we don't end up with duplicate sessions.
  // Can't use useState for this as it takes too long to update compared to just variable assignment.
  let createdSignupSession = false;

  const handleEmailSubmit = async () => {
    const isEmailUnique = await isEmailUniqueQuery.refetch();
    if (isEmailUnique.data === true) {
      setError(null);
      setStep(1);
    } else {
      setError("Email must not already be in use.");
    }
  };

  const getCookiesSignupSession = () => {
    const existingSessionCookie = Cookies.get("signupSession");
    if (existingSessionCookie !== undefined)
      return JSON.parse(existingSessionCookie);
  };

  const createSignupSession = async () => {
    let signupSession: SignupSession = {
      id: uuidv4(),
      step: 0,
      formValues: formik.values,
      isCompleted: false
    };
    await createSignupSessionMutation.mutateAsync(signupSession);
    Cookies.set("signupSession", JSON.stringify(signupSession));
  };

  const updateSignupSession = async (signupSession: SignupSession) => {
    await updateSignupSessionMutation.mutateAsync(signupSession);
    Cookies.set("signupSession", JSON.stringify(signupSession));
  };

  const createSignupSessionMutation = useMutation(
    async (signupSession: SignupSession) => {
      const recaptchaToken = await handleReCaptchaVerify();
      if (!recaptchaToken) throw new Error("ReCaptcha Error");

      await fetch(`${config?.API_URL}/signup-sessions`, {
        method: "POST",
        headers: {
          "recaptcha-token": recaptchaToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(signupSession)
      });
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const updateSignupSessionMutation = useMutation(
    async (signupSession: SignupSession) => {
      if (!signupSession.id)
        throw new Error("Signup Session Id must be provided");

      const recaptchaToken = await handleReCaptchaVerify();
      if (!recaptchaToken) throw new Error("ReCaptcha Error");

      await fetch(`${config?.API_URL}/signup-sessions/${signupSession.id}`, {
        method: "PUT",
        headers: {
          "recaptcha-token": recaptchaToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(signupSession)
      });
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const initialSignupSession = getCookiesSignupSession();
  const initialValues = initialSignupSession
    ? initialSignupSession.formValues
    : {
        contactFirstName: "",
        contactLastName: "",
        name: "",
        companySize: EmployerCompanySize.SMALL.toString(),
        contact: {
          phone: "",
          email: "",
          address: {
            address1: "",
            address2: "",
            city: "",
            stateOrProvince: "",
            postalCode: "",
            country: country
          }
        },
        billingContact: {
          email: ""
        }
      };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (formValues) => {
      createEmployerMutation.mutate({ formValues });
    }
  });

  const isEmailUniqueQuery = useQuery<boolean>(
    `${config?.API_URL}/admin/email-unique?email=${formik.values.contact.email}`,
    async () => {
      const recaptchaToken = await handleReCaptchaVerify();
      if (!recaptchaToken) throw new Error("ReCaptcha Error");

      const response = await fetch(
        `${config?.API_URL}/admin/email-unique?email=${formik.values.contact.email}`,
        {
          method: "GET",
          headers: {
            "recaptcha-token": recaptchaToken,
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      enabled: false
    }
  );

  const createEmployerMutation = useMutation(
    async ({ formValues }: { formValues: EmployerFormValuesType }) => {
      const recaptchaToken = await handleReCaptchaVerify();
      if (!recaptchaToken) throw new Error("ReCaptcha Error");

      const formattedPhoneNumber = parsePhoneNumberFromString(
        formValues.contact.phone,
        formValues.contact.address.country === "NZ" ? Country.NZ : Country.AU
      )?.number;

      const response = await fetch(`${config?.API_URL}/employers`, {
        method: "POST",
        headers: {
          "recaptcha-token": recaptchaToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...formValues,
          billingContact: {
            email: formValues.contact.email
          },
          contact: {
            ...formValues.contact,
            phone: formattedPhoneNumber,
            address: {
              ...formValues.contact.address,
              city: formValues.contact.address.country,
              // Only include state/province for outside of NZ
              stateOrProvince:
                formValues.contact.address.country !== "NZ"
                  ? formValues.contact.address.stateOrProvince
                  : undefined
            }
          },
          source: EmployerSource.SELF_SERVICE,
          brand: EmployerBrand.EXTRAORDINARY
        })
      });

      if (!response.ok) {
        let responseJson;
        try {
          responseJson = await response.json();
        } catch (err) {
          throw new Error("There was a problem signing up.");
        }

        switch (response.status) {
          case 409:
            throw new Error("Email already in use. Please contact support");
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem signing up.");
            }
          default:
            throw new Error("There was a problem signing up.");
        }
      }
    },
    {
      onSuccess: () => {
        handleSignupSessionUpdate(true);
        setIsSignupComplete(true);
        setTimeout(() => {
          login({
            authUrlParams: {
              login_hint: formik.values.contact.email
            }
          });
        }, 3000);
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    return await executeRecaptcha("onboarding");
  }, [executeRecaptcha]);

  useEffect(() => {
    formik.setFieldValue("contact.address.address1", "");
    formik.setFieldValue("contact.address.address2", "");
    formik.setFieldValue("contact.address.city", "");
    formik.setFieldValue("contact.address.postalCode", "");
    formik.setFieldValue("contact.address.country", country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  // Set the step to the stored sessions step
  useEffect(() => {
    setStep(initialSignupSession?.step ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolveSignupImage = () => {
    switch (step) {
      case 0: {
        return ExtraordinaryHandCard;
      }
      case 1: {
        return ExtraordinaryConfetti;
      }
      case 2: {
        return ExtraordinaryRainbow;
      }
      case 3: {
        return ExtraordinaryShapes;
      }
      default:
        return ExtraordinaryShapes;
    }
  };

  const handleSignupSessionUpdate = (isCompleted?: boolean) => {
    let signupSession = getCookiesSignupSession();
    if (!signupSession && !createdSignupSession) {
      createdSignupSession = true;
      createSignupSession();
    } else {
      updateSignupSession({
        ...signupSession,
        isCompleted:
          isCompleted !== null ? isCompleted : signupSession.isCompleted,
        step,
        formValues: formik.values
      });
    }
  };

  // When step updates, update the stored session formik values
  useEffect(() => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    handleSignupSessionUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, executeRecaptcha]);

  useEffect(() => {
    setSignupImage(resolveSignupImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const resolveStep = () => {
    switch (step) {
      case 0:
        return (
          <Step0Email
            formik={formik}
            loading={isEmailUniqueQuery.isLoading}
            next={() => handleEmailSubmit()}
          />
        );
      case 1:
        return <Step1Name formik={formik} next={() => setStep(2)} />;
      case 2:
        return <Step2CompanyName formik={formik} next={() => setStep(3)} />;
      case 3:
        return <Step3CompanySize formik={formik} next={() => setStep(4)} />;
      case 4:
        return (
          <Step4CompanyAddress
            formik={formik}
            loading={createEmployerMutation.isLoading}
            updateCountry={(e) => setCountry(e)}
          />
        );
    }
  };

  return (
    <SignupLayout imageSrc={signupImage}>
      {!isSignupComplete ? (
        <form onSubmit={formik.handleSubmit}>
          {resolveStep()}
          {step > 0 && (
            <Box display="flex" width="100%" mr="auto">
              <BackButton onClick={() => setStep(step - 1)} />
            </Box>
          )}
          {error && error.length > 0 && (
            <Typography color="error">{error}</Typography>
          )}
        </form>
      ) : (
        <>
          <Typography variant="h4">Thanks for signing up!</Typography>
          <Typography my={2} color="primary">
            You will be re-directed to log in...
          </Typography>
        </>
      )}
    </SignupLayout>
  );
};
