import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Copy } from "react-feather";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AmountAndCurrency, PaymentMethod } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { LoadingSpinner } from "../shared/LoadingSpinner";

type BankTransferPaymentProps = {
  invoiceId: string;
  reference: string;
  amount: AmountAndCurrency;
  onSuccess: () => void;
  onCancel: () => void;
};

export const BankTransferPayment = ({
  invoiceId,
  reference,
  amount,
  onCancel,
  onSuccess
}: BankTransferPaymentProps) => {
  const { healthNowProperties, employer } = useData();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const addPayment = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${
          employer.data!.id
        }/invoices/${invoiceId}/payments`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            method: PaymentMethod.BANK_TRANSFER,
            amount: amount.amount
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem sending the message");
      }
    },
    onSuccess: () => {
      toast.success("Payment added");
      onSuccess();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const handleBankTransferPaymentSent = async () => {
    await addPayment.mutateAsync();
  };

  if (
    healthNowProperties.isLoading ||
    healthNowProperties.isIdle ||
    employer.isLoading
  ) {
    return <LoadingSpinner />;
  }

  const displayRow = (field: string, value: string, clipValue?: string) => {
    return (
      <TableRow>
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={{ width: "150px" }}
        >
          <Typography>{field}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>
            {value}
            <Tooltip title="Copy">
              <IconButton
                onClick={() => copyToClipboard(clipValue ?? value)}
                size="small"
              >
                <Copy size={16} />
              </IconButton>
            </Tooltip>
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid container spacing={2}>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="simple table">
          <TableBody>
            {displayRow(
              "Amount",
              `${formatCurrency(amount.amount, 2)}`,
              amount.amount.toFixed(2)
            )}
            {displayRow("Acct. #", healthNowProperties.data?.bankAccount ?? "")}
            {displayRow("Reference", reference)}
          </TableBody>
        </Table>
      </TableContainer>

      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}

      <Grid item xs={12} pt={4}>
        <Paper elevation={0}>
          <Box display="flex" justifyContent="flex-end">
            <Box marginRight={2}>
              <LoadingButton
                onClick={handleBankTransferPaymentSent}
                variant="contained"
                color="primary"
                loading={
                  addPayment.isLoading ||
                  employer.isLoading ||
                  employer.data === undefined
                }
                autoFocus
              >
                Payment Sent
              </LoadingButton>
            </Box>
            <Box>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
