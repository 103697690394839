import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Box
} from "@mui/material";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";
import { AmountAndCurrency, EmployeeAccount, PlanReference } from "../../models";
import React, { useState, useMemo, useEffect } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";


type PlanSelectionProps = {
  employeeAccounts: EmployeeAccount[];
  onEmployeeAccountsChange: (plansToRemove: EmployeeAccount[], plansToAdd: PlanReference[]) => void;
  DEFAULT_VALUE: string;
  disabledPlans?: string[];
};

export const UserPlans = ({
                            employeeAccounts,
                            onEmployeeAccountsChange,
                            DEFAULT_VALUE,
                            disabledPlans
                          }: PlanSelectionProps) => {
  const { plans } = useData();

  const [removedAccounts, setRemovedAccounts] = useState<string[]>([]);
  const [newPlans, setNewPlans] = useState<PlanReference[]>([]);

  useEffect(() => {
    onEmployeeAccountsChange(
      employeeAccounts.filter(account => removedAccounts.includes(account.id)),
      newPlans
    );
  }, [removedAccounts, newPlans, employeeAccounts]);

  const handleRemoveAccount = (id: string) => {
    setRemovedAccounts((prev) => [...prev, id]);
  };

  const handleUnRemoveAccount = (id: string) => {
    setRemovedAccounts((prev) => prev.filter((removedId) => removedId !== id));
  };

  const handleAddNewPlan = () => {
    setNewPlans((prev) => [...prev, { planId: "", tierId: "" }]);
  };

  const handleNewPlanChange = (index: number, field: keyof PlanReference, value: string) => {
    const updatedPlans = [...newPlans];
    updatedPlans[index] = { ...updatedPlans[index], [field]: value };
    setNewPlans(updatedPlans);
  };

  const handleRemoveNewPlan = (index: number) => {
    setNewPlans((prev) => prev.filter((_, idx) => idx !== index));
  };

  const {
    removedCount,
    totalAvailableBalanceRemoved,
    remainingAccountsTotalBalance
  } = useMemo(() => {
    const removedPlans = employeeAccounts.filter((account) =>
      removedAccounts.includes(account.id)
    );
    const remainingAccounts = employeeAccounts.filter(
      (account) => !removedAccounts.includes(account.id)
    );

    const totalBalanceRemoved = removedPlans.reduce(
      (acc, account) => acc + account.availableBalance.amount,
      0
    );
    const totalRemainingBalance = remainingAccounts.reduce(
      (acc, account) => acc + account.availableBalance.amount,
      0
    );

    return {
      removedCount: removedPlans.length,
      totalAvailableBalanceRemoved: totalBalanceRemoved,
      remainingAccountsTotalBalance: totalRemainingBalance
    };
  }, [removedAccounts, employeeAccounts]);

  const { addedCount, totalAvailableBalanceAdded } = useMemo(() => {
    const addedPlans = newPlans
      .map((planRef) =>
        plans.data!.items.find((plan) => plan.id === planRef.planId)?.tiers.find((tier) => tier.id === planRef.tierId)
      )
      .filter((plan) => plan !== undefined) as { amount: AmountAndCurrency }[];

    const totalBalanceAdded = addedPlans.reduce((acc, plan) => acc + plan.amount.amount, 0);

    return {
      addedCount: addedPlans.length,
      totalAvailableBalanceAdded: totalBalanceAdded
    };
  }, [newPlans, plans.data]);

  const finalBalance = remainingAccountsTotalBalance + totalAvailableBalanceAdded;

  if (!plans.data?.items.length) return <div />;

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Current Plans</Typography>
          <Button variant="contained" color="primary" onClick={handleAddNewPlan}>
            Add Plan
          </Button>
        </Box>
      </Grid>

      {employeeAccounts.map((employeePlan, index) => {
        const plan = plans.data!.items.find((x) => x.id === employeePlan.planId);
        if (!plan) return <div key={index}></div>;

        const isRemoved = removedAccounts.includes(employeePlan.id);

        return (
          <Grid item xs={12} key={index}>
            <InputLabel id={employeePlan.id}>{employeePlan.name}</InputLabel>
            <Box display="flex" alignItems="center">
              <Select
                key={plan!.id}
                labelId={plan!.id}
                value={employeeAccounts.find((x) => x.planId === plan!.id)?.tierId ?? DEFAULT_VALUE}
                onChange={(e: SelectChangeEvent) => {}}
                fullWidth
                required
                size="small"
                disabled={true}
                sx={{ flex: 1 }}
              >
                {[
                  ...plan!.tiers.map((tier, index) => (
                    <MenuItem value={tier.id} key={index}>
                      {tier.name} {formatCurrency(tier.amount.amount)}
                    </MenuItem>
                  )),
                  <MenuItem value={DEFAULT_VALUE} defaultChecked key={9999}>
                    Not selected ($0)
                  </MenuItem>
                ]}
              </Select>
              {!isRemoved ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveAccount(employeePlan.id)}
                  sx={{ marginLeft: 2 }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleUnRemoveAccount(employeePlan.id)}
                  sx={{ marginLeft: 2 }}
                >
                  Cancel removal
                </Button>
              )}
            </Box>
          </Grid>
        );
      })}

      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <Typography variant="h6">Added Plans</Typography>
        {newPlans.map((newPlan, index) => (
          <Grid item xs={12} key={index} style={{ marginTop: "10px" }}>
            <Box display="flex" alignItems="center">
              <Select
                labelId={`new-plan-${index}`}
                value={newPlan.planId}
                onChange={(e: SelectChangeEvent) =>
                  handleNewPlanChange(index, "planId", e.target.value)
                }
                fullWidth
                required
                size="small"
                sx={{ flex: 1 }}
              >
                {plans.data!.items.map((plan, idx) => (
                  <MenuItem value={plan.id} key={idx}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                labelId={`new-tier-${index}`}
                value={newPlan.tierId}
                onChange={(e: SelectChangeEvent) =>
                  handleNewPlanChange(index, "tierId", e.target.value)
                }
                fullWidth
                required
                size="small"
                sx={{ flex: 1, marginLeft: 2 }}
                disabled={!newPlan.planId}
              >
                {newPlan.planId &&
                  plans.data!.items
                    .find((p) => p.id === newPlan.planId)
                    ?.tiers.map((tier, idx) => (
                    <MenuItem value={tier.id} key={idx}>
                      {tier.name} {formatCurrency(tier.amount.amount)}
                    </MenuItem>
                  ))}
              </Select>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleRemoveNewPlan(index)}
                sx={{ marginLeft: 2 }}
              >
                Remove
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      {(removedCount > 0 || addedCount > 0) && (
        <Grid item xs={12} style={{ marginTop: "20px", display: "flex", justifyContent: "space-around" }}>
          {removedCount > 0 && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="error" sx={{ display: "flex", alignItems: "center" }}>
                <RemoveCircleOutlineIcon fontSize="small" color="error" sx={{ marginRight: 1 }} />
                {removedCount} removed: {formatCurrency(totalAvailableBalanceRemoved)}
              </Typography>
            </Box>
          )}
          {addedCount > 0 && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="primary" sx={{ display: "flex", alignItems: "center" }}>
                <AddCircleOutlineIcon fontSize="small" color="primary" sx={{ marginRight: 1 }} />
                {addedCount} added: {formatCurrency(totalAvailableBalanceAdded)}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
              <AccountBalanceIcon fontSize="small" sx={{ marginRight: 1 }} />
              Balance: {formatCurrency(finalBalance)} (Δ {formatCurrency(totalAvailableBalanceAdded - totalAvailableBalanceRemoved)})
            </Typography>
          </Box>
        </Grid>
      )}

    </>
  );
};
