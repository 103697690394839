import { Box, Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import ExtraordinaryShapes from "../../assets/extraordinary-shapes.svg";
import { ReactComponent as OnboardingStepsWireframe } from "../../assets/onboarding-steps-wireframe.svg";
import { Employer } from "../../models";

export const Step0Welcome: FC<{
  employer: Employer | undefined;
  next: () => void;
}> = ({ employer, next }) => {
  return (
    <Grid container item>
      <Grid item xs={12} lg={8}>
        <Typography fontSize={28} variant="h4" my={1} color="primary">
          Welcome to extraordinary!
        </Typography>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Now let's get you setup
        </Typography>
        <Typography mt={5} mb={3}>
          Next Steps:
        </Typography>
        <Box display="flex" gap={2}>
          <OnboardingStepsWireframe />
          <Box display="flex" flexDirection="column" gap={4.5} mt={-0.4}>
            <Box>
              <Typography fontWeight={600}>
                Create Contribution Accounts
              </Typography>
              <Typography>
                Choose benefits and allowances for your staff
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Add your Team</Typography>
              <Typography>Assign Accounts to your staff</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Payment</Typography>
              <Typography>Add a float to activate your staff cards</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>All done!</Typography>
              <Typography>Company setup in 5 minutes</Typography>
            </Box>
          </Box>
        </Box>
        <Button variant="contained" sx={{ my: 5 }} onClick={next}>
          Get Started
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        display={{ xs: "none", md: "initial" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          height: "100vh"
        }}
      >
        <img
          alt=""
          src={ExtraordinaryShapes}
          style={{
            height: "100vh",
            objectFit: "contain",
            position: "relative",
            right: 0,
            bottom: 0
          }}
        />
      </Grid>
    </Grid>
  );
};
