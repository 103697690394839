import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import {
  AlertCircle,
  ArrowLeftCircle,
  CheckCircle,
  PlayCircle,
  Upload,
  XCircle
} from "react-feather";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CompleteImportDialog } from "../../components/employees/CompleteImportDialog";
import { UploadCsv } from "../../components/employees/UploadCsv";
import { LightTooltip } from "../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Table } from "../../components/shared/Table";
import {
  CreateEmployeeRequest,
  PlanReference,
  TagCategory,
  TagReference
} from "../../models";
import { checkJobProgress } from "../../utils/checkJobProgress";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { useDynamicImportEmployeeValidationSchema } from "../../utils/useDynamicValidationSchema";

enum ImportStatus {
  READY = "Ready",
  VALIDATION_ERROR = "Validation error",
  IN_PROGRESS = "In Progress",
  IMPORTED = "Succeed",
  FAILED = "Failed"
}

type CsvEmployeeRecord = {
  employee: CreateEmployeeRequest;
  status: ImportStatus;
  errors: any;
};

type ImportParameters = {
  plans: PlanReference[];
  tags: TagReference[];
  sendInvitationEmail: boolean;
};

const findTagReferenceByName = (
  tagCategories: TagCategory[],
  categoryName: string,
  tagName: string
): TagReference | null => {
  const tagCategory = tagCategories.find(
    (x) => x.name.toLowerCase() === categoryName.toLowerCase()
  );
  if (!tagCategory) {
    return null;
  }
  const tag = tagCategory.tags.find(
    (x) => x.name.toLowerCase() === tagName.toLowerCase()
  );
  if (!tag) {
    return null;
  }

  return {
    tagCategoryId: tagCategory.id,
    tagId: tag.id
  };
};

export const ImportEmployees = () => {
  const theme = useTheme();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const [uploadCsvModalOpen, setUploadCsvModalOpen] = useState<boolean>(false);
  const [completeImportModalOpen, setCompleteImportModalOpen] =
    useState<boolean>(false);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);
  const [csvEmployees, setCsvEmployees] = useState<CsvEmployeeRecord[]>([]);
  const [importStatus, setImportStatus] = useState<ImportStatus>(
    ImportStatus.READY
  );
  const [idempotencyId, setIdempotencyId] = useState<string>(uuidv4());
  const { employer, employees, membership, tagCategories } = useData();

  const validationSchema = useDynamicImportEmployeeValidationSchema(
    employer.data?.country.id ?? "",
    employees.data?.items ?? []
  );

  useEffect(() => {
    const errorCount = csvEmployees.filter(
      (x) => x.errors.length > 0 || x.status !== ImportStatus.READY
    ).length;
    setIsValidInput(errorCount === 0);
  }, [csvEmployees, setCsvEmployees]);

  useEffect(() => {
    setIdempotencyId(uuidv4());
  }, []);

  const onJobComplete = async () => {
    const updatedEmployees = csvEmployees.map((x) => {
      x.status = ImportStatus.IMPORTED;
      return x;
    });
    setCsvEmployees(updatedEmployees);
    setImportStatus(ImportStatus.IMPORTED);
    await employees.refetch();
  };

  const importEmployees = useMutation({
    mutationFn: async ({
      plans,
      tags,
      sendInvitationEmail
    }: ImportParameters) => {
      // Initialize a progress toast
      let toastId = toast("Importing Employees...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const employeesToImport = csvEmployees.map((x) => x.employee);
      for (let i = 0; i < employeesToImport.length; i++) {
        employeesToImport[i].plans = plans;
        employeesToImport[i].tags = (employeesToImport[i].tags ?? []).concat(
          tags
        );
        employeesToImport[i].sendInvitationEmail = sendInvitationEmail;
      }

      setImportStatus(ImportStatus.IN_PROGRESS);

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data!.id}/employees/import`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "X-Idempotency-ID": idempotencyId,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(employeesToImport)
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem importing Empoyees");
      }

      const responseBody = await response.json();

      checkJobProgress(
        config!.API_URL,
        responseBody.jobId,
        toastId,
        "Employees imported!",
        getToken,
        onJobComplete
      );
    },
    onSuccess: () => {
      toast.success("Employees will be imported in background!");
      employees.refetch();
    },
    onError: (error: Error) => {
      console.error(error.message);
    }
  });

  const displayCell = (value: string, error: string) => {
    return (
      <Container maxWidth="sm">
        <Typography variant="body2" component="div" gutterBottom>
          {value}
        </Typography>
        {error && (
          <Typography
            variant="body2"
            component="div"
            color="error"
            gutterBottom
          >
            {error}
          </Typography>
        )}
      </Container>
    );
  };

  const columns: ColumnDef<CsvEmployeeRecord>[] = [
    {
      accessorKey: "status",
      header: "Status",
      cell: (cell) => {
        const status = cell.getValue() as ImportStatus;
        switch (status) {
          case ImportStatus.READY:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title="Ready to import" placement="top">
                  <PlayCircle color={theme.palette.primary.dark} />
                </LightTooltip>
              </Box>
            );
          case ImportStatus.IMPORTED:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title="Successfully imported" placement="top">
                  <CheckCircle color={theme.palette.primary.main} />
                </LightTooltip>
              </Box>
            );
          case ImportStatus.VALIDATION_ERROR:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip
                  title="Employee's data was not properly formatted or missing"
                  placement="top"
                >
                  <AlertCircle color={theme.palette.error.main} />
                </LightTooltip>
              </Box>
            );
          case ImportStatus.FAILED:
          default:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title="Failed to import" placement="top">
                  <XCircle color={theme.palette.error.main} />
                </LightTooltip>
              </Box>
            );
        }
      },
      meta: {
        filterSelectOptions: Object.keys(ImportStatus).map((statusKey) => ({
          option:
            statusKey === "None"
              ? "-"
              : ImportStatus[statusKey as keyof typeof ImportStatus],
          value:
            statusKey === "None"
              ? "-"
              : ImportStatus[statusKey as keyof typeof ImportStatus] // Allows matching of "None" to "-"
        }))
      },
      filterFn: (row, columnId, filterValues: string) => {
        return filterValues.includes(row.getValue(columnId));
      }
    },
    {
      accessorFn: (row) => {
        if (
          row.employee.customerEmployeeId &&
          row.employee.customerEmployeeId?.length > 0
        )
          return row.employee.customerEmployeeId;
        return null;
      },
      cell: (cell) => {
        let value = "";
        if (
          cell.row.original.employee.customerEmployeeId &&
          cell.row.original.employee.customerEmployeeId?.length > 0
        ) {
          value = cell.row.original.employee.customerEmployeeId;
        }
        return displayCell(
          value,
          cell.row.original.errors["customerEmployeeId"]
        );
      },
      header: "Id in your system",
      sortingFn: (rowA, rowB) => {
        let rowAId = "";
        let rowBId = "";
        if (
          rowA.original.employee.customerEmployeeId &&
          rowA.original.employee.customerEmployeeId?.length > 0
        )
          rowAId = rowA.original.employee.customerEmployeeId;
        if (
          rowB.original.employee.customerEmployeeId &&
          rowB.original.employee.customerEmployeeId?.length > 0
        )
          rowBId = rowB.original.employee.customerEmployeeId;
        return rowAId < rowBId ? -1 : rowAId === rowBId ? 0 : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.firstName,
      header: "First Name",
      cell: (cell) =>
        displayCell(
          cell.row.original.employee.firstName,
          cell.row.original.errors["firstName"]
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.employee.firstName <
          rowB.original.employee.firstName
          ? -1
          : rowA.original.employee.firstName ===
            rowB.original.employee.firstName
            ? 0
            : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.middleName,
      header: "Middle Name",
      cell: (cell) =>
        displayCell(
          cell.row.original.employee.middleName,
          cell.row.original.errors["middleName"]
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.employee.middleName <
          rowB.original.employee.middleName
          ? -1
          : rowA.original.employee.middleName ===
            rowB.original.employee.middleName
            ? 0
            : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.lastName,
      header: "Last Name",
      cell: (cell) =>
        displayCell(
          cell.row.original.employee.lastName,
          cell.row.original.errors["lastName"]
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.employee.lastName < rowB.original.employee.lastName
          ? -1
          : rowA.original.employee.lastName === rowB.original.employee.lastName
            ? 0
            : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.contact.email,
      header: "Email",
      cell: (cell) =>
        displayCell(
          cell.row.original.employee.contact.email,
          cell.row.original.errors["contact.email"]
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.employee.contact.email <
          rowB.original.employee.contact.email
          ? -1
          : rowA.original.employee.contact.email ===
            rowB.original.employee.contact.email
            ? 0
            : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.contact.phone,
      header: "Phone",
      cell: (cell) =>
        displayCell(
          cell.row.original.employee.contact.phone,
          cell.row.original.errors["contact.phone"]
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.employee.contact.phone <
          rowB.original.employee.contact.phone
          ? -1
          : rowA.original.employee.contact.phone ===
            rowB.original.employee.contact.phone
            ? 0
            : 1;
      },
      enableSorting: true
    },
    {
      accessorFn: (row) => row.employee.tags,
      header: "Tags",
      cell: (cell) => {
        const tags = [];
        const totalTags = cell.row.original.employee.tags?.length ?? 0;
        if (!tagCategories.data || totalTags === 0) {
          return displayCell("", cell.row.original.errors["/tags"]);
        }
        for (let i = 0; i < totalTags; ++i) {
          const tagReference = cell.row.original.employee.tags![i];
          const tagCategory = tagCategories.data.items.find(
            (tagCategory) => tagCategory.id === tagReference.tagCategoryId
          );
          if (!tagCategory) {
            continue;
          }
          const tag = tagCategory.tags.find(
            (tag) => tag.id === tagReference.tagId
          );
          if (tag) {
            tags.push(tag?.name);
          }
        }
        return displayCell(tags.join(", "), cell.row.original.errors["/tags"]);
      },
      enableSorting: false
    }
  ];

  if (employees.isLoading || employees.isIdle || tagCategories.isLoading)
    return <LoadingSpinner />;

  if (
    employees.error ||
    !employees.data ||
    tagCategories.error ||
    !tagCategories.data
  )
    return <p>Something went wrong, please try again later</p>;

  const showCompleteImportModal = () => {
    if (!isValidInput) {
      toast.error("Please fix import data errors.");
      return;
    }
    setCompleteImportModalOpen(true);
  };

  const handleReset = () => {
    setIdempotencyId(uuidv4());
    setCsvEmployees([]);
    setImportStatus(ImportStatus.READY);
  };

  const getHeaderActions = () => {
    if (csvEmployees.length > 0) {
      return (
        <>
          <Button
            variant="contained"
            onClick={handleReset}
            endIcon={<ArrowLeftCircle size={16} />}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={showCompleteImportModal}
            endIcon={<Upload size={16} />}
            disabled={importStatus !== ImportStatus.READY}
          >
            Complete Import
          </Button>
        </>
      );
    }
    return (
      <Button
        variant="contained"
        onClick={() => setUploadCsvModalOpen(true)}
        endIcon={<Upload size={16} />}
        disabled={
          membership.data?.features?.maxEmployeeCount
            ? employees.data.totalItems >=
            membership.data.features.maxEmployeeCount
            : false
        }
      >
        Upload CSV
      </Button>
    );
  };

  function addError(
    record: CsvEmployeeRecord,
    fieldPath: string,
    error: string
  ) {
    if (!record.errors) {
      record.errors = {};
    }
    record.errors[fieldPath] = error;
    record.status = ImportStatus.VALIDATION_ERROR;
  }

  function checkDuplicates(employeeRecords: CsvEmployeeRecord[]) {
    const emailMap: Map<string, CsvEmployeeRecord[]> = new Map();
    const customerEmployeeIdMap: Map<string, CsvEmployeeRecord[]> = new Map();

    employeeRecords.forEach((record) => {
      const email = record.employee.contact.email;
      if (emailMap.has(email)) {
        emailMap.get(email)!.push(record);
      } else {
        emailMap.set(email, [record]);
      }

      const customerEmployeeId = record.employee.customerEmployeeId;
      if (customerEmployeeId) {
        if (customerEmployeeIdMap.has(customerEmployeeId)) {
          customerEmployeeIdMap.get(customerEmployeeId)!.push(record);
        } else {
          customerEmployeeIdMap.set(customerEmployeeId, [record]);
        }
      }
    });

    emailMap.forEach((records, email) => {
      if (records.length > 1) {
        records.forEach((record) => {
          addError(record, `contact.email`, `Duplicate email: ${email}`);
        });
      }
    });

    customerEmployeeIdMap.forEach((records, customerEmployeeId) => {
      if (records.length > 1) {
        records.forEach((record) => {
          addError(
            record,
            `customerEmployeeId`,
            `Duplicate customerEmployeeId: ${customerEmployeeId}`
          );
        });
      }
    });
  }

  const onCsvProcessed = async (csvRows: any[]) => {
    const employeesToUpload: CsvEmployeeRecord[] = csvRows.map((row) => {
      const employee: CreateEmployeeRequest = {
        firstName: row.firstname,
        middleName: row.middlename,
        lastName: row.lastname,
        contact: {
          phone: row.phone,
          email: row.email?.toLowerCase(),
          address: {
            country: employer.data!.contact.address.country
          }
        },
        customerEmployeeId: row.id,
        metadata: {
          source: "CSV_IMPORT"
        },
        sendInvitationEmail: false
      };

      const keys = Object.keys(row);
      const errors: any = {};
      let status: ImportStatus = ImportStatus.READY;
      employee.tags = [];
      keys.forEach((key) => {
        const tagMatch = key.match(/^tag\[(.+)\]$/);
        if (tagMatch) {
          const categoryName = tagMatch[1];
          const tagName = row[key];
          if (tagName !== "") {
            const tag = findTagReferenceByName(
              tagCategories.data.items,
              categoryName,
              tagName
            );
            if (tag === null) {
              errors[
                "/tags"
              ] = `Tag '${tagName}' not found in Category '${categoryName}'.`;
              status = ImportStatus.VALIDATION_ERROR;
            } else {
              employee.tags!.push(tag);
            }
          }
        }
      });

      return {
        employee: employee,
        status: status,
        errors: errors
      };
    });

    for (let i = 0; i < employeesToUpload.length; i++) {
      const record = employeesToUpload[i];
      try {
        await validationSchema.validate(record.employee, { abortEarly: false });
      } catch (validationError: any) {
        record.status = ImportStatus.VALIDATION_ERROR;
        record.errors = {
          ...record.errors,
          ...validationError.inner.reduce((acc: any, error: any) => {
            acc[error.path] = error.message;
            return acc;
          }, [])
        };
      }
    }

    checkDuplicates(employeesToUpload);
    setCsvEmployees(employeesToUpload);
    setUploadCsvModalOpen(false);
  };

  const onComplete = (
    plans: PlanReference[],
    tags: TagReference[],
    sendInvitationEmail: boolean
  ) => {
    if (!isValidInput) {
      toast.error("Please fix import data errors.");
      return;
    }

    if (
      membership.data?.features?.maxEmployeeCount &&
      csvEmployees.length + employees.data!.totalItems >=
      membership.data.features.maxEmployeeCount
    ) {
      toast.error(
        "Maximum Employees limit reached, please contact sales team."
      );
      return;
    }

    if (csvEmployees.length === 0) {
      return;
    }

    importEmployees.mutate({ plans, tags, sendInvitationEmail });

    setCompleteImportModalOpen(false);
  };

  return (
    <>
      <CompleteImportDialog
        isLoading={importEmployees.isLoading}
        open={completeImportModalOpen}
        handleClose={() => setCompleteImportModalOpen(false)}
        employeesCount={csvEmployees.length}
        onComplete={onComplete}
      />
      <UploadCsv
        open={uploadCsvModalOpen}
        handleClose={() => setUploadCsvModalOpen(false)}
        onUploaded={onCsvProcessed}
      />
      <Table<CsvEmployeeRecord>
        data={csvEmployees}
        totalItems={csvEmployees.length}
        columns={columns}
        rowSelection={false}
        headerActions={getHeaderActions()}
      />
    </>
  );
};
