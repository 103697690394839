import { ReportEntity } from "./reportEntity";

export type ReportFilter = {
  entity: ReportEntity;
  path: string;
  operator: ReportFilterOperator;
  value: string;
};

export enum ReportFilterOperator {
  EQUALS = "EQUALS",
  CONTAINS = "CONTAINS"
}
