import { Grid } from "@mui/material";
import { FC, ReactNode } from "react";
import ExtraordinaryBlack from "../assets/logos/ExtraordinaryBlack.png";

export const SignupLayout: FC<{
  imageSrc: string;
  children: ReactNode;
}> = ({ imageSrc, children }) => {
  return (
    <Grid
      container
      direction="row"
      pl={{ xs: 5, sm: 10, md: 20, lg: 30 }}
      gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
    >
      <style>
        {`
          .grecaptcha-badge {
            bottom: auto !important;
            top: 4px !important;
          }
        `}
      </style>
      <Grid container direction="column" xs={11} md={6} lg={4} my={5}>
        <Grid item>
          <img src={ExtraordinaryBlack} alt="Logo" height={28} />
        </Grid>
        <Grid container item mt={14}>
          {children}
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        display={{ xs: "none", md: "initial" }}
        // overflow="hidden"
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          height: "100vh"
          // width: "100vw"
        }}
      >
        <img
          alt=""
          src={imageSrc}
          style={{
            height: "100vh",
            // width: "100%",
            objectFit: "contain",
            position: "relative",
            right: 0,
            bottom: 0
          }}
        />
      </Grid>
    </Grid>
  );
};
