import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { TagCategory } from "../../models/tagCategory";

type CreateTagCategoryProps = {
  open: boolean;
  handleClose: () => void;
};

export const CreateTagCategory = ({
  open,
  handleClose
}: CreateTagCategoryProps) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const { employerId, employees, tagCategories } = useData();
  const [tagCategoryState, setTagCategoryState] = useState<
    Partial<TagCategory>
  >({
    id: undefined,
    name: undefined,
    description: undefined,
    tags: []
  });

  const [error, setError] = useState("");

  const mutationCreateTagCategory = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/tag-categories`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(tagCategoryState)
        }
      );

      if (!response.ok)
        throw new Error("There was a problem creating this Tag Category");
    },
    {
      onSuccess: () => {
        toast.success("Tag Category created successfully");
        handleClose();
        employees.refetch();
        tagCategories.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    mutationCreateTagCategory.mutate();
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between" m={1}>
            <DialogTitle>Create Tag Category</DialogTitle>
          </Box>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                value={tagCategoryState?.name}
                onChange={(e) =>
                  setTagCategoryState({
                    ...tagCategoryState,
                    name: e.target.value
                  })
                }
                label="Name"
                name="name"
                required
                fullWidth
                size="small"
              />
              <TextField
                value={tagCategoryState?.description}
                onChange={(e) =>
                  setTagCategoryState({
                    ...tagCategoryState,
                    description: e.target.value
                  })
                }
                label="Description"
                name="description"
                required
                fullWidth
                size="small"
              />
              <Typography color="error">{error}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !tagCategoryState?.name || !tagCategoryState?.description
              }
              loading={mutationCreateTagCategory.isLoading}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
