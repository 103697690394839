import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { AlertCircle, ArrowRight, CheckCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { CurrentMonthInvoiceView } from "../../components/billing/CurrentMonthInvoiceView";
import { LightTooltip } from "../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Table } from "../../components/shared/Table";
import { Invoice, InvoiceStatus, InvoiceType } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";

export const Billing = () => {
  const { invoices } = useData();
  const theme = useTheme();
  const navigate = useNavigate();
  const [platformInvoices, setPlatformInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (!invoices.isLoading && invoices.data) {
      const clonedInvoices = [...invoices.data.items];
      setPlatformInvoices(
        clonedInvoices.filter(
          (invoice) => invoice.type === InvoiceType.PLATFORM_FEE
        )
      );
    }
  }, [invoices.isLoading, invoices.data]);

  if (invoices.isLoading || invoices.isIdle) {
    return <LoadingSpinner />;
  }

  if (invoices.error || !invoices.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  const currentMonthInvoice = platformInvoices.find(
    (x) => x.period.month === currentMonth && x.period.year === currentYear
  );

  const pastInvoices = platformInvoices
    .filter(
      (x) => x.period.month !== currentMonth || x.period.year !== currentYear
    )
    .sort((a, b) => {
      return (
        new Date(b.period.start).getTime() - new Date(a.period.start).getTime()
      );
    });

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorKey: "status",
      header: () => (
        <div style={{ textAlign: "center", width: "150px" }}>Status</div>
      ),
      cell: (cell) => {
        const invoiceStatus = cell.getValue() as InvoiceStatus;
        const isPaid =
          invoiceStatus === InvoiceStatus.PAID ||
          invoiceStatus === InvoiceStatus.PAYMENT_PENDING;
        return (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "150px" }}
          >
            <LightTooltip
              title={isPaid ? "Paid" : "Ready To Pay"}
              placement="top"
            >
              {isPaid ? (
                <CheckCircle color={theme.palette.primary.main} />
              ) : (
                <AlertCircle color={theme.palette.warning.main} />
              )}
            </LightTooltip>
          </Box>
        );
      },
      enableSorting: false,
      id: "Status",
      size: 20
    },
    {
      accessorFn: (row) => row.number,
      header: "Number",
      sortingFn: (rowA, rowB) => {
        return rowA.original.number < rowB.original.number ? -1 : 1;
      },
      size: 20
    },
    {
      accessorFn: (row) => row.period.title,
      header: "Period",
      sortingFn: (rowA, rowB) => {
        return rowA.original.period.title < rowB.original.period.title ? -1 : 1;
      },
      size: 1000
    },
    {
      accessorKey: "totalAmount",
      header: () => (
        <div style={{ textAlign: "center", width: "100px" }}>Total</div>
      ),
      cell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          {formatCurrency(row.original.amount.amount, 2)}
        </Box>
      ),
      size: 100
    },
    {
      id: "Details",
      cell: ({ row }) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={() => navigate(`/invoices/${row.original.id}`)}>
            <ArrowRight />
          </IconButton>
        </Box>
      ),
      enableSorting: false,
      header: () => <div style={{ textAlign: "right" }}>Details</div>,
      size: 20
    }
  ];

  return (
    <>
      <Grid container spacing={5} flexDirection="column">
        <Grid item>
          <Box minWidth="fit-content">
            <Typography variant="h4">Billing & Invoicing</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box minWidth="fit-content">
            {currentMonthInvoice && (
              <CurrentMonthInvoiceView invoice={currentMonthInvoice} />
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box minWidth="fit-content">
            <Typography variant="h6" pb={2}>
              Invoices
            </Typography>
            <Table<Invoice>
              data={pastInvoices}
              totalItems={pastInvoices.length}
              columns={columns}
              displaySearch={false}
              displayFilters={false}
              rowSelection={false}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
