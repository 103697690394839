import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Download, Settings } from "react-feather";
import { useMutation } from "react-query";
import { Report } from "../../models/reporting/report";
import { ReportEntity } from "../../models/reporting/reportEntity";
import { ReportPeriod } from "../../models/reporting/reportPeriod";
import { ReportType } from "../../models/reporting/reportType";
import { ReportVisualScale } from "../../models/reporting/reportVisualScale";
import {
  convertCardsToCsv,
  convertEmployeesToCsv,
  convertTransactionsToCsv,
  downloadCsv
} from "../../utils/convertToCSV";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import ReportLineChart from "../charts/ReportLineChart";
import MainCard from "../shared/MainCard";
import applyReportFilters from "./applyReportFilters";
import { EditReport } from "./EditReport";
import { ReportDataTable } from "./ReportDataTable";
import { ReportStatValue } from "./ReportStatValue";

export const RenderReport: FC<{
  report: Report;
  documents: any;
}> = ({ report, documents }) => {
  const { config } = useConfig();
  const {
    employerId,
    employees,
    accounts,
    reports,
    tagCategories,
    plans,
    serviceSectors
  } = useData();
  const { getToken } = useKindeAuth();

  const [key, setKey] = useState(0); // Used to track state of chart, so we can re-build the chart when data changes
  const [visualScale, setVisualScale] = useState(report.visualScale);
  const [isEditingReport, setIsEditingReport] = useState(false);

  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);

  const resolveVisualScale = () => {
    switch (visualScale) {
      case ReportVisualScale.SM:
        return 4;
      case ReportVisualScale.MD:
        return 6;
      case ReportVisualScale.LG:
        return 12;
      default:
        return 12;
    }
  };
  const [columns, setColumns] = useState(resolveVisualScale());

  const renderReport = () => {
    switch (report.type) {
      case ReportType.STAT_VALUE:
        return (
          <ReportStatValue
            key={key}
            report={report}
            filteredDocuments={filteredDocuments}
          />
        );
      case ReportType.LINE_CHART:
        return (
          <ReportLineChart
            key={key}
            report={report}
            filteredDocuments={filteredDocuments}
          />
        );
      case ReportType.DATA_TABLE:
        return <ReportDataTable key={key} csvData={getCsv(report.entity)} />;
    }
  };

  // Used to update reports visual scale value
  const mutationUpdateReport = useMutation(
    async (visualScale: ReportVisualScale) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports/${report.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...report,
            visualScale
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this report");
    },
    {
      onSuccess: () => {
        reports.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const getCsv = (reportEntity: ReportEntity) => {
    switch (reportEntity) {
      case ReportEntity.EMPLOYEES: {
        return convertEmployeesToCsv(
          filteredDocuments,
          accounts.data?.items ?? [],
          tagCategories.data?.items,
          plans.data?.items
        );
      }
      case ReportEntity.TRANSACTIONS: {
        return convertTransactionsToCsv(
          filteredDocuments,
          report.data.isAnonymised,
          serviceSectors.data?.items,
          employees.data?.items,
          tagCategories.data?.items,
          plans.data?.items
        );
      }
      case ReportEntity.CARDS: {
        return convertCardsToCsv(
          filteredDocuments,
          report.data.isAnonymised,
          employees.data?.items,
          tagCategories.data?.items,
          plans.data?.items
        );
      }
      default: {
        throw new Error("Unsupported Export");
      }
    }
  };

  const handleDownloadReport = () => {
    const csv = getCsv(report.entity);
    downloadCsv(csv, `${report.entity}${new Date().toLocaleString()}`);
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [report]);

  useEffect(() => {
    if (employees.data?.items) {
      let filtered = applyReportFilters(
        documents,
        report,
        employees.data.items
      );
      setFilteredDocuments(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, documents]);

  useEffect(() => {
    if (visualScale !== report.visualScale) {
      mutationUpdateReport.mutate(visualScale);
    }
    setColumns(resolveVisualScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualScale]);

  useEffect(() => {
    setVisualScale(report.visualScale);
  }, [report.visualScale]);

  return (
    <Grid item sm={columns}>
      {/* @ts-ignore */}
      <MainCard content={false}>
        <Box>
          <Grid item>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ mt: 2, mx: 2 }}
            >
              <Box>
                <Typography variant="h6">{report.title}</Typography>
                <Typography variant="subtitle2" color="grey">
                  {report.entity[0]}
                  {report.entity
                    .toLowerCase()
                    .slice(1, report.entity.length)} -{" "}
                  {report.data.period !== ReportPeriod.ALL_TIME
                    ? "Current"
                    : ""}{" "}
                  {report.data.period[0]}
                  {report.data.period
                    .toString()
                    .replaceAll("_", " ")
                    .toLowerCase()
                    .slice(1, report.data.period.length)}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  endIcon={<Download />}
                  onClick={() => handleDownloadReport()}
                  sx={{ marginX: 1 }}
                >
                  Export
                </Button>
                <IconButton onClick={() => setIsEditingReport(true)}>
                  <Settings />
                </IconButton>
              </Box>
            </Box>
            <Grid
              container
              px={1}
              pb={1}
              width="100%"
              sx={{ maxHeight: 250, mt: 2 }}
            >
              {renderReport()}
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" color="lightGrey" padding={1}>
          {report.type !== ReportType.DATA_TABLE && (
            <Box display="flex" ml="auto" gap={1}>
              <Typography
                fontSize="14px"
                sx={{ cursor: "pointer" }}
                onClick={() => setVisualScale(ReportVisualScale.SM)}
              >
                SM
              </Typography>
              <Typography
                fontSize="14px"
                sx={{ cursor: "pointer" }}
                onClick={() => setVisualScale(ReportVisualScale.MD)}
              >
                MD
              </Typography>
              <Typography
                fontSize="14px"
                sx={{ cursor: "pointer" }}
                onClick={() => setVisualScale(ReportVisualScale.LG)}
              >
                LG
              </Typography>
            </Box>
          )}
        </Box>
      </MainCard>
      {isEditingReport && (
        <EditReport
          open={true}
          handleClose={() => setIsEditingReport(false)}
          report={report}
        />
      )}
    </Grid>
  );
};
