import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Report } from "../../models/reporting/report";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { ReportDetailsForm } from "./ReportDetailsForm";

type EditReportProps = {
  open: boolean;
  handleClose: () => void;
  report: Report;
};

export const EditReport = ({ open, handleClose, report }: EditReportProps) => {
  const { config } = useConfig();
  const { employerId, reports } = useData();
  const { getToken } = useKindeAuth();

  const [error, setError] = useState("");

  const mutationUpdateReport = useMutation(
    async (formValues: Partial<Report>) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports/${report.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formValues)
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this report");
    },
    {
      onSuccess: () => {
        toast.success("Report updated successfully");
        reports.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const mutationDeleteReport = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports/${report.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok)
        throw new Error("There was a problem deleting this Report");
    },
    {
      onSuccess: () => {
        toast.success("Report deleted successfully");
        handleClose();
        reports.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      title: report.title,
      type: report.type,
      entity: report.entity,
      visualScale: report.visualScale,
      data: {
        xAxis: report.data.xAxis,
        yAxis: report.data.yAxis,
        period: report.data.period,
        isAnonymised: report.data.isAnonymised
      },
      filters: report.filters
    },
    onSubmit: async (values) => {
      mutationUpdateReport.mutate(values);
    },
    enableReinitialize: true
  });

  // Reset form when the modal is opened
  useEffect(() => {
    if (open) {
      resetForm();
      setError("");
    }
  }, [resetForm, setError, open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <Box display="flex">
          <DialogTitle>Edit Report</DialogTitle>
          <Box ml="auto" p={2}>
            <Button
              onClick={() => mutationDeleteReport.mutate()}
              color="error"
              variant="outlined"
              style={{ marginLeft: "auto" }}
            >
              Delete
            </Button>
          </Box>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <Typography color="error">{error}</Typography>
                </Grid>
              )}
              <Box sx={{ width: "100%", px: 2, pt: 1 }}>
                <ReportDetailsForm form={formik} />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={mutationUpdateReport.isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
