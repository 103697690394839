import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { Tier } from "../../../models";
import { useData } from "../../../utils/useData";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { TierInfoBox } from "../TierInfoBox";

export const Step2Tiers: FC<{
  formik: FormikProps<CreatePlanInput>;
  currencyCode: string;
  next: () => void;
  back: () => void;
}> = ({ formik, currencyCode, next, back }) => {
  const { membership } = useData();
  const initialTier = {
    id: undefined,
    name: "",
    amount: {
      amount: 200,
      currencyCode
    }
  };

  const [isCreatingTier, setIsCreatingTier] = useState(false);
  const [tierToCreate, setTierToCreate] = useState<Tier | undefined>(
    initialTier
  );
  const [creatingTierError, setCreatingTierError] = useState<string>();

  // Handle membership tier limit restriction
  useEffect(() => {
    if (
      membership.data?.features?.maxTierAmount &&
      tierToCreate?.amount.amount &&
      tierToCreate.amount.amount > membership.data?.features?.maxTierAmount
    ) {
      setCreatingTierError("Tier amount exceeds the maximum amount");
    } else {
      setCreatingTierError(undefined);
    }
  }, [tierToCreate, membership?.data?.features?.maxTierAmount]);

  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Set your Contribution Tiers
      </Typography>
      <Typography variant="subtitle1" my={2}>
        Contribution tiers allow you assign different spend limits to your team.
      </Typography>

      {formik.values.tiers.map((tier, i) => {
        return (
          <TierInfoBox
            key={i}
            tier={tier}
            isEdit={false}
            error={undefined}
            periodInMonths={formik.values.renewalOptions.periodInMonths}
            deleteTier={() => {
              let tiers = formik.values.tiers.filter((t) => t !== tier);
              formik.setFieldValue("tiers", tiers);
            }}
          />
        );
      })}

      {isCreatingTier && tierToCreate && (
        <TierInfoBox
          tier={tierToCreate}
          isEdit={true}
          error={creatingTierError}
          periodInMonths={formik.values.renewalOptions.periodInMonths}
          updateTierToCreate={(value: Tier) => setTierToCreate(value)}
          submitTierToCreate={() => {
            let tiers = formik.values.tiers;
            tiers.push(tierToCreate);
            formik.setFieldValue("tiers", tiers);
            setIsCreatingTier(false);
            setTierToCreate(initialTier);
          }}
        />
      )}

      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        gap={1}
        my={3}
        sx={{ cursor: "pointer" }}
        onClick={() => setIsCreatingTier(true)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={1}
          borderRadius="100%"
          width={40}
          height={40}
          sx={{ bgcolor: "grey.300" }}
        >
          <Add color="primary" />
        </Box>
        <Typography fontWeight={500}>Add Tier</Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} outlined />
        <Button
          disabled={!formik.values.tiers.length || Boolean(formik.errors.tiers)}
          variant="contained"
          onClick={next}
        >
          Continue
        </Button>
      </Box>
    </Grid>
  );
};
