import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { CheckCircle, Circle } from "react-feather";
import { CurrentFloatView } from "../../components/billing/CurrentFloatView";
import { FloatPaymentBox } from "../../components/billing/FloatPaymentBox";
import { LightTooltip } from "../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Table } from "../../components/shared/Table";
import { Invoice, InvoiceStatus, InvoiceType } from "../../models";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { useData } from "../../utils/useData";

export const FloatManagement = () => {
  const { float, employer, invoices } = useData();
  const theme = useTheme();
  const [floatInvoices, setFloatInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (!invoices.isLoading && invoices.data) {
      const clonedInvoices = [...invoices.data.items];
      setFloatInvoices(
        clonedInvoices.filter(
          (invoice) =>
            invoice.type === InvoiceType.FLOAT &&
            (invoice.status === InvoiceStatus.PAID || invoice.status === InvoiceStatus.PAYMENT_PENDING)
        )
      );
    }
  }, [invoices.isLoading, invoices.data]);

  if (float.isLoading || float.isIdle || employer.isLoading) {
    return <LoadingSpinner />;
  }

  if (float.error || !float.data || employer.error || !employer.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorKey: "status",
      header: () => (
        <div style={{ textAlign: "center", width: "150px" }}>Status</div>
      ),
      cell: (cell) => {
        const invoiceStatus = cell.getValue() as InvoiceStatus;
        const isPaid = invoiceStatus === InvoiceStatus.PAID;
        const isPending = invoiceStatus === InvoiceStatus.PAYMENT_PENDING;
        const displayIcon = () => {
          if (isPaid) {
            return (<CheckCircle color={theme.palette.primary.main} />);
          }
          if (isPending) {
            return (<Circle color={theme.palette.primary.main} />);
          }
          return <></>;
        };
        return (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "150px" }}
          >
            <LightTooltip
              title={isPaid ? "Paid" : "Pending"}
              placement="top"
            >
              {displayIcon()}
            </LightTooltip>
          </Box>
        );
      },
      enableSorting: false,
      id: "Status",
      size: 20
    },
    {
      accessorFn: (row) => row.number,
      header: "Number",
      sortingFn: (rowA, rowB) => {
        return rowA.original.number < rowB.original.number ? -1 : 1;
      },
      size: 20
    },
    {
      accessorFn: (row) => formatDate(row.paidOn),
      header: "Date",
      sortingFn: (rowA, rowB) => {
        return rowA.original.paidOn < rowB.original.paidOn ? -1 : 1;
      },
      size: 1000
    },
    {
      accessorKey: "totalAmount",
      header: () => (
        <div style={{ textAlign: "center", width: "100px" }}>Total</div>
      ),
      cell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          {formatCurrency(row.original.amount.amount, 2)}
        </Box>
      ),
      size: 100
    }
  ];

  return (
    <Grid container spacing={5} flexDirection="column">
      <Grid item>
        <Box minWidth="fit-content">
          <Typography variant="h4">Float Management</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box minWidth="fit-content">
          <CurrentFloatView float={float.data} />
        </Box>
      </Grid>
      <Grid
        item
        container
        sx={{ display: "flex", justifyContent: "center" }}
        spacing={2}
        justifyContent="center"
      >
        <FloatPaymentBox />
      </Grid>
      <Grid item>
        <Box minWidth="fit-content">
          <Typography variant="h6" pb={2}>
            Receipts
          </Typography>
          <Table<Invoice>
            data={floatInvoices}
            totalItems={floatInvoices.length}
            columns={columns}
            displaySearch={false}
            displayFilters={false}
            rowSelection={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
