export const formatCurrency = (amount: number, dp?: number): string => {
  const defaultDp = amount % 1 === 0 ? 0 : 2;
  const validDp = dp !== undefined && dp >= 0 && dp <= 20 ? dp : defaultDp;

  const formatter = new Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    minimumFractionDigits: validDp,
    maximumFractionDigits: 2,
    currencyDisplay: "narrowSymbol"
  });

  return formatter.format(amount);
};

export const formatDate = (date: string | Date | undefined): string => {
  if (!date) return "";

  const formatter = new Intl.DateTimeFormat("en-NZ", {
    day: "2-digit",
    month: "short",
    year: "numeric"
  });

  return formatter.format(new Date(date));
};

export const formatCardDate = (date: string | Date | undefined): string => {
  if (!date) return "";

  const formatter = new Intl.DateTimeFormat("en-NZ", {
    month: "2-digit",
    year: "2-digit"
  });

  return formatter.format(new Date(date));
};

export const formatPercentage = (percentage: number): string => {
  if (isNaN(percentage)) {
    return "Invalid Percentage";
  }

  return `${Math.round(percentage)}%`;
};
