import { Search } from "@mui/icons-material";
import {
  Box,
  Card,
  Checkbox,
  Collapse,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  Row,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { Employee } from "../../models";
import { Filter } from "../table/Filter";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterType?: "number" | "select" | "string";
    filterSelectOptions?: {
      option: string;
      value: string;
      category?: string;
    }[];
    filterNumberRange?: { min: number; max: number };
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank
  });

  return itemRank.passed;
};

type TableProps<T> = {
  data: T[];
  totalItems: number;
  columns: ColumnDef<T>[];
  defaultSorting?: SortingState;
  headerActions?: React.ReactNode;
  itemActions?: React.ReactNode;
  displaySearch?: boolean;
  displayFilters?: boolean;
  rowSelection?: boolean;
  onSelectionChanges?: (selected: T[]) => void;
  total?: string | undefined;
  size?: "sm" | "lg";
};

export const Table = <T extends unknown>({
  data,
  totalItems,
  columns,
  defaultSorting = [],
  headerActions = undefined,
  itemActions = undefined,
  displaySearch = true,
  displayFilters = true,
  rowSelection = true,
  onSelectionChanges = undefined,
  total = undefined,
  size = "lg"
}: TableProps<T>) => {
  const [selectAll, setSelectAll] = useState<boolean | undefined>(undefined);
  const [searchValue, setSearchValue] = useState("");
  const globalFilter = useDebounce<string>(searchValue, 200);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [selected, setSelected] = useState<T[]>([]);

  const handleSelectionChange = (newSelection: T[]) => {
    setSelected(newSelection);
    if (onSelectionChanges) {
      onSelectionChanges(newSelection);
    }
  };

  useEffect(() => {
    if (selectAll === undefined) return;
    const allFilteredItems: any = table
      .getFilteredRowModel()
      .rows.map((row) => {
        row.toggleSelected(selectAll);
        return row.original;
      });

    handleSelectionChange(selectAll ? allFilteredItems : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const rowSelectionColumn: ColumnDef<T> = {
    id: "Select",
    header: ({ table }) => (
      <Checkbox
        checked={selectAll}
        onChange={(e) => {
          setSelectAll(e.target.checked);
        }}
        size="small"
        sx={{ p: 0, ml: "9px" }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="small"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={(e) => {
          row.getToggleSelectedHandler()(e);
          if (e.target.checked) {
            const newSelection = [...selected];
            newSelection.push(row.original);
            handleSelectionChange(newSelection);
          } else {
            const newSelection = selected.filter((x) => x !== row.original);
            handleSelectionChange(newSelection);
          }
        }}
      />
    ),
    enableSorting: false,
    size: 20
  };

  let adjustedColumns = [...columns];
  if (rowSelection) {
    adjustedColumns.unshift(rowSelectionColumn);
  }

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const table = useReactTable({
    data,
    columns: adjustedColumns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
      columnFilters,
      sorting,
      pagination: {
        pageIndex: page,
        pageSize: pageSize
      }
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setSearchValue,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility: {
        Name: false
      }
    }
  });

  useEffect(() => {
    table.setPageIndex(page);
    table.setPageSize(pageSize);
  }, [page, pageSize, table, data]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border: "1px solid",
        borderRadius: 1,
        borderColor: "divider",
        boxShadow: "inherit"
      }}
    >
      {/* Table header */}
      {(displaySearch || headerActions) && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center" // Add this line
          sx={{
            padding: 2,
            overflowX: "auto"
          }}
        >
          {displaySearch && (
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={`Search ${
                table.getFilteredRowModel().rows.length
              } users`}
              InputProps={{
                startAdornment: <Search />
              }}
              size="small"
              sx={{ minWidth: "200px" }}
            />
          )}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{
              padding: 2,
              overflowX: "auto"
            }}
          >
            {headerActions}
          </Stack>
        </Stack>
      )}

      {/* User Actions */}
      {itemActions && (
        <Collapse
          in={(table.getSelectedRowModel().rows as Row<Employee>[]).length > 0}
          style={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              padding: 2,
              overflowX: "auto",
              height: 72
            }}
          >
            {itemActions}
          </Stack>
        </Collapse>
      )}

      {/* Main table */}
      <TableContainer sx={{ maxHeight: size === "sm" ? 205 : undefined }}>
        <MuiTable>
          <TableHead
            sx={{
              backgroundColor: "grey.50",
              borderTop: 1,
              borderBottom: 1,
              borderColor: "grey.200"
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.200",
                      width: `${header.column.getSize()}px`
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {header.column.getCanSort() ? (
                          <TableSortLabel
                            sx={{ whiteSpace: "nowrap" }}
                            active={header.column.getIsSorted() !== false}
                            direction={
                              header.column.getIsSorted() === "asc"
                                ? "asc"
                                : "desc"
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() !== false ? (
                              <Box component="span" sx={visuallyHidden}>
                                {header.column.getIsSorted() === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            sx={{
              "> tr, th": {
                borderColor: "grey.200"
              }
            }}
          >
            {/* filters */}
            {displayFilters &&
              table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      sx={{
                        borderBottom: 1,
                        borderColor: "grey.200",
                        width: `${header.column.getSize()}px`
                      }}
                    >
                      <Filter header={header} column={header.column} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            {/* rows */}
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.200",
                      width: `${cell.column.getSize()}px`,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/* Total Row */}
            {total !== undefined && (
              <TableRow>
                {/* Assuming the total is for the first column, adjust as necessary */}
                <TableCell>
                  <b>Total</b>
                </TableCell>
                <TableCell colSpan={table.getAllColumns().length - 1}>
                  <Box display={"flex"} justifyContent="flex-end">
                    <b>{total}</b>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        component="div"
        count={table.getFilteredRowModel().rows.length}
        page={table.getState().pagination.pageIndex}
        rowsPerPage={table.getState().pagination.pageSize}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
      />
    </Card>
  );
};
