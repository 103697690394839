import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { AmountAndCurrency } from "../../models";
import { BankTransferPayment } from "./BankTransferPayment";
import { CardPayment } from "./CardPayment";

type PaymentDialogProps = {
  invoiceId: string;
  countryId: string;
  title: string;
  open: boolean;
  amount: AmountAndCurrency;
  reference: string;
  onSuccess: () => void;
  onCancel: () => void;
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const PaymentDialog = ({
  invoiceId,
  countryId,
  title,
  amount,
  reference,
  open,
  onCancel,
  onSuccess
}: PaymentDialogProps) => {
  const theme = useTheme();
  const { getBooleanFlag } = useKindeAuth();
  const [paymentMethod, setPaymentMethod] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent,
    newPaymentMethod: number
  ) => {
    setPaymentMethod(newPaymentMethod);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <b>{title}</b>
      </DialogTitle>
      <DialogContent>
        <Box pt={2}>
          <Tabs
            value={paymentMethod}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="With Bank Transfer" {...a11yProps(0)} />
            {getBooleanFlag("stripe_payments") === true && (
              <Tab label="With Card" {...a11yProps(1)} />
            )}
          </Tabs>
          <Box>
            <TabPanel value={paymentMethod} index={0} dir={theme.direction}>
              <BankTransferPayment
                invoiceId={invoiceId}
                reference={reference}
                amount={amount}
                onCancel={onCancel}
                onSuccess={onSuccess}
              />
            </TabPanel>

            {getBooleanFlag("stripe_payments") === true && (
              <TabPanel value={paymentMethod} index={1} dir={theme.direction}>
                <CardPayment
                  invoiceId={invoiceId}
                  amount={amount}
                  onCancel={onCancel}
                  onSuccess={onSuccess}
                  countryId={countryId}
                />
              </TabPanel>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
