import { DateTime } from "luxon";

type SortDateProps = {
  dateA: string;
  dateB: string;
  ascending?: boolean;
};

export const sortDate = ({
  dateA,
  dateB,
  ascending = true,
}: SortDateProps): number => {
  const dateTimeA = DateTime.fromISO(dateA);
  const dateTimeB = DateTime.fromISO(dateB);

  return (dateTimeA > dateTimeB ? 1 : -1) * (ascending ? 1 : -1);
};
